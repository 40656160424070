<template>
    <div>
        <div class="funfacts-area bg-fff9ee pt-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title yellow-color">Distance Learning</span>
                    <h2 class="nunito-font">Flexible study at your own pace to your own needs</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1200">
                        <div class="single-funfacts-item">
                            <div class="icon">
                                <i class="flaticon-employee"></i>
                            </div>
                            <h3>145000</h3>
                            <p>Video Courses</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <div class="single-funfacts-item">
                            <div class="icon">
                                <i class="flaticon-projects"></i>
                            </div>
                            <h3>35278</h3>
                            <p>Enrolled Learners</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <div class="single-funfacts-item">
                            <div class="icon">
                                <i class="flaticon-rating"></i>
                            </div>
                            <h3>100%</h3>
                            <p>Satisfaction Rate</p>
                        </div>
                    </div>
                </div>
                <div class="video-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                    <img src="../../assets/images/video.jpg" alt="video">
                    <div 
                        class="video-btn popup-video"
                        v-on:click="isPopupMethod(isPopup)"
                        style="cursor: pointer"
                    >
                        <i class="flaticon-play"></i>
                    </div>
                    <div class="shape15"><img src="../../assets/images/shape/shape15.png" alt="shape"></div>
                    <div class="shape16"><img src="../../assets/images/shape/shape16.png" alt="shape"></div>
                </div>
            </div>
        </div>

        <div 
            class="video-popup show" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>
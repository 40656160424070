<template>
    <div>
      <!-- Page Title -->
      <div class="page-title-area">
        <div class="container">
          <div class="page-title-content">
            <span class="sub-title red-light-color">{{ blogLabel }}</span>
            <h1>{{ blogHeading }}</h1>
          </div>
        </div>
      </div>
  
      <!-- Blog Details Content -->
      <div class="blog-details-area ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
              <div class="blog-details-desc green-color">
                <!-- Blog Image -->
                <div class="post-thumb">
                  <img :src="blogImage" alt="blog-details" />
                </div>
  
                <!-- Post Meta -->
                <div class="post-meta">
                  <ul>
                    <li>
                      <i :class="blogDateIcon"></i>
                      {{ blogDate }}
                    </li>
                  </ul>
                </div>
  
                <!-- Blog Description 1 -->
                <div v-html="blogDescription1"></div>
  
                <!-- Blog Description 2 as List -->
                <ol>
                  <li v-html="blogDescription2"></li>
                </ol>
  
                <!-- Blog Description 3 -->
                <div v-html="blogDescription3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        blogLabel: "",
        blogHeading: "",
        blogDateIcon: "",
        blogDate: "",
        blogDescription1: "",
        blogDescription2: "",
        blogDescription3: "",
        blogImage: ""
      };
    },
    async mounted() {
      // Always use the id from the URL
      const entryId = this.$route.params.id;
      console.log('entryId:', entryId);
      await this.fetchBlogDetails(entryId);
    },
    methods: {
      async fetchBlogDetails(entryId) {
        try {
          const response = await this.$contentfulClient.getEntry(entryId);
          console.log("Blog Details Response:", response);
  
          // Populate the data from the Contentful response
          this.blogLabel = response.fields.label;
          this.blogHeading = response.fields.heading;
          this.blogDateIcon = response.fields.dateIcon;
          this.blogDate = response.fields.date;
          this.blogDescription1 = this.$documentToHtmlString(response.fields.description1);
          this.blogDescription2 = this.$documentToHtmlString(response.fields.description2);
          this.blogDescription3 = this.$documentToHtmlString(response.fields.description3);
          this.blogImage = `https:${response.fields.image.fields.file.url}`;
        } catch (error) {
          console.error("Error fetching blog details:", error);
        }
      }
    }
  };
  </script>
  
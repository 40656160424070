<template>
    <div class="software-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="software-banner-content">
                        <span class="sub-title">Get your 14 days free trail</span>
                        <h1>Manage All of Your Stuff Using a Vumy</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id tincidunt eifend odio viverra diam aliquet donec again.</p>
                        <router-link to="/contact" class="btn-style-one red-light-color">
                            Get Started Now 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="software-banner-image" data-aos="fade-up">
                        <img src="../../assets/images/banner/banner9.png" alt="banner-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>
<template>
  <div>
    <NavbarStyleNine />

    <div class="page-title-area">
      <div class="container">
        <div class="page-title-content">
          <h1>FAQ For Agents</h1>
        </div>
      </div>
    </div>
    <AgentFaq />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import AgentFaq from "../Faq/AgentFaq.vue";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "FaqAgentPage",
  components: {
    NavbarStyleNine,
    AgentFaq,
    FooterStyleFive,
  },
};
</script>

<template>
    <div class="goal-area ptb-100 br-bottom-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="goal-img">
                        <img src="../../assets/images/goal2.jpg" alt="goal-image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="goal-text">
                        <span class="sub-title">WhyChooseUs</span>
                        <h2 class="nunito-font">Helping you find the property of your dreams</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>High returns:</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Short-term:</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                <h3>Low minimum:</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape8"><img src="../../assets/images/shape/shape8.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>
<template>
    <div>
        <NavbarStyleNine />
        <!-- <PageTitle subTitle="Our Services" heding="Our work is delivered by the best team in the world" /> -->
        <OurGoal />
        <!-- <Dahboard />
        <AddPropertyForm />
        <WhatWeDo />
        <WhatWeOffer />
        <Testimonials /> -->
        <!-- <FooterStyleEight /> -->
    </div>
</template>

<script>
import NavbarStyleNine from '../Layouts/NavbarStyleNine'
import PageTitle from '../Common/PageTitle'
import OurGoal from '../ServicesFour/OurGoal'
import Dahboard from '../HomeOwners/Dashboard'
import AddPropertyForm from '../Forms/AddPropertyForm'
import WhatWeDo from '../ServicesFour/WhatWeDo'
import WhatWeOffer from '../ServicesFour/WhatWeOffer'
import Testimonials from '../ServicesFour/Testimonials'
import FooterStyleEight from '../Layouts/FooterStyleEight'

export default {
    name: 'ServicesFourPage',
    components: {
        NavbarStyleNine,
        PageTitle,
        OurGoal,
        Dahboard,
        AddPropertyForm,
        WhatWeDo,
        WhatWeOffer,
        Testimonials,
        FooterStyleEight,
    }
}
</script>
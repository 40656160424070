<template>
  <div>
    <NavbarStyleNine />
    <Contact />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import Contact from "../Contact/Contact";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "ContactPage",
  components: {
    NavbarStyleNine,
    Contact,
    FooterStyleFive,
  },
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
};
</script>

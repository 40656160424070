<template>
  <div class="timer">
    <div class="row">
      <div class="col">
        <div class="fs-4 fw-bold">{{ timerDays }}</div>
        <div class="time-label">DAYS</div>
      </div>
      <div
        class="col fs-3 fw-bold d-flex justify-content-center align-self-center"
      >
        :
      </div>
      <div class="col">
        <div class="fs-4 fw-bold">{{ timerHours }}</div>
        <div class="time-label">HOURS</div>
      </div>
      <div
        class="col fs-3 fw-bold d-flex justify-content-center align-self-center"
      >
        :
      </div>
      <div class="col">
        <div class="fs-4 fw-bold">{{ timerMinutes }}</div>
        <div class="time-label">MIN</div>
      </div>
      <div
        class="col fs-3 fw-bold d-flex justify-content-center align-self-center"
      >
        :
      </div>
      <div class="col">
        <div class="fs-4 fw-bold">{{ timerSeconds }}</div>
        <div class="time-label">SEC</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    timerDate: {
      type: String,
      required: true,
    },
  },
  created() {
    setInterval(() => {
      this.updateTimer();
    }, 1000);
  },
  data() {
    return {
      timerDays: 0,
      timerHours: 23,
      timerMinutes: 59,
      timerSeconds: 59,
    };
  },
  methods: {
    updateTimer() {
      let endTime = new Date(this.timerDate);
      let endTimeParse = Date.parse(endTime) / 1000;
      let now = new Date(new Date().toISOString()); //this is utc time
      let nowParse = Date.parse(now) / 1000;
      let timeLeft = endTimeParse - nowParse;
      let days = Math.floor(timeLeft / 86400);
      let hours = Math.floor((timeLeft - days * 86400) / 3600);
      let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
      let seconds = Math.floor(
        timeLeft - days * 86400 - hours * 3600 - minutes * 60
      );
      if (days < 0) {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
      } else {
        if (hours < "10") {
          hours = "0" + hours;
        }
        if (minutes < "10") {
          minutes = "0" + minutes;
        }
        if (seconds < "10") {
          seconds = "0" + seconds;
        }
      }
      this.timerDays = days;
      this.timerHours = hours;
      this.timerMinutes = minutes;
      this.timerSeconds = seconds;
    },
  },
};
</script>

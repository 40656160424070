const FormatCurrency = (currency) => {
  const numericValue = currency.replace(/[^0-9.]/g, "");
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return formatter.format(numericValue);
};

export default FormatCurrency;

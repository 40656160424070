<template>
  <div>
    <NavbarStyleNine />
    <!-- Dynamically display subtitle and heading from Contentful -->
    <PageTitle :subTitle="pageSubTitle" :heding="pageHeading" />
    <Pricing />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import PageTitle from "../Common/PageTitle";
import Pricing from "../Pricing/Pricing";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "PricingPage",
  components: {
    NavbarStyleNine,
    PageTitle,
    Pricing,
    FooterStyleFive,
  },
  data() {
    return {
      pageSubTitle: "",  
      pageHeading: "",   
    };
  },
  async mounted() {

    const entryId = "6tpb7a19EILzZ2yni4WVcT";
    await this.fetchPageContent(entryId);
  },
  methods: {
    async fetchPageContent(entryId) {
      try {

        const response = await this.$contentfulClient.getEntry(entryId);

        this.pageSubTitle = response.fields.label;
        this.pageHeading = response.fields.title;
      } catch (error) {
        console.error("Error fetching page content:", error);
      }
    }
  }
};
</script>

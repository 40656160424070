<template>
    <div>
        <Navbar />
        <PageTitle subTitle="About Us" heding="Hello World! This is Vumy!" />
        <!-- <Bar
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
        /> -->
        <FooterStyleTwo class="bg-f9f9f9 " />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
// import { Bar } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'AboutOnePage',
    components: {
        Navbar,
        PageTitle,
        // Bar,
        FooterStyleTwo,
    },
  //   data() {
  //   return {
  //     chartData: {
  //       labels: [ 'January', 'February', 'March' ],
  //       datasets: [ { data: [40, 20, 12] } ]
  //     },
  //     chartOptions: {
  //       responsive: true
  //     }
  //   }
  // }
}
</script>
<template>
    <div class="testimonials-area with-top-border ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title dark-green-color">Testimonials</span>
                <h2 class="nunito-font">Here’s what our amazing clients are saying</h2>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="testimonials-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/man-with-laptop.png" alt="testimonials-image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="testimonials-content">
                        <div class="testimonials-slides-two">
                            <carousel
                                :autoplay="5000"
                                :wrap-around="true"
                            >
                                <Slide v-for="slide in carouselItems" :key="slide.id">
                                    <div class="single-testimonials-box">
                                        <i class="flaticon-left-quote"></i>
                                        <h5>{{slide.title}}</h5>
                                        <p>{{slide.desc}}</p>
                                        <div class="info">
                                            <h3>{{slide.name}}</h3>
                                            <span>{{slide.position}}</span>
                                        </div>
                                    </div>
                                </Slide>

                                <template #addons>
                                    <Pagination />
                                </template> 
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                title: '“I never really lost it to begin with.”',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed massa volutpat.',
                name: 'Lora Joly',
                position: 'Founder at Envato',
            },
            {
                id: 2,
                title: '“Every moment is a fresh beginning.”',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed massa volutpat.',
                name: 'David Warner',
                position: 'Founder at ThemeForest',
            },
            {
                id: 3,
                title: '“Whatever you do, do it well.”',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Risus pellentesque felis nisl ut laoreet euismod vel, integer. Massa sodales lorem nisi, sed massa volutpat.',
                name: 'James Anderson',
                position: 'Founder at EnvyTheme',
            },
        ],
    }),
})
</script>
import { defineStore } from "pinia";
import axios from "axios";

// const baseUrl = "https://localhost:7139/api";
const baseUrl = process.env.VUE_APP_API_URL;

export const usePropertyStore = defineStore("properties", {
  state: () => ({
    property: null,
    properties: [],
  }),
  actions: {
    async addProperty(propertyData) {
      try {
        const response = await axios.post(`${baseUrl}/Property`, propertyData, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async getAllStates() {
      try {
        const response = await axios.get(`${baseUrl}/Property/getAllStates`, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async getStateById(stateId) {
      try {
        const response = await axios.get(
          `${baseUrl}/Property/getStateById/` + stateId,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async getAllUserProperties() {
      try {
        const response = await axios.get(
          `${baseUrl}/Property/getUserProperties`,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async getAllProperties() {
      try {
        const response = await axios.get(`${baseUrl}/Property/getAll`, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async getPropertyById(id) {
      try {
        const response = await axios.get(
          `${baseUrl}/Property/getPropertyById/` + id,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
    async mayViewProperty(propertyId) {
      try {
        const response = await axios.get(
          `${baseUrl}/Property/mayViewProperty/` + propertyId,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
  },
});

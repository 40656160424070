<template>
  <div class="d-flex w-100 row m-0 justify-content-center mt-5 py-5">
    <div class="w-1200px text-center">
      <h2 class="howItWorksHeading mb-5">How it Works</h2>
      <div class="w-100 howItWorksVideo ratio ratio-16x9 mx-auto mb-5 border">
        <video
          width="100%"
          :poster="
            require('@/assets/agent/images/thumbnails/Agent-Arena-Process-thumbnail.jpg')
          "
          controls
          preload="metadata"
        >
          <source
            :src="require('@/assets/agent/videos/Agent-Arena-Process.mp4')"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="howto-steps howTo-text">
        <!-- Step 1 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="001"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="require('@/assets/agent/images/discover.png')"
              alt="Discover the Top Agents"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >1</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Choose Your Terms</h3>
            <p class="howTo-text">
              Begin your journey by registering your property with us. As a
              homeowner, you have the power to set the three key terms of your
              listing agreement:
            </p>
            <ul class="hollow-bullets">
              <li>
                <strong>Home Price:</strong> Determine the price you want for
                your home.
              </li>
              <li>
                <strong>Commission Rate:</strong> Set the commission rate that
                works best for you.
              </li>
              <li>
                <strong>Listing Duration:</strong> Decide how long your property
                will be listed.
              </li>
            </ul>
            <p class="mt-3 howTo-text">
              This process is designed to be empowering and stress-free,
              allowing you to make decisions at your own pace, free from the
              pressure of a pushy real estate agent.
            </p>
          </div>
        </div>

        <!-- Step 2 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="002"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="require('@/assets/agent/images/setup.png')"
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >2</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Unlock Local Agent Rankings</h3>
            <p class="howTo-text">
              After customizing your ideal listing agreement terms, you gain
              access to comprehensive local agent rankings. Homeowners have the
              freedom to invite as many agents as they wish to the Arena. Agents
              are ranked by their performance in each zip code, based on the
              volume of listings they've sold in the past year. This ensures you
              can select from the best agents in your area, making informed
              decisions with confidence.
            </p>
          </div>
        </div>

        <!-- Step 3 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="003"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="require('@/assets/agent/images/bid.png')"
              alt="Discover the Top Agents"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >3</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Agent Invitation Process</h3>
            <p class="howTo-text">
              Once invited, agents are automatically notified via text message.
              They will:
            </p>
            <ul class="hollow-bullets">
              <li>Thoroughly analyze your property</li>
              <li>Review the listing agreement terms</li>
              <li>Attend your open house if needed</li>
            </ul>
            <p class="mt-3 howTo-text">
              If an agent is confident in their ability to sell your home, they
              will enter the Arena, ready to compete for your listing.
            </p>
          </div>
        </div>

        <!-- Step 4 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="004"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="require('@/assets/agent/images/win.png')"
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >4</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">
              Agents Compete for Your Listing in the Arena
            </h3>
            <p class="howTo-text">
              An online auction takes place among the agents who enter your
              Arena. The agent with the winning bid earns the right to sign your
              listing agreement. Agent Arena holds the winning bid amount in
              escrow until your house is sold or the listing expires without a
              sale. Once your home is sold, or if the listing expires without a
              sale, the money is yours! This ensures a fair and secure process
              for all parties involved.
            </p>
          </div>
        </div>

        <!-- Step 5 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="005"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="require('@/assets/agent/images/win.png')"
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >5</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">
              The Victorious Agent Gets to Work Selling Your Home
            </h3>
            <p class="howTo-text">
              The victorious agent and homeowner meet to finalize and sign the
              remaining terms of the listing agreement. The agent then schedules
              professional photos and begins the process of selling your home in
              whatever manner they see fit. With a vested interest in your
              success, your agent is more motivated than ever to achieve the
              best results. Never before has a listing agent had this kind of
              skin in the game!
            </p>
          </div>
        </div>

        <!-- Centered Button -->
        <div class="text-center mt-4 w-100 d-flex justify-content-center">
          <router-link
            to="/register"
            class="btn-style-two fw-bold mt-2 d-flex justify-content-center align-items-center px-5 py-3 text-uppercase"
          >
            Let's do this
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  components: {},
};
</script>

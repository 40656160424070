<template>
  <div class="working-process-area ptb-100 bg-13965f">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div
            class="working-process-img"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <img :src="aboutImage" alt="working-process" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="working-process-text">
            <span class="sub-title">{{ aboutSubtitle }}</span>
            <h2>{{ aboutTitle }}</h2>
            <div v-html="aboutDescription"></div>
            <ul class="working-process-list">
              <li
                v-for="(item, index) in aboutUsList"
                :key="index"
                :data-aos="'fade-up'"
                :data-aos-duration="'1200'"
                :data-aos-delay="index * 100"
              >
                <div class="number">
                  {{ index + 1 }}
                </div>
                <h3 class="nunito-font">{{ item.title }}</h3>
                <div v-html="item.description"></div>
              </li>
            </ul>
            <p class="mt-4" v-html="aboutDescription2"></p>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="shape10">
      <img src="../../assets/images/shape/shape10.png" alt="shape" />
    </div>
    <div class="shape11">
      <img src="../../assets/images/shape/shape11.png" alt="shape" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutSubtitle: "",
      aboutTitle: "",
      aboutDescription: "",
      aboutDescription2: "",
      aboutImage: "",
      aboutUsList: [],
    };
  },
  async mounted() {
    await this.fetchAboutUsData("26x9X01fCMLAG2SlbRRFid");
    await this.fetchAboutUsList("aboutUsList");
  },
  methods: {
    async fetchAboutUsData(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log("About Us Response:", response);
        this.aboutSubtitle = response.fields.label;
        this.aboutTitle = response.fields.title;
        this.aboutDescription = this.$documentToHtmlString(
          response.fields.description
        );
        this.aboutDescription2 = this.$documentToHtmlString(
          response.fields.description2
        );
        this.aboutImage = `https:${response.fields.image.fields.file.url}`;
      } catch (error) {
        console.error("Error fetching about us data:", error);
      }
    },
    async fetchAboutUsList(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log("About Us List Response:", response);
        this.aboutUsList = response.items.map((item) => ({
          title: item.fields.title,
          description: this.$documentToHtmlString(item.fields.description),
        }));
      } catch (error) {
        console.error("Error fetching about us list:", error);
      }
    },
  },
};
</script>

<template>
  <div>
    <NavbarStyleNine />
    <HomeListing></HomeListing>
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import HomeListing from "../Listing/HomeListing.vue";

export default {
  name: "HomeListingPage",
  components: {
    NavbarStyleNine,
    HomeListing,
    FooterStyleFive,
  },
};
</script>

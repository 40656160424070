<template>
    <div>
        <NavbarStyleSeven />
        <PageTitle class="style-two" subTitle="Learn at your own pace" heding="Vumy Popular Courses" />
        <Courses />
        <AppDownload />
        <FooterStyleSeven />
    </div>
</template>

<script>
import NavbarStyleSeven from '../Layouts/NavbarStyleSeven'
import PageTitle from '../Common/PageTitle'
import Courses from '../Courses/Courses'
import AppDownload from '../Courses/AppDownload'
import FooterStyleSeven from '../Layouts/FooterStyleSeven'

export default {
    name: 'CoursesPage',
    components: {
        NavbarStyleSeven,
        PageTitle,
        Courses,
        AppDownload,
        FooterStyleSeven,
    }
}
</script>
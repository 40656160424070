<template>
    <div class="products-area ptb-100">
        <div class="container">
            <div class="vumy-grid-sorting row align-items-center">
                <div class="col-lg-6 col-md-6 result-count">
                    <p>We found <span class="count">12</span> products available for you</p>
                </div>
                <div class="col-lg-6 col-md-6 ordering">
                    <div class="select-box">
                        <label>Sort By:</label>
                        <select>
                            <option>Default</option>
                            <option>Popularity</option>
                            <option>Latest</option>
                            <option>Price: low to high</option>
                            <option>Price: high to low</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products1.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                            <span class="sale">Sale</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Black Men's T-Shirt</router-link></h3>
                            <div class="price">
                                <span class="new-price">$412.99</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products2.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Floating Paper Bag</router-link></h3>
                            <div class="price">
                                <span class="new-price">$320.85</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products3.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Sports Cap Black</router-link></h3>
                            <div class="price">
                                <span class="new-price">$211.99</span>
                                <span class="old-price">$500.99</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products4.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                            <span class="out-of-stock">Out of Stock</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Covid Smart Mask</router-link></h3>
                            <div class="price">
                                <span class="new-price">$121.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products5.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Black Coffee Mug</router-link></h3>
                            <div class="price">
                                <span class="new-price">$412.00</span>
                                <span class="old-price">$1000.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products6.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                            <span class="hot">Hot</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Kids School Bag</router-link></h3>
                            <div class="price">
                                <span class="new-price">$541.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products7.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Eco Smart Headphone</router-link></h3>
                            <div class="price">
                                <span class="new-price">$84.55</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products8.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Smart Watch</router-link></h3>
                            <div class="price">
                                <span class="new-price">$555.47</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products9.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                            <span class="sale">Sale</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Leather Backpack</router-link></h3>
                            <div class="price">
                                <span class="new-price">$412.99</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products10.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Coffee Mag</router-link></h3>
                            <div class="price">
                                <span class="new-price">$320.85</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products11.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Sports Jersey</router-link></h3>
                            <div class="price">
                                <span class="new-price">$211.99</span>
                                <span class="old-price">$500.99</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-products-box">
                        <div class="image">
                            <router-link to="/products-details" class="d-block">
                                <img src="../../assets/images/products/products12.jpg" alt="products-image">
                            </router-link>
                            <button type="submit" class="btn-style-one red-light-color">Add to Cart <i class="ph-caret-right"></i></button>
                            <span class="out-of-stock">Out of Stock</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/products-details">Black Mask</router-link></h3>
                            <div class="price">
                                <span class="new-price">$121.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <a href="/products" class="prev page-numbers">prev</a>
                            <span class="page-numbers current">1</span>
                            <a href="/products" class="page-numbers">2</a>
                            <a href="/products" class="page-numbers">3</a>
                            <a href="/products" class="next page-numbers">next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Products'
}
</script>
<template>
    <div 
        class="chat-bot-banner-area movearea"
        @mousemove="onMousemove"
        :style="{ backgroundColor: `hsl(${x}, 99%, 99%)` }"
    >
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-xl-5 col-lg-12 col-md-12">
                    <div class="chat-bot-banner-content">
                        <h1 class="nunito-font" data-aos="fade-up" data-aos-duration="1200">Build your brand connecting with customers</h1>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Velit hendrerit sit auctor tempor sem. Congue mi tempor condimentum felis arcu, non cursus. Nulla pharetra porttitor sed platea arcu et leo odio.</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <router-link to="/contact" class="btn-style-one purple-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                            <router-link to="/about-us" class="btn-style-one white-color">
                                About Us 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-md-12">
                    <div class="chat-bot-banner-image">
                        <img src="../../assets/images/banner/banner8.png" alt="banner-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data() {
        return {
            x: 0
        }
    },
    methods: {
        onMousemove(e) {
            this.x = e.clientX
        }
    }
}
</script>
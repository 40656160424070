<template>
    <div class="overview-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview1.png" data-aos="fade-up" alt="overview">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Software Intregrations</span>
                        <h2>Your valuable helping hand will find here</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Marketplace system
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Management system
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Remote system
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    File system
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Team Intregrations</span>
                        <h2>It is an easy solution for a very large solution</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Business solution
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Management support
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Enterprise account
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Market analysis
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview2.png" data-aos="fade-up" alt="overview">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SoftwareIntegrations'
}
</script>
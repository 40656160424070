<template>
  <div>
    <NavbarStyleNine />
    <AddPropertyForm />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import AddPropertyForm from "../Forms/AddPropertyForm";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "ServicesFourPage",
  components: {
    NavbarStyleNine,
    AddPropertyForm,
    FooterStyleFive,
  },
};
</script>

<template>
  <div class="terms-conditions-area ptb-100">
    <div class="container">
      <div class="terms-conditions-content">
        <h4>1. Introduction</h4>
        <p>
          Welcome to Agent Arena ("Agent Arena," "we," "us," or "our"). This
          User Agreement ("Agreement") governs your use of the Agent Arena
          platform and services, including but not limited to our website at
          <a class="blue-text" href="https://www.AgentArena.com" target="_blank"
            >www.AgentArena.com</a
          >
          and any other branded properties (collectively, the "Services"). By
          using any of our Services, you agree to abide by the terms and
          conditions outlined in this Agreement. Please read this Agreement
          carefully before accessing or using our Services.
        </p>
        <h4>2. What We Do</h4>
        <p>
          Agent Arena is a cutting-edge platform revolutionizing the real estate
          industry by connecting homeowners seeking to sell their properties
          with qualified agents through a transparent and efficient auction
          process. By providing a secure marketplace where agents bid for
          exclusive listing opportunities, Agent Arena ensures fair
          representation for homeowners and maximizes agents' potential to
          secure valuable listings. With a focus on transparency, integrity, and
          empowerment, Agent Arena facilitates successful property transactions
          while empowering homeowners and agents to achieve their real estate
          goals.
        </p>
        <h4>3. Acceptance of Terms</h4>
        <p>
          By using our Services, you agree to abide by the rules and terms
          outlined in this Agreement. This Agreement constitutes a legally
          binding contract between you and Agent Arena. If you do not agree with
          these terms, including the arbitration clause and class action waiver,
          you may not use our Services.
        </p>
        <h4>4. Rules for Agents</h4>
        <p>You agree to our simple process:</p>
        <p>
          Agents are selected by homeowners who believe they would effectively
          market and sell their homes. These agents receive notifications via
          text message containing the address of the homes up for sale and the
          desired listing price set by the homeowner. Upon receiving the
          notification, agents are prompted to sign up and can join the Agent
          Arena platform at one of three tiers.
        </p>
        <ul>
          <li>
            <strong>Bronze Tier:</strong>
            Free of charge, agents at this level can participate in bidding
            arenas for a fee of $99.99.
          </li>
          <li>
            <strong>Silver Tier:</strong>
            Priced at $299.99 per year, agents at this level benefit from
            reduced participation fees of $49.99 in bidding arenas. They also
            gain the ability to create a biography.
          </li>
          <li>
            <strong>Gold Tier:</strong>
            Available at $500 per year, agents at this level enjoy exclusive
            perks. They can access invited bidding arenas for free and create a
            biography.
          </li>
        </ul>
        <p>
          Agents can choose the tier that best suits their needs and budget,
          ensuring they have the tools and opportunities to effectively compete
          in the Agent Arena marketplace.
        </p>
        <p>
          Invited agents have access to detailed information about the homes
          available for listing on the Agent Arena platform. Along with this
          information, they also receive three critical terms of the potential
          listing agreement:
        </p>
        <ol>
          <li>
            <strong>Listing Agreement Price:</strong>
            The price at which the homeowner intends to list their property for
            sale.
          </li>
          <li>
            <strong>Listing Agreement Duration:</strong>
            The duration for which the homeowner permits the property to be
            listed on the market.
          </li>
          <li>
            <strong>Listing Agreement Commission:</strong>
            The commission rate offered to the listing agent upon successful
            sale of the property.
          </li>
        </ol>
        <p>
          These three terms provide invited agents with essential insights into
          the homeowner's expectations and the potential scope of the listing
          agreement they are bidding for. Invited agents are also extended an
          invitation to attend an open house, where they can further analyze the
          property and gather additional insights to inform their bidding
          decisions. This opportunity allows agents to evaluate the home's
          condition, features, and potential market value firsthand, enhancing
          their ability to make an informed decision of whether or not to enter
          the bidding Arena.
        </p>
        <p>
          If an agent believes they have a strong chance of successfully selling
          the home, they can enter the Arena to participate in the auction and
          bid for the listing. The entry fee for the Arena varies based on the
          agent's membership tier:
        </p>
        <ul>
          <li>
            <strong>Bronze Members:</strong>
            $99.99
          </li>
          <li>
            <strong>Silver Members:</strong>
            $49.99
          </li>
          <li>
            <strong>Gold Members:</strong>
            Free
          </li>
        </ul>
        <p>
          Agents can choose the membership tier that aligns with their
          preferences and budget, ensuring fair access to bidding opportunities
          while maximizing their potential for success in securing the listing.
        </p>
        <p>
          The auction for the listing starts at noon the day after the open
          house and concludes at 3pm. If any bid occurs in the last minute of
          the auction, the auction extends for an additional minute to allow for
          further bidding activity.
        </p>
        <p>
          The opening bid is determined as 10% of the potential commission that
          the agent stands to earn from selling the property. For instance, if
          the homeowner is selling their home for $1 million and offering a
          commission of 2.5% to the agent, the potential commission for the
          agent is $25,000. Therefore, the opening bid is set at $2,500.
        </p>
        <p>
          Bids incrementally increase in intervals equal to 10% of the opening
          bid. In the example provided, the bid intervals would be $250. This
          structured bidding process ensures fairness and transparency in the
          auction, allowing agents to make competitive bids based on the
          potential earnings from selling the property.
        </p>
        <p>
          Once the auction concludes, the winning agent is required to pay the
          winning bid amount and finalize the listing agreement in person with
          the homeowner. The homeowner should only sign the listing agreement
          once Agent Arena has verified that the agent has made the payment.
        </p>
        <p>
          The winning bid amount paid by the agent is held in escrow by Agent
          Arena until one of the following events occurs:
        </p>
        <ol>
          <li>The home is sold.</li>
          <li>The listing agreement expires.</li>
        </ol>
        <p>
          When one of these events happens and the seller wishes to receive the
          funds from Agent Arena, they must confirm the transaction through the
          app. Agent Arena will then verify this confirmation before releasing
          the funds.
        </p>
        <p>
          Upon verification, the funds will be disbursed to the seller, with
          Agent Arena deducting a 15% fee. This fee covers the platform's
          operational costs and ensures the integrity of the transaction
          process.
        </p>
        <p>
          Agent Arena holds the funds in escrow not only to facilitate secure
          transactions but also to hold sellers accountable for presenting their
          homes to potential buyers in a condition equal to or greater than that
          observed during the broker open house. This ensures transparency and
          fairness in the listing process and promotes trust among all parties
          involved. By using Agent Arena's escrow services, you agree to adhere
          to the terms outlined in this section and acknowledge the role of
          escrow in facilitating secure transactions on our platform
        </p>
        <h4>5. Rules for Homeowners</h4>
        <p>
          <strong>Provide Truthful, Accurate Information:</strong>
          Homeowners agree to provide truthful and accurate information about
          their property when listing it on Agent Arena. This includes ensuring
          that property descriptions, photos, and other details accurately
          reflect the property's condition. Misrepresentation may result in the
          editing or removal of the listing without liability to the homeowner
          or the agent.
        </p>
        <p>
          <strong>Maintain Honest Intent:</strong>
          Homeowners confirm their intention to proceed with the sale of their
          property if they accept money for the listing. Agent Arena employs
          fraud detection systems and cooperates with law enforcement to address
          fraudulent activities reported on the platform.
        </p>
        <p>
          <strong>Confirm Home Ownership:</strong>
          Homeowners agree to confirm ownership of the listed property and
          refrain from attempting to monetize assets that do not belong to them.
          Agent Arena utilizes various methods to verify homeownership and
          expects honesty from homeowners in this regard.
        </p>
        <p>
          <strong>Remain On-Platform:</strong>
          Homeowners agree not to engage in off-platform negotiations with
          agents and to report any such attempts by agents to Agent Arena. The
          platform is designed to maximize the homeowner's opportunity to
          connect with suitable agents and maximize value through on-platform
          interactions.
        </p>
        <p>
          <strong>Recognize Agent Arena's Role:</strong>
          Homeowners acknowledge that Agent Arena exists to assist them in
          monetizing their property listings and matching them with appropriate
          agents. Once a listing agreement is executed, the terms of the
          agreement govern the relationship between the homeowner and the agent,
          with Agent Arena unable to mediate disputes beyond this point.
        </p>
        <p>
          <strong>List When Ready to Sell:</strong>
          Homeowners agree to list their property on Agent Arena only when they
          are ready to sell. Posting and retracting listings undermine
          confidence in the platform. Homeowners exploring the idea of selling
          are encouraged to contact Agent Arena for assistance until they are
          ready to proceed.
        </p>
        <p>
          <strong>Understand and Abide by Listing Agreement:</strong>
          Homeowners acknowledge the importance of understanding and abiding by
          the terms of the listing agreement. Disputes between homeowners and
          agents are primarily governed by the listing agreement and local laws,
          with Agent Arena unable to mediate most disputes beyond this scope.
        </p>
        <p>
          <strong>No Interest in Agent Arena Fee:</strong>
          Homeowners agree that Agent Arena is compensated for facilitating
          matches between homeowners and agents, and they have no right or
          interest in the compensation received by Agent Arena.
        </p>
        <p>
          <strong>Tax Obligations:</strong>
          Homeowners acknowledge their responsibility for tax obligations
          related to transactions on Agent Arena. Agent Arena may provide
          necessary tax documentation, such as a 1099 form, where applicable,
          but homeowners are responsible for their tax compliance beyond this
          provision.
        </p>
        <h4>6. Agreement to the Rules:</h4>
        <p>
          By creating an account, agents agree to abide by this Agreement and
          all applicable laws, regulations, and professional obligations
          governing their conduct as real estate professionals. This includes
          but is not limited to compliance with fair housing laws, ethical
          standards, and fiduciary responsibilities to clients. Agents further
          acknowledge their responsibility to conduct themselves with integrity,
          honesty, and professionalism in all interactions on the Agent Arena
          platform.
        </p>
        <h4>7. Client Hijacking Policy:</h4>
        <p>
          Agents agree not to engage in client hijacking, which includes
          attempting to negotiate or acquire exclusive listings found on the
          Agent Arena platform outside of the platform itself. Any attempt to
          circumvent the platform and directly solicit or negotiate with
          homeowners listed on Agent Arena is strictly prohibited.
        </p>
        <p>
          Violation of this policy may result in severe consequences, including
          but not limited to:
        </p>
        <ul>
          <li>
            Immediate suspension or termination of the agent's account on Agent
            Arena.
          </li>
          <li>
            Legal action taken against the agent for breach of contract and
            violation of intellectual property rights.
          </li>
          <li>
            Reporting of the agent's conduct to relevant professional regulatory
            bodies and associations.
          </li>
          <li>
            Pursuit of financial damages for any losses incurred by Agent Arena
            as a result of the agent's actions.
          </li>
        </ul>
        <p>
          Agents are reminded to conduct all business activities related to
          exclusive listings through the proper channels provided by Agent
          Arena. Failure to adhere to this policy may result in serious
          repercussions for the agent's reputation and career.
        </p>
        <h4>8. Agent Arena's Role:</h4>
        <p>
          Agent Arena plays a crucial role in facilitating the initial
          connection between homeowners and agents. However, once this
          connection is established and the listing agreement is signed, Agent
          Arena takes a back seat in the ongoing relationship between the
          homeowner and the agent. Our platform serves as a conduit for this
          initial connection, but the terms outlined in the listing agreement
          govern the relationship from that point onwards.
        </p>
        <p>
          It's imperative to understand that Agent Arena's involvement is
          limited to facilitating the initial connection and providing escrow
          services during the listing process. Once the listing agreement is
          signed, the homeowner and the agent are responsible for adhering to
          the terms and conditions outlined in the agreement. Agent Arena
          respects the autonomy of both parties and does not interfere in the
          day-to-day operations or decision-making processes related to the
          property sale.
        </p>
        <p>
          This clear delineation ensures that homeowners and agents have the
          autonomy and flexibility to work together effectively while Agent
          Arena maintains a neutral and supportive role throughout the listing
          period.
        </p>
        <h4>OUR FEES AND HOW THEY WORK</h4>
        <h4>Agent Arena Fee Structure:</h4>
        <ol>
          <li>
            <strong>Fee Disclosure:</strong>
            Fee disclosure: In the Agent Arena, our fee structure operates
            uniquely. When an agent emerges victorious in an auction, they pay
            100% of the winning bid amount. However, upon transferring the
            winning bid to the homeowner, we deduct a 15% service fee. This
            ensures transparency and clarity in our fee structure, empowering
            agents to make informed decisions.
          </li>
          <li>
            <strong>Flexibility in Fee Structure:</strong>
            We reserve the right to adjust our fee structure as needed. By using
            our platform, you acknowledge and agree to this possibility.
          </li>
          <li>
            <strong>Payment Obligations:</strong>
            To utilize our services, you must have a valid payment method on
            file. All fees and taxes associated with our services must be paid
            upon closing the sale of the listing agreement. We do not entertain
            special payment arrangements and will take necessary actions for
            collections if required.
          </li>
          <li>
            <strong>Overdue Accounts:</strong>
            Accounts past due by more than 30 days may have outstanding amounts
            deducted from the payment method on file, if feasible. Late fees may
            also apply, and we reserve the right to report delinquent accounts
            to credit bureaus.
          </li>
        </ol>
        <h4>RULES AND POLICY AMENDMENTS</h4>
        <p>
          <strong>Continuous Improvement:</strong>
          As we evolve, we may need to update our policies to align with user
          needs, market changes, or regulatory requirements. We retain the right
          to modify this Agreement without prior notice. We'll clearly document
          these changes, notify registered users, and ensure accessibility to
          updated versions.
        </p>
        <h4>Intellectual Property Protection:</h4>
        <ol>
          <li>
            <strong>Ownership of Services:</strong>
            All features and content within Agent Arena are proprietary. You may
            not infringe upon our intellectual property rights or misrepresent
            our ownership.
          </li>
          <li>
            <strong>Limited License:</strong>
            Users are granted a restricted, non-transferable license to access
            and use our services. Any unauthorized use or reproduction is
            strictly prohibited and may lead to termination of access.
          </li>
        </ol>
        <h4>SERVICE USAGE RULES</h4>
        <p>
          <strong>Conduct Guidelines:</strong>
          To ensure a positive experience for all users, adherence to our
          service rules is mandatory. This includes refraining from harmful
          behaviors, respecting intellectual property rights, and refraining
          from illegal activities.
        </p>
        <p>
          <strong>Content Submission Guidelines:</strong>
          Users are solely responsible for content they submit. While we may use
          submitted content as per our discretion, users must ensure they have
          the necessary rights to their submissions and comply with our
          guidelines.
        </p>
        <p>
          <strong>Dispute Resolution:</strong>
          Any copyright infringement claims must follow our specified procedure
          for resolution, ensuring transparency and adherence to legal
          requirements.
        </p>
        <h4>DISCLAIMER AND LIMITATION OF LIABILITY</h4>
        <p>
          <strong>Limited Warranty:</strong>
          Users acknowledge that our services are provided "as is," and we
          disclaim all warranties, expressly or implicitly. We are not liable
          for any damages arising from service use, except as expressly outlined
          in this Agreement.
        </p>
        <p>
          <strong>Indemnification:</strong>
          Users agree to indemnify us against claims arising from their use of
          our services, including legal fees and costs incurred in defense.
        </p>
        <h4>PRIVACY POLICY AND TERMINATION</h4>
        <p>
          <strong>Privacy Protection:</strong>
          Our Privacy Policy governs user data usage. By using our services,
          users consent to our data practices.
        </p>
        <p>
          <strong>Termination Rights:</strong>
          Users may cease service usage at any time, and we reserve the right to
          modify or terminate services as deemed necessary. We may also
          terminate user access for non-compliance with this Agreement.
        </p>
        <h4>ARBITRATION AND ENFORCEMENT</h4>
        <p>
          <strong>Dispute Resolution:</strong>
          Disputes with users will be resolved through binding arbitration, and
          users waive their right to participate in class action lawsuits
          against us.
        </p>
        <p>
          <strong>Policing and Monitoring:</strong>
          We reserve the right to monitor service usage and enforce this
          Agreement through audits and content review.
        </p>
        <h4>FORCE MAJEURE AND COMPLIANCE WITH LAWS</h4>
        <p>
          <strong>External Events:</strong>
          In the event of circumstances beyond our control, we invoke the
          doctrine of force majeure. Users are also responsible for compliance
          with applicable laws and regulations.
        </p>

        <h4>NOTICES AND COMMUNICATIONS</h4>
        <p>
          <strong>Communication Channels:</strong>
          Users consent to receive notices and communications electronically and
          through other specified methods. We may also record telephone
          conversations for quality and training purposes.
        </p>
        <p>
          <strong>Legal Compliance:</strong>
          Users agree to abide by the laws of their jurisdiction and acknowledge
          that services may not be available in certain regions or to
          individuals under legal restrictions.
        </p>
        <p>
          By using Agent Arena, you agree to adhere to these rules and policies,
          ensuring a fair, transparent, and efficient marketplace for all
          participants.
        </p>
        <h4>Privacy Policy</h4>
        <p>
          At Agent Arena, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines how we collect, use, and safeguard the information you
          provide to us through our platform and services. By using Agent Arena,
          you consent to the practices described in this policy.
        </p>
        <h4>Information We Collect:</h4>
        <ol>
          <li>
            <strong>Personal Information:</strong>
            When you create an account on Agent Arena, we may collect personal
            information such as your name, email address, phone number, and
            postal address.
          </li>
          <li>
            <strong>Transaction Information:</strong>
            We collect information about transactions you conduct through our
            platform, including bid amounts, property details, and payment
            information.
          </li>
          <li>
            <strong>Usage Data:</strong>
            We gather data about how you interact with our platform, including
            your browsing history, search queries, and preferences.
          </li>
          <li>
            <strong>Communications:</strong>
            If you contact us or communicate with other users through our
            platform, we may collect information related to those
            communications.
          </li>
        </ol>
        <h4>How We Use Your Information:</h4>
        <ol>
          <li>
            <strong>Provide Services:</strong>
            We use the information you provide to deliver our services,
            facilitate transactions, and improve the user experience on Agent
            Arena.
          </li>
          <li>
            <strong>Communications:</strong>
            We may use your contact information to send you important updates,
            newsletters, or marketing communications related to our services.
            You can opt out of these communications at any time.
          </li>
          <li>
            <strong>Analytics:</strong>
            We analyze usage data to understand how our platform is used,
            identify trends, and make improvements to enhance user satisfaction.
          </li>
          <li>
            <strong>Legal Compliance:</strong>
            We may use your information to comply with applicable laws,
            regulations, or legal processes, or to respond to lawful requests
            from government authorities.
          </li>
        </ol>
        <h4>Information Sharing and Disclosure:</h4>
        <ol>
          <li>
            <strong>Service Providers:</strong>
            We may share your information with third-party service providers who
            assist us in operating our platform, processing payments, or
            providing other services on our behalf.
          </li>
          <li>
            <strong>Business Transfers:</strong>
            In the event of a merger, acquisition, or sale of assets, your
            information may be transferred to the acquiring entity or successor
            organization.
          </li>
          <li>
            <strong>Legal Requirements:</strong>
            We may disclose your information if required by law or in response
            to legal processes, such as subpoenas, court orders, or law
            enforcement requests.
          </li>
        </ol>
        <h4>Data Security:</h4>
        <p>
          We implement security measures to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction.
          These measures include encryption, firewalls, and regular security
          assessments.
        </p>
        <h4>Data Retention:</h4>
        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy or as required by
          law. If you wish to delete your account or request the deletion of
          your information, please contact us.
        </p>
        <h4>Third-Party Links:</h4>
        <p>
          Agent Arena may contain links to third-party websites or services that
          are not operated or controlled by us. This Privacy Policy applies
          solely to information collected by Agent Arena, and we are not
          responsible for the privacy practices of third-party sites.
        </p>
        <h4>Updates to this Policy:</h4>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or to comply with legal requirements. We will notify
          you of any material changes by posting the updated policy on our
          platform or by other means.
        </p>
        <h4>Contact Us:</h4>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at privacy@agentarena.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>

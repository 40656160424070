<template>
    <div class="how-to-apply-area ptb-100 bg-263238">
        <div class="container">
            <div class="section-title white-color">
                <span class="sub-title">Apply Process</span>
                <h2 class="nunito-font">Start saving on your final expense insurance</h2>
            </div>
            <div class="apply-arrow">
                <img src="../../assets/images/arrow.png" class="arrow" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="400" alt="arrow">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                        <div class="single-how-to-apply-box">
                            <div class="number">
                                1
                            </div>
                            <h3 class="nunito-font">Answer A Few Questions</h3>
                            <p>Lorem ipsum dolor sit amet, sagittis consectetur adipiscing elit. Sed est non feugiat sagittis, sagittis donec.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <div class="single-how-to-apply-box">
                            <div class="number">
                                2
                            </div>
                            <h3 class="nunito-font">Receive Your Free Quotes</h3>
                            <p>Lorem ipsum dolor sit amet, sagittis consectetur adipiscing elit. Sed est non feugiat sagittis, sagittis donec.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                        <div class="single-how-to-apply-box">
                            <div class="number">
                                3
                            </div>
                            <h3 class="nunito-font">Purchase Your Policy</h3>
                            <p>Lorem ipsum dolor sit amet, sagittis consectetur adipiscing elit. Sed est non feugiat sagittis, sagittis donec.</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                        <div class="lets-start-box">
                            <router-link to="/contact" class="btn-style-one dark-green-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ApplyProcess'
}
</script>
import { defineStore } from "pinia";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_URL}/auctionInvite`;

export const useAuctionInviteStore = defineStore("auctionInvite", {
  state: () => ({
    auctionInvite: null,
    auctionInvites: [],
  }),
  actions: {
    async inviteByEmail(inviteData) {
      try {
        const response = await axios.post(
          `${baseUrl}/inviteByEmail`,
          inviteData,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error inviteByEmail:", error);
        return error;
      }
    },
    async updateStatus(auctionAgentInvite, accepted) {
      try {
        auctionAgentInvite.status = accepted ? 1 : 2;
        const response = await axios.post(
          `${baseUrl}/updateAuctionAgentInvite/${auctionAgentInvite.id}/${auctionAgentInvite.status}`,
          { withCredentials: true }
        );
        return response;
      } catch (error) {
        console.error("Error updateStatus:", error);
        return error;
      }
    },
    async getInviteStatus(propertyId) {
      try {
        const response = await axios.get(
          `${baseUrl}/agentInviteStatus/${propertyId}`,
          { withCredentials: true }
        );
        return response;
      } catch (error) {
        console.error("Error getInviteStatus:", error);
        return error;
      }
    },
    async getAgentAuctionInvite(propertyId) {
      try {
        const response = await axios.get(
          `${baseUrl}/agentAuctionInvite/${propertyId}`,
          { withCredentials: true }
        );
        return response;
      } catch (error) {
        console.error("Error getInviteStatus:", error);
        return error;
      }
    },
  },
});

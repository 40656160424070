<template>
    <div class="residence-area ptb-100 br-bottom-100 bg-f9f9f9">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="residence-content">
                        <span class="sub-title">Residence Area</span>
                        <h2 class="nunito-font">Let Our Family Show Your Family the Way Home</h2>
                        <div class="box" data-aos="fade-up" data-aos-duration="1200">
                            <i class="flaticon-draw-check-mark"></i>
                            <h3>Interior Details</h3>
                            <ul>
                                <li>
                                    <span>Interior Size:</span>
                                    2500 sq ft
                                </li>
                                <li>
                                    <span>Bedrooms:</span>
                                    4
                                </li>
                                <li>
                                    <span>Bathrooms:</span>
                                    3
                                </li>
                                <li>
                                    <span>Garage:</span>
                                    3 car
                                </li>
                                <li>
                                    <span>Laundry Room:</span>
                                    Washer/Dryer Hookups
                                </li>
                            </ul>
                        </div>
                        <div class="box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <i class="flaticon-draw-check-mark"></i>
                            <h3>Room Dimensions</h3>
                            <ul>
                                <li>
                                    <span>Living Room:</span>
                                    29x18
                                </li>
                                <li>
                                    <span>Dining Room:</span>
                                    12x11
                                </li>
                                <li>
                                    <span>Kitchen:</span>
                                    15x14
                                </li>
                                <li>
                                    <span>Master Bedroom:</span>
                                    23x14
                                </li>
                                <li>
                                    <span>Bedroom 2:</span>
                                    12x11
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="residence-image">
                        <img src="../../assets/images/residence.jpg" alt="residence-image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape9"><img src="../../assets/images/shape/shape9.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'ResidenceArea'
}
</script>
<template>
  <div>
    <NavbarStyleNine />
    <PageTitle
      subTitle="Memberships"
      heding="No Hidden Charge Applied, Choose Your Plan"
    />
    <Membership />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import PageTitle from "../Common/PageTitle";
import Membership from "../Agent/Membership.vue";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "PricingPage",
  components: {
    NavbarStyleNine,
    PageTitle,
    Membership,
    FooterStyleFive,
  },
};
</script>

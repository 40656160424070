<template>
    <div class="property-banner-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="property-banner-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">Single Property</span>
                        <h1 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100" class="nunito-font">Your Gateway to a Richer Life</h1>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">At the end of the day, we want a home that matches our lifestyle. Vumy knows that and promises clients their agents are ready and waiting to help find the perfect fit.</p>
                        <form class="search-form" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300" @submit.prevent>
                            <div class="row">
                                <div class="col-lg-5 col-md-4">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <input type="text" placeholder="Where to?">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <label>Dates</label>
                                        <input type="text" placeholder="How long?">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-4">
                                    <button type="submit" class="btn-style-one green-color">Search Now <i class="ph-magnifying-glass"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="property-banner-image" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/banner/banner5.jpg" alt="banner-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>
<template>
  <div class="profile-authentication-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <img src="../../assets/images/Register1.png" alt="image" />
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="login-form">
            <h2>Login</h2>
            <form @submit.prevent="submitForm">
              <div
                v-if="showAlert"
                class="alert alert-primary"
                :class="successfulSignIn ? 'alert-success' : 'alert-danger'"
                role="alert"
              >
                {{ alertMessage }}
              </div>
              <div class="form-group">
                <input
                  type="text"
                  v-model="formData.email"
                  :class="{
                    'is-invalid border border-danger': !emailValid,
                  }"
                  class="form-control"
                  placeholder="Email"
                  :disabled="loader"
                />
              </div>
              <div class="input-group form-group">
                <input
                  class="form-control"
                  :class="{
                    'is-invalid border border-danger': !passwordValid,
                  }"
                  v-model="formData.password"
                  :type="passwordType"
                  placeholder="Password"
                  :disabled="loader"
                />
                <span
                  role="button"
                  class="input-group-text"
                  @click="setPasswordVisibility()"
                >
                  <i v-if="passwordVisible" class="ph ph-eye"></i>
                  <i v-else class="ph ph-eye-slash"></i>
                </span>
              </div>
              <div class="form-group mb-1">
                <b-button
                  class="btn-style-two"
                  type="submit"
                  :disabled="loader"
                >
                  {{ loader ? "Logging in..." : "Log In" }}
                </b-button>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 remember-me-wrap">
                  <!-- <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="remember-me"
                    />
                    <label class="form-check-label" for="remember-me"
                      >Remember me</label
                    >
                  </div> -->
                  <p>
                    Don't have an account yet?
                    <router-link
                      to="/register"
                      class="blue-text text-decoration-underline link-btn"
                    >
                      Sign Up
                    </router-link>
                  </p>
                </div>
                <div class="col-lg-6 col-md-6 lost-your-password-wrap">
                  <!-- <router-link to="/lost-password" class="lost-your-password"
                    >Forgot your password?</router-link
                  > -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "../../stores/users";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Authentication",
  setup() {
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();
    return {
      userStore,
      route,
      router,
    };
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      passwordVisible: false,
      passwordType: "Password",
      emailValid: true,
      passwordValid: true,
      loader: false,
      showAlert: false,
      alertMessage: "",
      successfulSignIn: false,
    };
  },
  computed: {
    validateEmail() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.formData.email
      );
    },
  },
  methods: {
    setPasswordVisibility() {
      if (this.passwordVisible) {
        this.passwordVisible = false;
        this.passwordType = "password";
      } else {
        this.passwordVisible = true;
        this.passwordType = "text";
      }
    },
    async submitForm() {
      this.loader = true;
      this.emailValid = true;
      this.passwordValid = true;
      this.showAlert = false;
      this.alertMessage = "";
      this.successfulSignIn = false;

      if (
        !this.validateEmail ||
        this.formData.password == null ||
        this.formData.password == "" ||
        this.formData.password == " "
      ) {
        if (!this.validateEmail) {
          this.emailValid = false;
        }
        if (
          this.formData.password == null ||
          this.formData.password == "" ||
          this.formData.password == " "
        ) {
          this.passwordValid = false;
        }

        this.alertMessage = "Please ensure all required fields are completed";
        this.successfulSignIn = false;
        this.showAlert = true;
        this.loader = false;
        return;
      }

      await this.userStore.login(this.formData).then((res) => {
        console.log(res);

        if (res.status == 200) {
          const userData = res.data;
          // redirect to initial intended url or dashboard
          let defaultPath;
          switch (userData.roleId) {
            case 1:
              defaultPath = "/";
              break;
            case 2:
              defaultPath = "/AgentDashboard";
              break;
            case 3:
              defaultPath = "/HomeOwnerDashboard";
              break;
            default:
              defaultPath = "/";
          }
          let redirectTo;
          if (this.route.query.to) {
            redirectTo = this.route.query.to;
          } else if (this.route.query.redirect) {
            redirectTo = this.route.query.redirect;
          } else {
            redirectTo = defaultPath;
          }

          const targetRoute = { path: redirectTo };
          this.router.push(targetRoute);
        } else if (
          res.response &&
          res.response.status == 400 &&
          res.response.data == "Incorrect username or password"
        ) {
          this.alertMessage = "Incorrect email or password";
          this.successfulSignIn = false;
          this.showAlert = true;
          this.loader = false;
          return;
        } else {
          this.alertMessage = "Something unexpected has occurred";
          this.successfulSignIn = false;
          this.showAlert = true;
          this.loader = false;
        }
      });
    },
  },
};
</script>

<template>
    <div class="profile-authentication-area ptb-100">
        <div class="container justify-content-center">
            <div class="row">
                <div class="col-lg-3 col-md-0"></div>
                <div class="col-lg-6 col-md-12">
                    <div class="login-form">
                        <!-- <h4>Edit your profile</h4> -->
                        <form v-if="agent" @submit.prevent>
                            <div class="form-group">
                                <label>Facebook URL</label>
                                <input v-model="agent.facebookURL" type="text" class="form-control" placeholder="Facebook URL">
                            </div>
                            <div class="form-group">
                                <label>Twitter URL</label>
                                <input v-model="agent.twitterURL" type="text" class="form-control" placeholder="Twitter URL">
                            </div>
                            <div class="form-group">
                                <label>Linked URL</label>
                                <input v-model="agent.linkedURL" type="text" class="form-control" placeholder="Linked URL">
                            </div>
                            <div class="form-group">
                                <label>Instagram URL</label>
                                <input v-model="agent.instagramURL" type="text" class="form-control" placeholder="Instagram URL">
                            </div>
                            <div class="form-group">
                                <label>Awards</label>
                                <input v-model="agent.awards" type="text" class="form-control" placeholder="Awards">
                            </div>
                            <div class="form-group">
                                <label>Number Of Past Sales</label>
                                <input v-model="agent.numberOfPastSales" type="text" class="form-control" placeholder="Number Of Past Sales">
                            </div>
                            <div class="form-group">
                                <label>Years Experience</label>
                                <input v-model="agent.yearsExperience" type="text" class="form-control" placeholder="Years Experience">
                            </div>
                            <div class="form-group">
                                <label>Experience Level</label>
                                <input v-model="agent.experienceLevel" type="text" class="form-control" placeholder="Experience Level">
                            </div>
                        </form>
                        <button :disabled="updating == true" @click="updateAgent" class="btn btn-primary w-100">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAgentStore } from "../../stores/agent";
import { useRoute, useRouter } from "vue-router";

export default {
    name: 'Authentication',
    setup() {
        const agentStore = useAgentStore();
        const router = useRouter();

        return {
            agentStore,
            router,
        }
    },
    data() {
        return {
            agent: null,
            updating: false,
        }
    },
    methods: {
        fetchAgent() {
            this.agentStore.fetchAgent().then((res) => {
                this.agent = this.agentStore.getAgent;
                //console.log(this.agent);
            });
        },
        updateAgent() {
            const data = {
                facebookURL: this.agent.facebookURL,
                twitterURL: this.agent.twitterURL,
                linkedURL: this.agent.linkedURL,
                instagramURL: this.agent.instagramURL,
                awards: this.agent.awards,
                numberOfPastSales: this.agent.numberOfPastSales,
                yearsExperience: this.agent.yearsExperience,
                experienceLevel: this.agent.experienceLevel,
            };
            this.updating = true;
            this.agentStore.updateAgent(data).then((res) => {
                this.router.push("/AgentDashboard");
                this.updating = false;
            });
        }
    },
    mounted() {
        this.fetchAgent();
    },
}
</script>
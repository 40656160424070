<template>
    <div class="partner-area pt-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="partner-title">
                        Trusted by world famous companies:
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="partner-item-lists">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <img src="../../assets/images/partner/partner1.png" alt="partner-image">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <img src="../../assets/images/partner/partner2.png" alt="partner-image">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <img src="../../assets/images/partner/partner5.png" alt="partner-image">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <img src="../../assets/images/partner/partner4.png" alt="partner-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Partner'
}
</script>
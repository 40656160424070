<template>
    <div class="get-started-area pb-100">
      <div class="container">
        <div class="get-started-inner-area" data-aos="fade-up" data-aos-duration="1200">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
              <div v-html="description"></div>
            </div>
            <div class="col-lg-5 col-md-12 text-end">
              <router-link to="/contact" class="btn-style-one green-color">
                {{ buttonText }} 
                <i class="ph-caret-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  
  export default {
    data() {
      return {
        description: '', 
        buttonText: '',  
      };
    },
    async mounted() {
      await this.fetchGetStartedContent('5n1Rf4ZRNxzyJEEya4Y9zZ');
    },
    methods: {
      async fetchGetStartedContent(entryId) {
        try {
          const response = await this.$contentfulClient.getEntry(entryId);
          this.description = this.$documentToHtmlString(response.fields.description);
          this.buttonText = response.fields.buttonText;
        } catch (error) {
          console.error('Error fetching get started content:', error);
        }
      }
    }
  };
  </script>
  
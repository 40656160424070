<template>
  <div class="mt-sm-4 mt-md-3 mt-lg-2">
    <div class="container">
      <div class="row">
        <div class="d-flex w-100 justify-content-start mb-2">
          <button
            class="btn btn-style-two"
            :disabled="loader"
            @click="backToPropertyDetails()"
          >
            <div
              class="d-flex justify-content-center align-items-center py-2 pe-2"
            >
              <i class="ph-caret-left"></i>
              Back to Property
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mb-2">
        <div class="col-12 mb-4 mb-lg-0">
          <div class="card pb-3">
            <div class="card-body">
              <h5 class="card-title">Invite By Email</h5>
              <div class="d-flex mx-2">
                <div
                  v-if="showStatusMessage"
                  :class="[
                    'w-100 alert',
                    isSuccess ? 'alert-success' : 'alert-danger',
                  ]"
                >
                  {{ statusMessage }}
                </div>
              </div>
              <div class="d-flex">
                <div class="input-group mx-2 w-100">
                  <input
                    v-model="formData.invitedEmail"
                    type="text"
                    :class="{ 'is-invalid': !emailValid }"
                    class="form-control h-100"
                    placeholder="Email"
                  />
                  <button
                    class="btn btn-style-two"
                    :disabled="loader"
                    @click="sendInvite()"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center py-2 ps-1"
                    >
                      {{ loader ? "Sending Invite" : "Send Invite" }}
                      <i class="ph-caret-right"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div class="d-flex mx-2 w-100">
                <span v-if="!emailValid" class="text-danger fs-6">
                  Please ensure a valid email has been entered
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-4 mb-lg-0">
          <div class="card">
            <div class="card-body">
              <!-- Row with Invite By Agents heading and Send Invite button -->
              <div
                class="d-flex justify-content-between align-items-center mx-3"
              >
                <h5 class="card-title">Invite Agents</h5>
                <button
                  class="btn btn-style-two"
                  :disabled="loader"
                  @click="sendBatchInvites()"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2 ps-1"
                  >
                    {{ loader ? "Sending Invites" : "Send Invites" }}
                    <i class="ph-caret-right"></i>
                  </div>
                </button>
              </div>
              <div class="row mt-2 mx-2">
                <button
                  class="btn btn-style-two mx-2 mb-2 col"
                  :disabled="loader"
                  @click="selectTopAgents(10)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    Select Top 10
                  </div>
                </button>
                <button
                  class="btn btn-style-two mx-2 mb-2 col"
                  :disabled="loader"
                  @click="selectTopAgents(50)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    Select Top 50
                  </div>
                </button>
                <button
                  class="btn btn-style-two mx-2 mb-2 col"
                  :disabled="loader"
                  @click="selectTopAgents(100)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    Select Top 100
                  </div>
                </button>
                <button
                  class="btn btn-style-two mx-2 mb-2 col"
                  :disabled="loader"
                  @click="selectTopAgents(500)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    Select Top 500
                  </div>
                </button>
                <button
                  class="btn btn-style-two mx-2 mb-2 col"
                  :disabled="loader"
                  @click="selectTopAgents(1000)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    Select Top 1000
                  </div>
                </button>
              </div>
              <!-- Status message -->
              <div class="d-flex mx-2">
                <div
                  v-if="showBatchStatusMessage"
                  :class="[
                    'w-100 alert',
                    isSuccess ? 'alert-success' : 'alert-danger',
                  ]"
                >
                  {{ batchStatusMessage }}
                </div>
              </div>

              <!-- Checkbox list of agents -->
              <div class="d-flex flex-column agent-list">
                <InviteAgentCard
                  v-for="agent in agents"
                  :key="agent.id"
                  :icon="
                    agent.agent && agent.agent.user
                      ? agent.agent.user.profilePictureUrl
                      : ''
                  "
                  :name="agent.agentName"
                  :numberOfSales="agent.numberOfSales"
                  :valueOfSales="agent.valueOfSales"
                  :email="agent.email"
                  :selectedEmails="selectedAgents"
                  @selection-change="handleSelectionChange"
                />
              </div>
              <div class="d-flex w-100 justify-content-end px-2">
                <button
                  v-if="pagination.page != 1"
                  class="btn btn-style-one green-color mx-2"
                  :disabled="loader"
                  @click="prevPage()"
                >
                  Previous
                </button>
                <button
                  v-if="!agents.length < 25"
                  class="btn btn-style-one green-color"
                  :disabled="loader"
                  @click="nextPage()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useAuctionInviteStore } from "../../stores/auctionInvite";
import { useAgentStore } from "../../stores/agent";
import InviteAgentCard from "./InviteAgentCard";

export default {
  name: "InviteExternal",
  components: {
    InviteAgentCard,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const auctionInviteStore = useAuctionInviteStore();
    const agentStore = useAgentStore();
    return { route, router, auctionInviteStore, agentStore };
  },
  data() {
    return {
      loader: false,
      emailValid: true,
      formData: {
        propertyId: this.route.params.propertyId,
        invitedEmail: "",
      },
      showStatusMessage: false,
      statusMessage: "",
      showBatchStatusMessage: false,
      batchStatusMessage: "",
      isSuccess: false,
      agents: [],
      pagination: {
        page: 1,
        itemsPerPage: 25,
      },
      selectedAgents: [],
    };
  },
  computed: {
    validateEmail() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.formData.invitedEmail
      );
    },
  },
  methods: {
    backToPropertyDetails() {
      this.router.push({
        name: "PropertyDetails",
        params: { id: this.formData.propertyId },
      });
    },
    handleSelectionChange({ email, selected }) {
      if (selected) {
        // Add to selected agents if not already in the list
        if (!this.selectedAgents.includes(email)) {
          this.selectedAgents.push(email);
        }
      } else {
        // Remove from selected agents
        this.selectedAgents = this.selectedAgents.filter(
          (agentEmail) => agentEmail !== email
        );
      }
    },
    async prevPage() {
      if (this.pagination.page != 1) {
        this.loader = true;
        this.pagination.page = this.pagination.page - 1;
        this.agents = await this.agentStore.fetchAllCountyAgents(
          this.pagination
        );
        this.loader = false;
      }
    },
    async nextPage() {
      if (!this.agents.length < 25) {
        this.loader = true;
        this.pagination.page = this.pagination.page + 1;
        this.agents = await this.agentStore.fetchAllCountyAgents(
          this.pagination
        );
        this.loader = false;
      }
    },
    sendInvite() {
      this.loader = true;
      this.emailValid = true;
      this.showStatusMessage = false;
      this.isSuccess = false;
      this.statusMessage = "";

      if (!this.validateEmail) {
        this.emailValid = false;
        this.loader = false;
        return;
      }

      const data = {
        propertyId: this.route.params.propertyId,
        invitedEmails: [this.formData.invitedEmail],
      };

      this.auctionInviteStore
        .inviteByEmail(data)
        .then((res) => {
          if (res.status == 200) {
            if (res.data == 1) {
              // Invite sent successfully
              this.isSuccess = true;
              this.statusMessage = `Invite has been sent to ${this.formData.invitedEmail}`;
            } else {
              this.handleInviteResponse(res.data);
            }
            this.loader = false;
            this.formData.invitedEmail = "";
            this.showStatusMessage = true;
            setTimeout(() => {
              this.showStatusMessage = false;
              this.isSuccess = false;
              this.statusMessage = "";
            }, 10000);
          }
        })
        .catch(() => {
          // Handle errors
        });
    },
    sendBatchInvites() {
      this.loader = true;
      this.showBatchStatusMessage = false;
      this.isSuccess = false;
      this.batchStatusMessage = "";

      if (this.selectedAgents.length <= 0) {
        this.showBatchStatusMessage = true;
        this.batchStatusMessage = "Select at least 1 agent";
        this.loader = false;
        return;
      }

      const data = {
        propertyId: this.route.params.propertyId,
        invitedEmails: this.selectedAgents,
      };

      this.auctionInviteStore
        .inviteByEmail(data)
        .then((res) => {
          if (res.status == 200) {
            // Invite sent successfully
            this.isSuccess = true;
            this.batchStatusMessage = `Invite has been sent to ${this.selectedAgents.length} agents`;
            this.loader = false;
            this.formData.invitedEmail = "";
            this.selectedAgents = [];
            this.showBatchStatusMessage = true;
            setTimeout(() => {
              this.showBatchStatusMessage = false;
              this.isSuccess = false;
              this.batchStatusMessage = "";
            }, 10000);
          } else {
            this.handleInviteResponse(res.data);
          }
        })
        .catch(() => {
          // Handle errors
        });
    },
    handleInviteResponse(responseCode) {
      switch (responseCode) {
        case -1:
          this.isSuccess = false;
          this.statusMessage = "Something went wrong, please try again later.";
          break;
        case -2:
          this.isSuccess = false;
          this.statusMessage = `${this.formData.invitedEmail} has already been invited.`;
          break;
        case -3:
          this.isSuccess = false;
          this.statusMessage =
            "The person you are trying to invite is not registered as an agent. Please try a different email.";
          break;
        default:
          this.isSuccess = false;
          this.statusMessage = "Something went wrong, please try again later.";
          break;
      }
    },
    toggleAgent(email) {
      if (this.selectedAgents.includes(email)) {
        this.selectedAgents = this.selectedAgents.filter(
          (selectedEmail) => selectedEmail !== email
        );
      } else {
        this.selectedAgents.push(email);
      }
    },
    async selectTopAgents(numberOfTopAgents) {
      const topAgents = await this.agentStore.getTopAgents(numberOfTopAgents);

      if (topAgents.data) {
        this.selectedAgents = topAgents.data;

        this.agents = [];
        this.agents = await this.agentStore.fetchAllCountyAgents(
          this.pagination
        );
      }
    },
  },
  async beforeMount() {
    this.agents = await this.agentStore.fetchAllCountyAgents(this.pagination);
  },
};
</script>

<style scoped>
.is-invalid {
  border: 1px solid red;
}

.agent-list {
  border-radius: 5px;
  padding: 10px;
}

.agent-item {
  padding: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.agent-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.form-check-input {
  margin-right: 10px;
}

.form-check-label {
  font-size: 16px;
  cursor: pointer;
}

/* Remove border between items */
.agent-item + .agent-item {
  border-top: none;
}
</style>

<template>
  <div>
    <NavbarStyleNine />
    <div class="page-title-area">
      <div class="container">
        <div class="page-title-content">
          <h1>Terms & Conditions</h1>
        </div>
      </div>
    </div>
    <TermsConditions />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import TermsConditions from "../TermsConditions/TermsConditions";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "TermsConditionsPage",
  components: {
    NavbarStyleNine,
    TermsConditions,
    FooterStyleFive,
  },
};
</script>

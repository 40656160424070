<template>
    <div class="about-area with-top-border ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image bg1">
                        <img src="../../assets/images/about/about1.jpg" alt="about">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">About Us</span>
                        <h2>The story behind our consulting firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id proin lectus in aliquam orci ornare nec. Commodo morbi tincidunt egestas velit.</p>
                        <ul class="about-list">
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Integrated Innovation
                               <router-link to="/services-details" class="link-btn">
                                <i class="ph-caret-right"></i>
                               </router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Collaborative Culture
                                <router-link to="/services-details" class="link-btn">
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Business Planning
                                <router-link to="/services-details" class="link-btn">
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="ph-checks"></i>
                                </div>
                                Professional Team
                                <router-link to="/services-details" class="link-btn">
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </li>
                        </ul>
                        <router-link to="/about-us" class="btn-style-one red-light-color">
                            More About Us 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
<template>
    <div>
        <NavbarStyleNine />
        <BlogDetailsThree />
        <FooterStyleFive />
    </div>
</template>

<script>
import NavbarStyleNine from '../Layouts/NavbarStyleNine'
import PageTitle from '../Common/PageTitle'
import BlogDetailsThree from '../BlogDetailsThree/BlogDetailsThree'
import FooterStyleFive from '../Layouts/FooterStyleFive'

export default {
    name: 'BlogDetailsThreePage',
    components: {
        NavbarStyleNine,
        PageTitle,
        BlogDetailsThree,
        FooterStyleFive,
    }
}
</script>
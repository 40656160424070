<template>
  <div class="container my-5">
    <div class="card">
      <div class="card-header bg-light">
        <div class="row">
          <div class="col-auto col-md-4 col-lg-4"></div>
          <div class="col-12 col-md-4 col-lg-4 text-center">
            <h3>My Properties</h3>
          </div>
          <div
            class="col-12 col-md-4 col-lg-4 d-flex justify-content-center justify-content-md-end justify-content-lg-end"
          >
            <button class="btn btn-style-two mb-2" @click="registerProperty">
              Register a Property
            </button>
          </div>
        </div>
      </div>
      <div v-if="properties.length > 0" class="property">
        <PropertyItem
          v-for="property in properties"
          :key="property.id"
          :propertyId="property.id"
          :propertyImageUrl="property.propertyPhotoUrls[0]"
          :propertyAddress="getAddress(property)"
          :bottomLinePrice="property.bottomLinePrice.toString()"
          :openHouseDate="property.openHouseDate"
          :auctionDate="property.auctionDate"
        />
      </div>
      <div v-else>
        <div class="p-2 border-bottom border-1 border-dark border-opacity-25">
          <div class="row">
            <div class="col-12 d-flex justify-content-center align-self-center">
              <p class="fs-4">No listings found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import PropertyItem from "./PropertyItem.vue";

export default {
  name: "MyListings",
  components: {
    PropertyItem,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  methods: {
    registerProperty() {
      this.router.push("register-property");
    },
    getAddress(property) {
      if (!property) {
        console.error("Property is undefined or null");
        return "";
      }

      let addressLine2 = "";
      if (property.address2 != null) {
        addressLine2 = property.address2 + ", ";
      }

      const address =
        property.address1 +
        ", " +
        addressLine2 +
        property.city +
        ", " +
        property.state +
        ", " +
        property.zipCode;

      //console.log("address", address);
      return address;
    },
  },
};
</script>

<style scoped>
.property {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 625px;
}
</style>

<template>
    <div>
      <NavbarStyleNine />
      
      <div class="page-title-area">
        <div class="container">
          <div class="page-title-content">
            <span class="sub-title">{{ label }}</span>
            <h1 v-html="title"></h1>
          </div>
        </div>
      </div>
  
      <Faq />
      <!-- <Partner /> -->
      <FooterStyleFive />
    </div>
  </template>
  
  <script>
  import NavbarStyleNine from '../Layouts/NavbarStyleNine'
  import Faq from '../Faq/Faq'
  import Partner from '../Faq/Partner'
  import FooterStyleFive from '../Layouts/FooterStyleFive'

  
  export default {
    name: 'FaqPage',
    components: {
      NavbarStyleNine,
      Faq,
      Partner,
      FooterStyleFive,
    },
    data() {
      return {
        label: '', 
        title: '',    
      };
    },
    async mounted() {
      await this.fetchFaqPageContent('5K3e5PGKvcJXs4uj8udyZ8'); 
    },
    methods: {
      async fetchFaqPageContent(entryId) {
        try {
          const response = await this.$contentfulClient.getEntry(entryId);
  
          this.label = response.fields.label;  
          this.title = response.fields.title;  
        } catch (error) {
          console.error('Error fetching FAQ page content:', error);
        }
      }
    }
  };
  </script>
  
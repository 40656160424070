import { defineStore } from "pinia";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_URL}/dashboard`;

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
  }),
  actions: {
    async agentInvites() {
      try {
        const response = await axios.get(`${baseUrl}/agent/invites`, { withCredentials: true });
        return response;
      } catch (error) {
        console.error("Error agentInvites:", error);
        return error;
      }
    },
    async agentOpenHouses() {
      try {
        const response = await axios.get(`${baseUrl}/agent/openhouses`, { withCredentials: true });
        return response;
      } catch (error) {
        console.error("Error agentOpenHouses:", error);
        return error;
      }
    },
    async agentAuctions() {
      try {
        const response = await axios.get(`${baseUrl}/agent/auctions`, { withCredentials: true });
        return response;
      } catch (error) {
        console.error("Error agentAuctions:", error);
        return error;
      }
    },
  },
});

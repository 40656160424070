<template>
    <div>
        <Navbar />
        <PageTitle subTitle="Our Services" heding="Our work is delivered by the best team in the world" />
        <HowCanHelp />
        <Services />
        <WhatWeDo />
        <Faq />
        <Subscribe />
        <Partner />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import HowCanHelp from '../Services/HowCanHelp'
import Services from '../Services/Services'
import WhatWeDo from '../Services/WhatWeDo'
import Faq from '../Services/Faq'
import Subscribe from '../Services/Subscribe'
import Partner from '../Services/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'ServicesPage',
    components: {
        Navbar,
        PageTitle,
        HowCanHelp,
        Services,
        WhatWeDo,
        Faq,
        Subscribe,
        Partner,
        Footer,
    }
}
</script>
<template>
  <div class="features-area pt-100 pb-75">
    <div class="container">
      <div class="section-title">
        <span class="sub-title green-color">{{ sectionLabel }}</span>
        <h2>{{ sectionTitle }}</h2>
      </div>
      <div class="row justify-content-center">
        <div
          v-for="(feature, index) in featureBlocks"
          :key="index"
          class="col-lg-4 col-md-6 col-sm-6"
          :data-aos="'fade-up'"
          :data-aos-duration="'1200'"
          :data-aos-delay="index * 100"
        >
          <div class="features-item">
            <div class="icon">
              <i :class="feature.icon"></i>
            </div>
            <h3 class="nunito-font">{{ feature.title }}</h3>
            <div v-html="feature.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionLabel: "",
      sectionTitle: "",
      sectionDescription: "",
      featureBlocks: [],
    };
  },
  async mounted() {
    await this.fetchSectionTitle("2AUvpIw0f3y7bEBs9tdIM5");
    await this.fetchFeatureBlocks("homeFeatureBlocks");
  },
  methods: {
    async fetchSectionTitle(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log("Section Title Response:", response);
        this.sectionLabel = response.fields.label;
        this.sectionTitle = response.fields.title;
      } catch (error) {
        console.error("Error fetching section title:", error);
      }
    },
    async fetchFeatureBlocks(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log("Feature Blocks Response:", response);
        this.featureBlocks = response.items.map((item) => ({
          icon: item.fields.icon,
          title: item.fields.title,
          description: this.$documentToHtmlString(item.fields.description),
        }));
      } catch (error) {
        console.error("Error fetching feature blocks:", error);
      }
    },
  },
};
</script>

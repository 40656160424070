<template>
  <div>
    <NavbarStyleNine />
    <PropertyDetails :propertyId="propertyId"></PropertyDetails>
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import PropertyDetails from "../Property/PropertyDetails.vue";
import { useRoute } from "vue-router";

export default {
  name: "PropertyDetailsPage",
  components: {
    NavbarStyleNine,
    PropertyDetails,
    FooterStyleFive,
  },
  setup() {
    const route = useRoute();
    return { route };
  },
  data() {
    return {
      propertyId: parseInt(this.route.params.id),
    };
  },
};
</script>

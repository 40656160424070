import { defineStore } from "pinia";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_URL}/membership`;

export const useMembershipStore = defineStore("Membership", {
  state: () => ({
    memberships: null,
  }),
  getters: {
    getMemberships(state) {
      return state.memberships;
    },
  },
  actions: {
    async getAllMemberships() {
      try {
        const response = await axios.get(`${baseUrl}`, {
          withCredentials: true,
        });
        this.memberships = response.data;
        return response.data;
      } catch (error) {
        console.error("Error agent:", error);
        return error;
      }
    },
  },
});

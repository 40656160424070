<template>
    <div class="blog-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Technology</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    19 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How sellers win when housing inventory is low</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog13.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Design</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    18 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Branding involves developing strategy to create point</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog14.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Startup</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    17 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Bootstrap 5 is open source software you can use</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog1.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Technology</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    25 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How is technology working with new things?</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog2.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Design</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    24 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Top 10 important tips on IT services & design</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog3.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <router-link to="/blog-tag">Startup</router-link>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    23 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How our company works in different ways</router-link></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogSearch'
}
</script>
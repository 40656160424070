<template>
  <div class="team-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
            <span class="sub-title">{{ teamSubtitle }}</span>
            <h2>{{ teamTitle }}</h2>
            <p v-html="teamDescription"></p>
            <router-link to="/team" class="btn-style-one green-color">
              {{ teamButtonText }}
              <i class="ph-caret-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="team-member-list">
            <ul>
              <li
                v-for="(member, index) in teamMembers"
                :key="index"
                :data-aos="'zoom-in'"
                data-aos-duration="1200"
              >
                <img :src="member.imageUrl" :alt="member.name" />
              </li>
            </ul>
            <img
              src="../../assets/images/shape/bg-shape1.jpg"
              alt="bg-image"
              class="bg-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamSubtitle: "",
      teamTitle: "",
      teamDescription: "",
      teamButtonText: "Meet Our Team",
      teamMembers: [],
    };
  },
  async mounted() {
    await this.fetchTeamContent("3TxmxF0ZXbCwqElO3pL26s");
    await this.fetchTeamMembers("teamMemberList");
  },
  methods: {
    async fetchTeamContent(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log("Team Content Response:", response);
        this.teamSubtitle = response.fields.label;
        this.teamTitle = response.fields.title;
        this.teamDescription = this.$documentToHtmlString(
          response.fields.description
        );
      } catch (error) {
        console.error("Error fetching team content:", error);
      }
    },
    async fetchTeamMembers(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log("Team Members Response:", response);
        this.teamMembers = response.items.map((item) => ({
          name: item.fields.name,
          imageUrl: `https:${item.fields.image.fields.file.url}`,
        }));
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    },
  },
};
</script>

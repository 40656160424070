<template>
  <div>
    <NavbarStyleNine />
    <Dashboard />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import Dashboard from "../Agent/DashboardTwo";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "ServicesFourPages",
  components: {
    NavbarStyleNine,
    Dashboard,
    FooterStyleFive,
  },
};
</script>

<template>
  <div></div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "InviteTop20",
  components: {},
  setup() {
    const route = useRoute();
    return { route };
  },
  data() {
    return {
      propertyId: this.route.params.propertyId,
    };
  },
  computed: {},
  methods: {},
  beforeMount() {},
};
</script>

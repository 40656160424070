<template>
    <div>
        <Navbar />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Tag: Technology</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/blog-grid">Blog</router-link></li>
                        <li>Technology</li>
                    </ul>
                </div>
            </div>
        </div>
        <BlogTag />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import BlogTag from '../BlogTag/BlogTag'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BlogTagPage',
    components: {
        Navbar,
        BlogTag,
        FooterStyleTwo,
    }
}
</script>
<template>
    <div>
        <NavbarStyleNine />
        <PageTitle v-if="auction" subTitle="Auction Winner"
            :heding="`Congratulations ${auction.winner.user.firstName} ${auction.winner.user.lastName}, you won the auction at ${getPropertyName()}`" />
        <div class="pricing-area bg-f1f5fd pb-75">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 col-lg-4">
                        <button @click="onPayClicked()" class="btn btn-success">{{ getPayButtonText() }}</button>
                    </div>
                </div>
            </div>
        </div>
        <button id="confirmationModalBtn" type="button" class="btn btn-primary d-none" data-bs-toggle="modal"
            data-bs-target="#confirmationModal"></button>
        <button id="confirmationModalCloseBtn" type="button" class="btn btn-primary d-none" data-bs-dismiss="modal"
            data-bs-target="#confirmationModal"></button>
        <div class="modal fade" id="confirmationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="confirmationModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="confirmationModalLabel">
                            Payment Confirmation
                        </h4>
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="modal-body fs-5">
                            <div>
                                <div ref="cardElementContainer" class="card-element mt-4 mb-4"></div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary fs-5" data-bs-dismiss="modal"
                                :disabled="loader">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary fs-5" :disabled="loader" @click="payBid">
                                {{ getPayButtonText() }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <FooterStyleFive />

    </div>
</template>

<script>
import { useAgentStore } from "../../stores/agent";
import { usePaymentStore } from "../../stores/payments";
import { useAuctionStore } from "../../stores/auctions";
import { usePropertyStore } from "../../stores/properties";
import { useBidStore } from "../../stores/bids";
import { useRoute, useRouter } from "vue-router";
import { loadStripe } from "@stripe/stripe-js";
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import PageTitle from "../Common/PageTitle";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import FormatCurrency from "../../utils/Currency";

export default {
    name: "AgentWinningPage",
    components: {
        NavbarStyleNine,
        PageTitle,
        FooterStyleFive,
    },
    setup() {
        const route = useRoute();
        const agentStore = useAgentStore();
        const auctionStore = useAuctionStore();
        const router = useRouter();
        const paymentStore = usePaymentStore();
        const propertyStore = usePropertyStore();
        const bidStore = useBidStore();

        return {
            route,
            agentStore,
            auctionStore,
            router,
            paymentStore,
            propertyStore,
            bidStore,
        };
    },
    data() {
        return {
            auction: null,
            agent: null,
            loader: false,
            stripe: null,
            card: null,
            states: [],
            amount: '0',
        };
    },
    methods: {
        FormatCurrency,
        findStateById(stateId) {
            const state = this.states.find((state) => state.id === stateId);
            if (state) {
                return state.name;
            } else {
                return "";
            }
        },
        async fetchAgent() {
            const agent = await this.agentStore.fetchAgent();
            if (agent) {
                this.agent = agent;
            }
        },
        async payBid() {
            // Get the token or payment method from Stripe
            const { error, paymentMethod } = await this.stripe.createPaymentMethod({
                type: "card",
                card: this.card,
            });


            if (error) {
                console.error(error);
                this.loader = false;
                return;
            }

            console.log("================================================");
            console.log(paymentMethod);

            const data = {
                paymentMethodId: paymentMethod.id,
                amount: this.auction.bid.bidAmount,
            };
            this.paymentStore.payWinningBid(data)
                .then(paymentConfirmation => {
                    if (paymentConfirmation.requiresAction) {
                        // Handle 3D Secure or authentication
                        this.stripe.confirmCardPayment(paymentConfirmation.clientSecret).then(result => {
                            if (result.error) {
                                console.error(result.error.message);
                                this.loader = false;
                            } else {
                                // Update is paid flag for bid
                                this.bidStore.payBid(this.auction.bid.bidId).then(res => {
                                    this.router.push("/AgentDashboard");
                                    document.getElementById("confirmationModalCloseBtn").click();
                                })
                                    .catch(error => {
                                        console.error(error);
                                        document.getElementById("confirmationModalCloseBtn").click();
                                    });
                            }
                        });
                    } else {
                        // Update is paid flag for bid
                        this.bidStore.payBid(this.auction.bid.bidId).then(res => {
                            this.router.push("/AgentDashboard");
                            document.getElementById("confirmationModalCloseBtn").click();
                        })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                })
                .catch(error => {
                    console.error('Payment failed', error);
                    this.loader = false;
                });
        },
        fetchStates() {
            this.propertyStore.getAllStates().then((res) => {
                if (res.status == 200) {
                    this.states = res.data;
                }
            });
        },
        async setupStripe() {
            await this.$nextTick();

            // Initialize Stripe
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
            const elements = this.stripe.elements();
            this.card = elements.create("card");
            this.card.mount(this.$refs.cardElementContainer);
        },
        async fetchAuction() {
            this.auction = await this.auctionStore.getWinningAuction(this.route.params.auctionId);
            this.amount = this.FormatCurrency(this.auction.bid.bidAmount.toString());
        },
        async onPayClicked() {
            document.getElementById("confirmationModalBtn").click();
        },
        getPayButtonText() {
            if (this.loader) return "Processing...";
            if (this.agent == null) return "Pay";
            if (this.auction == null) return "Pay";

            return `Pay ${this.FormatCurrency(this.auction.bid.bidAmount.toString())}`;
        },
        getPropertyName() {
            return this.auction.auction.property.address1.trimEnd() +
                ", " +
                this.auction.auction.property.city.trimEnd() +
                ", " +
                this.findStateById(this.auction.auction.property.stateId) +
                " " +
                this.auction.auction.property.zipCode.trimEnd();
        }
    },
    async mounted() {
        this.setupStripe();
    },
    async beforeMount() {
        await this.fetchAgent();
        await this.fetchAuction();
        await this.fetchStates()
    },
};
</script>

<style scoped>
.single-pricing-box {
    border: 1px solid #0d6efd;
}

.features-list li:hover i {
    background-color: black !important;
}
</style>
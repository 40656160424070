<template>
    <div class="courses-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses1.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $45
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Make a 2D Platformer with State Machines in Unity</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user1.jpg" alt="author">
                                        <span>David Allen</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 14 Lessons</li>
                            <li><i class="flaticon-team"></i> 125 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses2.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $55
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Write Like a Boss: Master Your Business Writing Skills</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user2.jpg" alt="author">
                                        <span>Alina Smith</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 10 Lessons</li>
                            <li><i class="flaticon-team"></i> 50 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses3.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $69
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Asynchronous JavaScript: Promises, Callbacks</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user3.jpg" alt="author">
                                        <span>James Andy</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 09 Lessons</li>
                            <li><i class="flaticon-team"></i> 41 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses4.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $45
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Node.js for Beginners: Go From Zero to Hero with Node.js</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user1.jpg" alt="author">
                                        <span>David Allen</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 14 Lessons</li>
                            <li><i class="flaticon-team"></i> 125 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses5.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $55
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Successful Course: Robot Humanoid Walk</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user2.jpg" alt="author">
                                        <span>Alina Smith</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 10 Lessons</li>
                            <li><i class="flaticon-team"></i> 50 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses6.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $69
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Graphic Design Masterclass: Design Course A-z</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user3.jpg" alt="author">
                                        <span>James Andy</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 09 Lessons</li>
                            <li><i class="flaticon-team"></i> 41 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses7.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $45
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Photography Is A Complete Guideline For Learning</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user1.jpg" alt="author">
                                        <span>David Allen</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 14 Lessons</li>
                            <li><i class="flaticon-team"></i> 125 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses8.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $55
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Finance Fundamentals - Easy Guide To Better Finances</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user2.jpg" alt="author">
                                        <span>Alina Smith</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 10 Lessons</li>
                            <li><i class="flaticon-team"></i> 50 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses9.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $69
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Medical Terminology - Start Basic Learning Course</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user3.jpg" alt="author">
                                        <span>James Andy</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 09 Lessons</li>
                            <li><i class="flaticon-team"></i> 41 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                    <div class="pagination-area yellow-color">
                        <div class="nav-links">
                            <a href="/courses" class="prev page-numbers">prev</a>
                            <span class="page-numbers current">1</span>
                            <a href="/courses" class="page-numbers">2</a>
                            <a href="/courses" class="page-numbers">3</a>
                            <a href="/courses" class="next page-numbers">next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Courses'
}
</script>
<template>
    <div>
        <NavbarStyleNine />
        <!-- <PageTitle subTitle="Authentication" heding="Howdy! Welcome Back!" /> -->
        <Register />
        <FooterStyleFive />
    </div>
</template>

<script>
import NavbarStyleNine from '../Layouts/NavbarStyleNine'
// import PageTitle from '../Common/PageTitle'
import Register from '../Authentication/Register'
import FooterStyleFive from '../Layouts/FooterStyleFive'

export default {
    name: 'AuthenticationPage',
    components: {
        NavbarStyleNine,
        // PageTitle,
        Register,
        FooterStyleFive,
    }
}
</script>
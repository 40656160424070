<template>
    <div class="screenshots-area bg-263238 ptb-100">
        <div class="container">
            <div class="section-title white-color">
                <span class="sub-title">Screenshots</span>
                <h2>Easily Customize within <br>One Minute</h2>
            </div>

            <div class="screenshots-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="screen1-tab" data-bs-toggle="tab" data-bs-target="#screen1" type="button" role="tab" aria-controls="screen1" aria-selected="true">
                            <i class="ph-swap"></i>
                            Budget Overview
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="screen2-tab" data-bs-toggle="tab" data-bs-target="#screen2" type="button" role="tab" aria-controls="screen2" aria-selected="false">
                            <i class="ph-swap"></i>
                            Create & Adjust
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="screen3-tab" data-bs-toggle="tab" data-bs-target="#screen3" type="button" role="tab" aria-controls="screen3" aria-selected="false">
                            <i class="ph-presentation-chart"></i>
                            View Reports
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="screen4-tab" data-bs-toggle="tab" data-bs-target="#screen4" type="button" role="tab" aria-controls="screen4" aria-selected="false">
                            <i class="ph-list-plus"></i>
                            Integrations
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="screen1" role="tabpanel">
                        <img src="../../assets/images/screenshots/screen1.jpg" alt="screenshots">
                    </div>
                    <div class="tab-pane fade" id="screen2" role="tabpanel">
                        <img src="../../assets/images/screenshots/screen2.jpg" alt="screenshots">
                    </div>
                    <div class="tab-pane fade" id="screen3" role="tabpanel">
                        <img src="../../assets/images/screenshots/screen3.jpg" alt="screenshots">
                    </div>
                    <div class="tab-pane fade" id="screen4" role="tabpanel">
                        <img src="../../assets/images/screenshots/screen4.jpg" alt="screenshots">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Screenshots',
}
</script>
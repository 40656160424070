<template>
    <div class="working-process-area ptb-100 bg-13965f">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-img" data-aos="fade-up" data-aos-duration="1200">
                        <img src="../../assets/images/working-process2.png" alt="working-process">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-text">
                        <span class="sub-title">Our Working Process</span>
                        <h2 class="nunito-font">Dedicated to help anything people’s needs</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Integrate</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Manage</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Analyze</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape10"><img src="../../assets/images/shape/shape10.png" alt="shape"></div>
        <div class="shape11"><img src="../../assets/images/shape/shape11.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'WorkingProcess'
}
</script>
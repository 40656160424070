<template>
    <div class="help-desk-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="section-title text-start">
                        <span class="sub-title">How Can Help You</span>
                        <h2>We’re helping teams do their best work</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                            <div class="single-help-desk-item">
                                <div class="icon">
                                    <i class="flaticon-growth"></i>
                                </div>
                                <h3>The best consulting services</h3>
                                <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                                <router-link to="/services-details" class="link-btn">
                                    Learn More 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            <div class="single-help-desk-item">
                                <div class="icon">
                                    <i class="ph-currency-dollar"></i>
                                </div>
                                <h3>Control over the finances system</h3>
                                <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                                <router-link to="/services-details" class="link-btn">
                                    Learn More 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpDesk'
}
</script>
<template>
    <div class="app-download-area bg-yellow pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="app-download-image">
                        <img src="../../assets/images/app-download.png" data-aos="fade-up" data-aos-duration="1200" alt="app-download">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-download-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">Download App</span>
                        <h2 class="nunito-font" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Let's get your free copy from Apple and Play store</h2>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <a href="#" class="playstore-btn" target="_blank">
                                <img src="../../assets/images/play-store.png" alt="image">
                                Get It On
                                <span>Google Play</span>
                            </a>
                            <a href="#" class="applestore-btn" target="_blank">
                                <img src="../../assets/images/apple-store.png" alt="image">
                                Download on the
                                <span>Apple Store</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload'
}
</script>
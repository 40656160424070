<template>
    <div class="courses-area pb-75">
        <div class="container">
            <div class="section-title">
                <h2>More Courses You <br>Might Like</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses1.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $45
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Make a 2D Platformer with State Machines in Unity</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user1.jpg" alt="author">
                                        <span>David Allen</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 14 Lessons</li>
                            <li><i class="flaticon-team"></i> 125 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses2.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $55
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Write Like a Boss: Master Your Business Writing Skills</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user2.jpg" alt="author">
                                        <span>Alina Smith</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 10 Lessons</li>
                            <li><i class="flaticon-team"></i> 50 Students</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-courses-box">
                        <router-link to="/courses-details" class="image d-block">
                            <img src="../../assets/images/courses/courses3.jpg" alt="courses">
                        </router-link>
                        <div class="content">
                            <div class="price">
                                $69
                            </div>
                            <h3 class="nunito-font">
                                <router-link to="/courses-details">Asynchronous JavaScript: Promises, Callbacks</router-link>
                            </h3>
                            <ul class="info d-flex align-items-center justify-content-between">
                                <li>
                                    <div class="author d-flex align-items-center">
                                        <img src="../../assets/images/user/user3.jpg" alt="author">
                                        <span>James Andy</span>
                                    </div>
                                </li>
                                <li><span class="status">Popular</span></li>
                            </ul>
                        </div>
                        <ul class="box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-video-player"></i> 09 Lessons</li>
                            <li><i class="flaticon-team"></i> 41 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Courses'
}
</script>
<template>
    <div class="services-area with-top-border pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title purple-color">What We Offer</span>
                <h2 class="nunito-font">Connect with users across channels</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon13.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Website</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon14.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">LiveChat</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon15.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Slack</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeOffer'
}
</script>
<template>
    <div class="faq-area bg-f1f5fd">
      <div class="container">
        <div class="faq-accordion-content">
          <div class="box">
            <div class="accordion" id="faqAccordion">
              <div
                v-for="(faq, index) in faqs"
                :key="index"
                class="accordion-item"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  :data-bs-toggle="'collapse'"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="false"
                  :aria-controls="'collapse' + index"
                >
                  {{ faq.question }}
                </button>
                <div
                  :id="'collapse' + index"
                  class="accordion-collapse collapse"
                  :data-bs-parent="'#faqAccordion'"
                >
                  <div class="accordion-body">
                    <div v-html="faq.answer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Faq',
    data() {
      return {
        faqs: [], 
      };
    },
    async mounted() {
      await this.fetchFaqs();
    },
    methods: {
      async fetchFaqs() {
        try {
          const response = await this.$contentfulClient.getEntries({
            content_type: 'faqs', 
          });

          this.faqs = response.items.map((item) => ({
            question: item.fields.question,
            answer: this.$documentToHtmlString(item.fields.answer),
          }));
        } catch (error) {
          console.error('Error fetching FAQs:', error);
        }
      },
    },
  };
  </script>
  
<template>
  <div class="card mb-3">
    <div
      class="row g-0 align-items-center"
      style="cursor: pointer"
      @click="toggleCheckbox"
    >
      <div class="col-auto p-3">
        <input
          type="checkbox"
          v-model="selected"
          @click.stop
          @change="emitSelection"
          class="form-check-input ms-3"
        />
      </div>
      <div class="col-auto">
        <div v-if="icon" class="image-container">
          <img
            :src="icon"
            alt="Agent Icon"
            class="img-fluid rounded-circle"
            style="width: 3.75rem; height: 3.75rem; object-fit: cover"
          />
        </div>
        <div v-else>
          <i class="ph ph-user-circle fw-bolder" style="font-size: 3.75rem"></i>
        </div>
      </div>
      <div class="col">
        <div class="card-body">
          <p class="card-text mb-1"><strong>Name:</strong> {{ name }}</p>
          <p class="card-text mb-1">
            <strong>Total Number of Sales:</strong> {{ numberOfSales }}
          </p>
          <p class="card-text mb-0">
            <strong>Total Value of Sales:</strong>
            {{ FormatCurrency(valueOfSales.toString()) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatCurrency from "../../utils/Currency";

export default {
  name: "InviteAgentCard",
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    numberOfSales: {
      type: Number,
      required: true,
    },
    valueOfSales: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    selectedEmails: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: false, // Internal selected state
    };
  },
  created() {
    // Initialize the selected state based on the selectedEmails prop
    if (this.selectedEmails.includes(this.email)) {
      this.selected = true;
    }
  },
  methods: {
    emitSelection() {
      this.$emit("selection-change", {
        email: this.email,
        selected: this.selected,
      });
    },
    FormatCurrency,
    toggleCheckbox() {
      this.selected = !this.selected;
      this.emitSelection();
    },
  },
};
</script>

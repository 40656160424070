<template>
    <div class="medical-banner-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="medical-banner-content">
              <h1>{{ bannerTitle }}</h1>
              <div v-html="richTextDescription1"></div>
              <div v-html="richTextDescription2"></div>
              <router-link to="/Register" class="btn-style-one green-color">
                {{ buttonText }}
                <i class="ph-caret-right"></i>
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="medical-banner-image">
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="image">
                    <img
                      :src="bannerImage1"
                      data-aos="flip-left"
                      data-aos-easing="ease"
                      data-aos-delay="300"
                      alt="banner-image"
                      style="width: 300px;"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="image">
                    <img
                      :src="bannerImage2"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-delay="300"
                      alt="banner-image"
                      style="width: 300px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      bannerTitle: "",
      richTextDescription1: "",
      richTextDescription2: "",
      bannerImage1: "",
      bannerImage2: "",
      buttonText: "",
    };
  },
  async mounted() {
    const entryId = "27YoDDaUljpI7jaXDA6yNM";
    await this.fetchBannerData(entryId);
  },
  methods: {
    async fetchBannerData(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        
        this.bannerTitle = response.fields.title;
        this.richTextDescription1 = this.$documentToHtmlString(response.fields.description1);
        this.richTextDescription2 = this.$documentToHtmlString(response.fields.description2); 
        this.bannerImage1 = `https:${response.fields.image1.fields.file.url}`;
        this.bannerImage2 = `https:${response.fields.image2.fields.file.url}`;
        this.buttonText = response.fields.buttonText;
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    },
  },
};
</script>

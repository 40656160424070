<template>
    <div>
        <NavbarStyleNine />
        <PageTitle subTitle="Add your property" heding="Let's work on getting your property added." />
        <AddPropertyForm />
        <OurGoal />
        <WhatWeDo />
        <WhatWeOffer />
        <Testimonials />
        <FooterStyleEight />
    </div>
</template>

<script>
import NavbarStyleNine from '../Layouts/NavbarStyleNine'
import PageTitle from '../Common/PageTitle'
import OurGoal from '../ServicesFour/OurGoal'
import WhatWeDo from '../ServicesFour/WhatWeDo'
import WhatWeOffer from '../ServicesFour/WhatWeOffer'
import Testimonials from '../ServicesFour/Testimonials'
import FooterStyleEight from '../Layouts/FooterStyleEight'
import AddPropertyForm from '../Forms/AddPropertyForm'

export default {
    name: 'ServicesFourPage',
    components: {
        NavbarStyleNine,
        PageTitle,
        OurGoal,
        WhatWeDo,
        WhatWeOffer,
        Testimonials,
        FooterStyleEight,
        AddPropertyForm
    }
}
</script>
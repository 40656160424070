<template>
  <div>
    <NavbarStyleNine />
    <MainBanner />
    <HowItWorks />
    <HomeOwnerBenefits />
    <AgentBenefits />
    <FooterStyleFive class="bg-f9f9f9" />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import MainBanner from "../Home/MainBanner.vue";
import HowItWorks from "../Home/HowItWorks.vue";
import HomeOwnerBenefits from "../Home/HomeOwnerBenefits.vue";
import AgentBenefits from "../Home/AgentBenefits.vue";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "MedicalPage",
  components: {
    NavbarStyleNine,
    MainBanner,
    HowItWorks,
    HomeOwnerBenefits,
    AgentBenefits,
    FooterStyleFive,
  },
};
</script>

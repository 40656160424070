<template>
  <div class="template-footer-five pt-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6"></div>

        <div
          class="col-lg-4 col-md-6 d-flex justify-content-center align-self-center"
        >
          <div>
            <router-link class="text-black" to="/terms-conditions"
              >Terms & Conditions</router-link
            >
          </div>
          <div class="text-secondary mx-3">|</div>
          <div>
            <router-link class="text-black" to="/contact"
              >Contact Us</router-link
            >
          </div>
        </div>
        <div class="col-lg-4 col-md-6"></div>
      </div>
    </div>

    <div class="copyright-area mt-3">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-5">
            <p>©{{ currentYear }} {{ rowText }}</p>
          </div>
          <div class="col-lg-4 col-md-3">
            <div class="logo">
              <router-link to="/">
                <img :src="rowImageUrl" alt="logo" />
              </router-link>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <ul class="social-links">
              <li v-for="(social, index) in socialLinks" :key="index">
                <a
                  v-if="isValidUrl(social.url)"
                  :href="social.url"
                  target="_blank"
                >
                  <i :class="social.icon"></i>
                </a>
                <span v-else>{{ social.icon }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterStyleFive",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      column2Title: "",
      column3Title: "",
      column3Text1: "",
      column3Placeholder: "",
      column3ButtonText: "",
      rowText: "",
      rowImageUrl: "",
      socialLinks: [],
    };
  },
  async mounted() {
    await this.fetchFooterContent();
  },
  methods: {
    async fetchFooterContent() {
      try {
        const entry = await this.$contentfulClient.getEntry(
          "6a7F8RLEUWmyLRh37I9UYY"
        );
        this.rowText = entry.fields.rowText;
        this.rowImageUrl = `https:${entry.fields.rowImage.fields.file.url}`;

        this.socialLinks = [
          {
            icon: entry.fields.rowIcon1,
            url: this.cleanUrl(entry.fields.rowLink1),
          },
          {
            icon: entry.fields.rowIcon4,
            url: this.cleanUrl(entry.fields.rowLink4),
          },
        ];
      } catch (error) {
        console.error("Error fetching footer content:", error);
      }
    },

    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    },

    cleanUrl(url) {
      if (url && url.startsWith("https://")) {
        return url;
      }
      return "";
    },
  },
};
</script>

<template>
  <div
    :style="backgroundImageStyle"
    class="profile-authentication-area ptb-100"
  >
    <div class="container">
      <div class="row"></div>
      <div class="row">
        <div class="col-lg-6 col-md-12 text-white">
          <!-- Let's visually make them choose here whether they are an agent or homeowner. Let's also get a nice home background that they have on the current register on agent arena. -->
        </div>
        <div class="col-lg-6 col-md-12 text-white">
          <div class="card p-5" style="border-radius: 3%">
            <div class="register-form">
              <h2 class="text-center">Sign Up</h2>
              <p class="fs-6 text-danger mb-1" v-if="errorHasOccurred">
                {{ errorMessage }}
              </p>
              <form @submit.prevent="handleSubmit">
                <div class="form-group mb-2">
                  <input
                    v-model="registerForm.firstName"
                    type="text"
                    :class="{
                      'is-invalid': !firstNameValid,
                    }"
                    class="form-control"
                    placeholder="First Name"
                    style="background-color: #bccc"
                  />
                  <span v-if="!firstNameValid" class="text-danger fs-6"
                    >This field is required</span
                  >
                </div>
                <div class="form-group mb-2">
                  <input
                    v-model="registerForm.lastName"
                    type="text"
                    :class="{
                      'is-invalid': !lastNameValid,
                    }"
                    class="form-control"
                    placeholder="Last Name"
                    style="background-color: #bccc"
                  />
                  <span v-if="!lastNameValid" class="text-danger fs-6"
                    >This field is required</span
                  >
                </div>
                <div class="form-group mb-2">
                  <input
                    v-model="registerForm.email"
                    type="text"
                    :class="{
                      'is-invalid': !emailValid,
                    }"
                    class="form-control"
                    placeholder="Email"
                    style="background-color: #bccc"
                  />
                  <span v-if="!emailValid" class="text-danger fs-6"
                    >Please ensure a valid email has been entered</span
                  >
                </div>
                <div class="form-group mb-2">
                  <input
                    v-model="formattedPhone"
                    type="text"
                    :class="{
                      'is-invalid': !phoneValid,
                    }"
                    class="form-control"
                    placeholder="Phone"
                    style="background-color: #bccc"
                  />
                  <span v-if="!phoneValid" class="text-danger fs-6"
                    >Please ensure a valid phone number has been entered</span
                  >
                </div>
                <div class="form-group mb-2">
                  <div class="input-group mb-0">
                    <input
                      v-model="registerForm.password"
                      :type="passwordType"
                      :class="{ 'is-invalid': !passwordValid }"
                      class="form-control"
                      placeholder="Password"
                      style="background-color: #bccc"
                    />
                    <span
                      role="button"
                      class="input-group-text"
                      @click="setPasswordVisibility()"
                    >
                      <i v-if="passwordVisible" class="ph ph-eye"></i>
                      <i v-else class="ph ph-eye-slash"></i>
                    </span>
                  </div>
                  <!-- <div class="password-criteria">
                    <div
                      :class="[
                        'd-flex align-items-center fs-6',
                        hasMinLength ? 'text-success' : 'text-danger',
                      ]"
                    >
                      <i
                        v-if="hasMinLength"
                        class="ph ph-check fw-bolder fs-5"
                      ></i>
                      <i v-else class="ph ph-x fw-bolder fs-5"></i>
                      At least 8 characters
                    </div>
                    <div
                      :class="[
                        'd-flex align-items-center fs-6',
                        hasUpperAndLowerCase ? 'text-success' : 'text-danger',
                      ]"
                    >
                      <i
                        v-if="hasUpperAndLowerCase"
                        class="ph ph-check fw-bolder fs-5"
                      ></i>
                      <i v-else class="ph ph-x fw-bolder fs-5"></i>
                      A mixture of both uppercase and lowercase letters
                    </div>
                    <div
                      :class="[
                        'd-flex align-items-center fs-6',
                        hasLettersAndNumbers ? 'text-success' : 'text-danger',
                      ]"
                    >
                      <i
                        v-if="hasLettersAndNumbers"
                        class="ph ph-check fw-bolder fs-5"
                      ></i>
                      <i v-else class="ph ph-x fw-bolder fs-5"></i>
                      A mixture of letters and numbers
                    </div>
                    <div
                      :class="[
                        'd-flex align-items-center fs-6',
                        hasSpecialChar ? 'text-success' : 'text-danger',
                      ]"
                    >
                      <i
                        v-if="hasSpecialChar"
                        class="ph ph-check fw-bolder fs-5"
                      ></i>
                      <i v-else class="ph ph-x fw-bolder fs-5"></i>
                      Include at least one special character, e.g.(! @ # ?)
                    </div>
                  </div> -->
                </div>
                <div class="form-group mb-2">
                  <div class="input-group mb-0">
                    <input
                      v-model="registerForm.confirmPassword"
                      :type="confirmPasswordType"
                      :class="{
                        'is-invalid': !confirmPasswordValid,
                      }"
                      class="form-control"
                      placeholder="Confirm Password"
                      style="background-color: #bccc"
                    />
                    <span
                      role="button"
                      class="input-group-text"
                      @click="setConfirmPasswordVisibility()"
                    >
                      <i v-if="confirmPasswordVisible" class="ph ph-eye"></i>
                      <i v-else class="ph ph-eye-slash"></i>
                    </span>
                  </div>
                  <span v-if="!confirmPasswordValid" class="text-danger fs-6"
                    >Please ensure that the passwords match</span
                  >
                </div>
                <b-button class="btn-style-two" :disabled="true" type="submit"
                  >Coming Soon!</b-button
                >
                <p>
                  Already have an account?
                  <router-link
                    to="/login"
                    class="text-primary text-decoration-underline link-btn"
                  >
                    Login
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "../../stores/users";
// import { useRouter } from "vue-router";
export default {
  data() {
    return {
      registerForm: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      errorHasOccurred: "",
      errorMessage: "",
      formattedPhone: "",
      loader: false,
      image: require("@/assets/images/Register1.png"),
      passwordVisible: false,
      passwordType: "Password",
      confirmPasswordVisible: false,
      confirmPasswordType: "Password",
      emailValid: true,
      firstNameValid: true,
      lastNameValid: true,
      phoneValid: true,
      passwordValid: true,
      confirmPasswordValid: true,
      countDown: 5,
    };
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
      };
    },
    validateEmail() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.registerForm.email
      );
    },
    hasMinLength() {
      return this.registerForm.password.length >= 8;
    },
    hasUpperAndLowerCase() {
      return (
        /[A-Z]/.test(this.registerForm.password) &&
        /[a-z]/.test(this.registerForm.password)
      );
    },
    hasLettersAndNumbers() {
      return (
        /[A-Za-z]/.test(this.registerForm.password) &&
        /[0-9]/.test(this.registerForm.password)
      );
    },
    hasSpecialChar() {
      return /[!@#?]/.test(this.registerForm.password);
    },
    passwordMatch() {
      return this.registerForm.password != ""
        ? this.registerForm.password === this.registerForm.confirmPassword
        : false;
    },
  },
  watch: {
    formattedPhone(newValue) {
      let digits = newValue.replace(/\D/g, "").substring(0, 10);
      let formatted = "";
      if (digits.length > 0) {
        formatted = `(${digits.substring(0, 3)}`;
        if (digits.length > 3) {
          formatted += `) ${digits.substring(3, 6)}`;
        }
        if (digits.length > 6) {
          formatted += `-${digits.substring(6, 10)}`;
        }
      }
      this.formattedPhone = formatted;
    },
  },
  methods: {
    redirectToHome() {
      this.$router.push({ name: "Medical" });
    },
    validatePhoneNumber() {
      return /^\(\d{3}\) \d{3}-\d{4}$/.test(this.formattedPhone);
    },
    setPasswordVisibility() {
      if (this.passwordVisible) {
        this.passwordVisible = false;
        this.passwordType = "password";
      } else {
        this.passwordVisible = true;
        this.passwordType = "text";
      }
    },
    setConfirmPasswordVisibility() {
      if (this.confirmPasswordVisible) {
        this.confirmPasswordVisible = false;
        this.confirmPasswordType = "password";
      } else {
        this.confirmPasswordVisible = true;
        this.confirmPasswordType = "text";
      }
    },
    async handleSubmit() {},
  },
};
</script>

<style scoped>
.is-invalid {
  border: 1px solid red;
}

.profile-authentication-area {
  position: relative;
}
</style>

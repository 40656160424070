<template>
  <div class="container my-5">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-center">
            <h3>Choose your role</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-md-6 d-flex justify-content-center align-self-center pt-4 pb-4"
        >
          <input
            type="radio"
            class="btn-check"
            name="options-outlined"
            id="homeowner-outlined"
            v-model="homeownerSelection"
            :value="true"
            :disabled="loader"
          />
          <label
            :disabled="loader"
            class="btn btn-outline-primary w-75"
            for="homeowner-outlined"
          >
            <i class="ph ph-house-line d-block fs-1"></i>
            <span class="fs-3 d-block">Homeowner</span>
            <ul class="text-start">
              <li>An individual who owns residential property.</li>
              <li>Seeks to sell the property through competitive bidding.</li>
              <li>Can list their properties on the platform.</li>
              <li>
                Allows Real Estate Agents to place bids for representation.
              </li>
              <li>Has full control over which bids to accept.</li>
              <li>Ensures they choose the best agent to sell their home.</li>
            </ul>
          </label>
        </div>
        <div
          class="col-12 col-md-6 d-flex justify-content-center align-self-center pt-4 pb-4"
        >
          <input
            type="radio"
            class="btn-check"
            name="options-outlined"
            id="agent-outlined"
            v-model="agentSelection"
            :value="true"
            :disabled="loader"
          />
          <label
            :disabled="loader"
            class="btn btn-outline-primary w-75"
            for="agent-outlined"
          >
            <i class="ph-thin ph-briefcase d-block fs-1"></i>
            <span class="fs-3 d-block">Real Estate Agent</span>
            <ul class="text-start">
              <li>
                A licensed professional representing clients in property
                transactions.
              </li>
              <li>
                Can browse properties listed by Homeowners on the platform.
              </li>
              <li>Places bids to secure the opportunity to sell properties.</li>
              <li>Can only bid on homes they wish to represent.</li>
              <li>Strives to offer competitive terms to win business.</li>
            </ul>
          </label>
        </div>
      </div>
    </div>
    <button
      id="confirmationModalBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-toggle="modal"
      data-bs-target="#confirmationModal"
    ></button>
    <button
      id="confirmationModalCloseBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-dismiss="modal"
      data-bs-target="#confirmationModal"
    ></button>
    <div
      class="modal fade"
      id="confirmationModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="confirmationModalLabel">
              Confirm Role Selection
            </h4>
          </div>
          <div class="modal-body fs-5">
            You have elected to be a
            <strong>
              {{
                homeownerSelection
                  ? "homeowner"
                  : agentSelection
                  ? "Real estate agent"
                  : ""
              }} </strong
            >. Please ensure this is correct as role selection is permanent.
            <form v-if="agentSelection" class="mt-3" novalidate>
              <div class="mb-2">
                <label for="agentLicenceNumber" class="form-label"
                  >Agent Licence Number</label
                >
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': errors.agentLicenceNumber,
                    'is-valid':
                      !errors.agentLicenceNumber && agentLicenceNumber,
                  }"
                  id="agentLicenceNumber"
                  v-model="agentLicenceNumber"
                  @input="validateLicenceNumber"
                />
                <div v-if="errors.agentLicenceNumber" class="invalid-feedback">
                  {{ errors.agentLicenceNumber }}
                </div>
              </div>
              <div class="mb-2">
                <label for="agentArea" class="form-label">Agent Area</label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': errors.agentArea,
                    'is-valid': !errors.agentArea && agentArea,
                  }"
                  id="agentArea"
                  v-model="agentArea"
                  @change="validateAgentArea"
                >
                  <option value="">Select an area</option>
                  <option value="All Areas">All Areas</option>
                  <option value="Broward">Broward</option>
                  <option value="Palm Beach">Palm Beach</option>
                  <option value="St Lucie">St Lucie</option>
                  <option value="Indian River Counties">
                    Indian River Counties
                  </option>
                </select>
                <div v-if="errors.agentArea" class="invalid-feedback">
                  {{ errors.agentArea }}
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              id=""
              type="button"
              class="btn btn-secondary fs-5"
              data-bs-dismiss="modal"
              :disabled="loader"
              @click="resetSelection"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary fs-5"
              :disabled="loader || isFormInvalid"
              @click="submitSelection"
            >
              {{ loader ? "Selecting..." : "Confirm Selection" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "../../stores/users";

export default {
  name: "RoleSelection",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userStore = useUserStore();
    return {
      router,
      route,
      userStore,
    };
  },
  data() {
    return {
      homeownerSelection: false,
      agentSelection: false,
      loader: false,
      agentLicenceNumber: "",
      agentArea: "",
      errors: {
        agentLicenceNumber: "",
        agentArea: "",
      },
    };
  },
  computed: {
    isFormInvalid() {
      return (
        this.agentSelection &&
        (this.errors.agentLicenceNumber ||
          this.errors.agentArea ||
          !this.agentLicenceNumber ||
          !this.agentArea)
      );
    },
  },
  watch: {
    homeownerSelection() {
      if (this.homeownerSelection) {
        this.agentSelection = false;
        document.getElementById("confirmationModalBtn").click();
      }
    },
    agentSelection() {
      if (this.agentSelection) {
        this.homeownerSelection = false;
        document.getElementById("confirmationModalBtn").click();
      }
    },
    agentLicenceNumber(value) {
      this.validateLicenceNumber(value);
    },
    agentArea(value) {
      this.validateAgentArea(value);
    },
  },
  methods: {
    async submitSelection() {
      this.loader = true;
      const roleId = this.homeownerSelection ? 3 : this.agentSelection ? 2 : 4;

      const roleData = {
        roleId: roleId,
        licenseId: this.agentLicenceNumber,
        area: this.agentArea,
      };

      await this.userStore.updateRole(roleData).then((res) => {
        if (res.status == 200) {
          let redirectTo;
          switch (roleId) {
            case 2:
              redirectTo = "/manage-membership";
              break;
            case 3:
              redirectTo = "/HomeOwnerDashboard";
              break;
            default:
              redirectTo = "/";
          }

          const targetRoute = { path: redirectTo };
          document.getElementById("confirmationModalCloseBtn").click();
          this.router.push(targetRoute);
        }
      });
    },
    resetSelection() {
      this.homeownerSelection = false;
      this.agentSelection = false;
    },
    validateLicenceNumber(value) {
      if (!value) {
        this.errors.agentLicenceNumber = "Agent Licence Number is required.";
      } else if (value.length < 5) {
        this.errors.agentLicenceNumber =
          "Agent Licence Number must be at least 5 characters.";
      } else {
        this.errors.agentLicenceNumber = "";
      }
    },
    validateAgentArea(value) {
      if (!value) {
        this.errors.agentArea = "Agent Area is required.";
      } else {
        this.errors.agentArea = "";
      }
    },
  },
};
</script>

<template>
    <div>
        <Navbar />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Products Details</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/products">Shop</router-link></li>
                        <li>Black Men's T-Shirt</li>
                    </ul>
                </div>
            </div>
        </div>
        <ProductsDetails />
        <FooterStyleTwo />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import ProductsDetails from '../ProductsDetails/ProductsDetails'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'ProductsDetailsPage',
    components: {
        Navbar,
        ProductsDetails,
        FooterStyleTwo,
    }
}
</script>
<template>
    <div class="case-studies-area pb-100 bg-f1f5fd">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies1.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-startup"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Business Startup</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies2.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-consulting"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Finance Consulting</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies3.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-personal-wealth"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Wealth Management</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies4.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-management"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Business Planning</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies5.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-data-management"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Data Management</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-case-studies-box">
                        <router-link to="/portfolio-details" class="d-block image">
                            <img src="../../assets/images/case-studies/case-studies6.jpg" alt="case-studies-image">
                        </router-link>
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-consulting"></i>
                            </div>
                            <h3><router-link to="/portfolio-details">Marketing Planning</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consec tetur adipis cing elit. Sed est non feugiat sagi ttis donec.</p>
                            <router-link to="/portfolio-details" class="link-btn">Learn More <i class="ph-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <a href="/portfolio" class="prev page-numbers">prev</a>
                            <span class="page-numbers current">1</span>
                            <a href="/portfolio" class="page-numbers">2</a>
                            <a href="/portfolio" class="page-numbers">3</a>
                            <a href="/portfolio" class="next page-numbers">next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Portfolio'
}
</script>
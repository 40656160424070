import { defineStore } from "pinia";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_URL}/agent`;

export const useAgentStore = defineStore("agent", {
  state: () => ({
    agent: null,
    agents: [],
  }),
  getters: {
    getAgent(state) {
      return state.agent;
    },
  },
  actions: {
    async fetchAgent() {
      try {
        const response = await axios.get(`${baseUrl}/loggedIn`, {
          withCredentials: true,
        });
        this.agent = response.data;
        return response.data;
      } catch (error) {
        console.error("Error agent:", error);
        return error;
      }
    },
    async updateAgent(formData) {
      try {
        const response = await axios.post(
          `${baseUrl}/updateProfile`,
          formData,
          { withCredentials: true }
        );
        return response;
      } catch (error) {
        console.error("Error agent:", error);
        return error;
      }
    },
    async updateMembership(membershipTier) {
      try {
        const response = await axios.post(
          `${baseUrl}/membership/${membershipTier}`,
          {},
          { withCredentials: true }
        );
        return response;
      } catch (error) {
        console.error("Error agent:", error);
        return error;
      }
    },
    async getAll() {
      try {
        const response = await axios.get(`${baseUrl}/`, {
          withCredentials: true,
        });
        this.agents = response.data;
        return response.data;
      } catch (error) {
        console.error("Error agent:", error);
        return [];
      }
    },
    async fetchAllCountyAgents(CountyAgentRequest) {
      try {
        //CountyAgentRequest example
        // {
        //   page: 1,
        //   itemsPerPage: 25,
        // }
        const response = await axios.post(
          `${baseUrl}/fetchAllCountyAgents`,
          CountyAgentRequest,
          {
            withCredentials: true,
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error agent:", error);
        return [];
      }
    },
    async getTopAgents(numberOfAgents) {
      try {
        const response = await axios.get(
          `${baseUrl}/getTopAgents/${numberOfAgents}`,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error agent:", error);
        return [];
      }
    },
  },
});

<template>
  <div class="d-flex w-100 row agentsBenefitsBg">
    <div class="w-1200px mx-auto">
      <div class="text-center w-900px">
        <h2 class="display-6 fw-bold text-uppercase mb-3">Agent Benefits</h2>
        <div class="row justify-content-center">
          <!-- First Benefit -->
          <div class="col-md-10 d-flex align-items-start mb-3 gap-4">
            <span
              class="rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
              style="
                width: 100px;
                height: 100px;
                font-size: 54px;
                border: 4px solid #555350;
              "
            >
              1
            </span>
            <div class="text-start">
              <h3 class="h4 fw-bold mb-2">Streamlined Listing Acquisition</h3>
              <p class="howTo-text">
                Agents can now purchase listings directly, bypassing the
                traditional approach of investing significant time and money
                into marketing campaigns to generate leads. This streamlined
                process eliminates unnecessary hassle and uncertainty associated
                with traditional marketing efforts.
              </p>
            </div>
          </div>

          <!-- Second Benefit -->
          <div class="col-md-10 d-flex align-items-start mb-3 gap-4">
            <span
              class="rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
              style="
                width: 100px;
                height: 100px;
                font-size: 54px;
                border: 4px solid #555350;
              "
            >
              2
            </span>
            <div class="text-start">
              <h3 class="h4 fw-bold mb-2">
                Thorough Understanding of Listings
              </h3>
              <p class="howTo-text">
                Agents gain a thorough understanding of potential listings
                before committing to sell them. This ensures they have in-depth
                knowledge of a home upfront, preventing surprises and enhancing
                their ability to effectively market the property.
              </p>
            </div>
          </div>

          <!-- Button -->
          <div class="col-12 text-center mt-4">
            <router-link to="/register" class="btn-style-two mt-2 px-5 py-3">
              Learn More
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentBenefits",
  components: {},
};
</script>

<template>
  <div class="container my-5">
    <div class="card">
      <div class="card-header bg-light">
        <div class="row">
          <div class="col-auto col-md-4 col-lg-4"></div>
          <div class="col-12 col-md-4 col-lg-4 text-center">
            <h3>All Listings</h3>
          </div>
        </div>
      </div>
      <div v-if="properties.length > 0" class="propertyListings">
        <PropertyListing
          v-for="property in properties"
          :key="property.id"
          :propertyId="property.id"
          :propertyImageUrl="property.propertyPhotoUrls[0]"
          :propertyAddress="property.fullAddress"
          :bottomLinePrice="property.bottomLinePrice.toString()"
          :openHouseDate="property.openHouseDate"
          :auctionDate="property.auctionDate"
        />
      </div>
      <div v-else>
        <div class="p-2 border-bottom border-1 border-dark border-opacity-25">
          <div class="row">
            <div class="col-12 d-flex justify-content-center align-self-center">
              <p class="fs-4">No listings found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyListing from "./PropertyListing.vue";
import { useRouter } from "vue-router";
import { usePropertyStore } from "../../stores/properties";

export default {
  name: "MyListings",
  components: {
    PropertyListing,
  },
  setup() {
    const router = useRouter();
    const propertyStore = usePropertyStore();
    return { router, propertyStore };
  },
  data() {
    return {
      properties: [],
      states: [],
    };
  },
  watch: {
    properties: {
      handler: async function (newProperties) {
        if (newProperties.length > 0) {
          for (let property of newProperties) {
            property.fullAddress = await this.getAddress(property);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getAddress(property) {
      if (!property) {
        console.error("Property is undefined or null");
        return "";
      }

      let addressLine2 = "";
      if (property.address2 != null) {
        addressLine2 = property.address2 + ", ";
      }

      // Await the result from the async getStateById function
      const state = await this.getStateById(property.stateId);

      const address =
        property.address1 +
        ", " +
        addressLine2 +
        property.city +
        ", " +
        state +
        ", " +
        property.zipCode;

      return address;
    },
    getStateById(stateId) {
      const state = this.states.find((state) => state.id === stateId);
      return state ? state.name : "";
    },
  },
  async beforeMount() {
    this.propertyStore.getAllStates().then((res) => {
      this.states = res.data;
    });

    const propertyResponse = await this.propertyStore.getAllProperties();

    if (propertyResponse.status == 200) {
      if (propertyResponse.data) {
        this.properties = propertyResponse.data;
      }
    }
  },
};
</script>

<style scoped>
.propertyListings {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}
</style>

<template>
  <div class="container">
    <div class="row">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="['step-item col ', index + 1 <= currentStep ? 'active' : '']"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: Number,
    steps: Object,
  },
  computed: {
    progress() {
      return (this.currentStep / (this.steps.length - 1)) * 100;
    },
  },
};
</script>

<style scoped>
.step-item {
  z-index: 10;
  text-align: center;
  background-color: #e4e4e4;
  width: auto;
  height: 10px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.active {
  background-color: #004bbb;
}
</style>

<template>
    <div>
        <NavbarStyleFive />
        <PageTitle subTitle="Our Services" heding="Our work is delivered by the best team in the world" />
        <WhyChooseUs />
        <WhatWeOffer />
        <Testimonials />
        <Partner class="ptb-100 bg-f9f9f9 br-bottom-100" />
        <FooterStyleFive />
    </div>
</template>

<script>
import NavbarStyleFive from '../Layouts/NavbarStyleFive'
import PageTitle from '../Common/PageTitle'
import WhyChooseUs from '../ServicesThree/WhyChooseUs'
import WhatWeOffer from '../ServicesThree/WhatWeOffer'
import Testimonials from '../ServicesThree/Testimonials'
import Partner from '../Common/Partner'
import FooterStyleFive from '../Layouts/FooterStyleFive'

export default {
    name: 'ServicesThreePage',
    components: {
        NavbarStyleFive,
        PageTitle,
        WhyChooseUs,
        WhatWeOffer,
        Testimonials,
        Partner,
        FooterStyleFive,
    }
}
</script>
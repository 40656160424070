<template>
      <div class="services-area with-top-border pt-75 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title purple-color">Welcome to  Agent Arena for Home Owners</span>
                <h2 class="nunito-font">Dashboard</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon13.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Your Properties (0)</router-link></h3>
                        <p>Manage and showcase your listings effortlessly. Keep track of all your properties and highlight key details to attract potential buyers.</p>
                        <router-link to="/AddPropertyListing" class="link-btn">List your first property <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon14.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Auctions (0)</router-link></h3>
                        <p>Participate in competitive auctions where top agents bid for the privilege to list and sell properties. Experience a transparent and dynamic bidding process..</p>
                        <router-link to="/services-details" class="link-btn">List your first property <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon15.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Current Bids (0)</router-link></h3>
                        <p>Stay updated with the latest bids on your property. Track the progress of each auction and see the competitive offers coming in.</p>
                        <router-link to="/AddPropertyListing" class="link-btn">List your first property <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon13.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Top Agents</router-link></h3>
                        <p>Discover and connect with top-performing agents in your area. Browse profiles, read reviews, and choose the best fit for selling your property.</p>
                        <router-link to="/AddPropertyListing" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon14.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Recent Market Trends</router-link></h3>
                        <p>Stay informed with the latest trends in the real estate market. Get insights and data to make informed decisions about buying or selling properties.</p>
                        <router-link to="/services-details" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-item style-two">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon15.png" alt="icon">
                        </div>
                        <h3 class="nunito-font"><router-link to="/services-details">Real Estate Stats</router-link></h3>
                        <p>Access comprehensive statistics on property values, market trends, and more. Use this data to make strategic decisions in the real estate market.</p>
                        <router-link to="/services-details" class="link-btn">View More <i class="ph-caret-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

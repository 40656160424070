<template>
    <div 
        class="distance-learning-banner-area movearea"
        @mousemove="onMousemove"
        :style="{ backgroundColor: `hsl(${x}, 50%, 95%)` }"
    >
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="distance-learning-banner-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">Online Education</span>
                        <h1 class="nunito-font" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">World’s largest distance learning provider</h1>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">Velit hendrerit sit auctor tempor sem. Congue mi tempor condimentum felis arcu, non cursus. Nulla pharetra porttitor sed platea arcu et leo odio.</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one yellow-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                            <router-link to="/about-us" class="btn-style-one white-color">
                                About Us 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="distance-learning-banner-image">
                        <img src="../../assets/images/banner/banner7.png" alt="banner-image">
                    </div>
                </div>
            </div>
            <div class="single-courses-box">
                <router-link to="/courses-details" class="image d-block">
                    <img src="../../assets/images/courses/courses4.jpg" alt="courses">
                </router-link>
                <div class="content">
                    <div class="price">
                        $45
                    </div>
                    <h3 class="nunito-font">
                        <router-link to="/courses-details">Node.js for Beginners: Go From Zero to Hero with Node.js</router-link>
                    </h3>
                    <ul class="info d-flex align-items-center justify-content-between">
                        <li>
                            <div class="author d-flex align-items-center">
                                <img src="../../assets/images/user/user1.jpg" alt="author">
                                <span>David Allen</span>
                            </div>
                        </li>
                        <li><span class="status">Popular</span></li>
                    </ul>
                </div>
                <ul class="box-footer d-flex justify-content-between align-items-center">
                    <li><i class="flaticon-video-player"></i> 14 Lessons</li>
                    <li><i class="flaticon-team"></i> 125 Students</li>
                </ul>
            </div>
        </div>
        <div class="shape15"><img src="../../assets/images/shape/shape15.png" alt="shape"></div>
        <div class="shape16"><img src="../../assets/images/shape/shape16.png" alt="shape"></div>
        <div class="shape17"><img src="../../assets/images/shape/shape17.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data() {
        return {
            x: 0
        }
    },
    methods: {
        onMousemove(e) {
            this.x = e.clientX
        }
    }
}
</script>
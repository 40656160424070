<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title yellow-color">Education for everyone</span>
                <h2 class="nunito-font">Affordable online courses and learning opportunities</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="features-box style-two">
                        <div class="icon">
                            <i class="flaticon-growth"></i>
                        </div>
                        <h3 class="nunito-font">Learn the Latest Top Skills</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="features-box style-two">
                        <div class="icon">
                            <i class="flaticon-fast-time"></i>
                        </div>
                        <h3 class="nunito-font">Learn in Your Own Pace</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="features-box style-two">
                        <div class="icon">
                            <i class="flaticon-connection"></i>
                        </div>
                        <h3 class="nunito-font">Learn From Industry Experts</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>
<template>
    <div class="cart-area ptb-100">
        <div class="container">
            <form @submit.prevent>
                <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">PRODUCT DETAILS</th>
                                <th scope="col">UNIT PRICE</th>
                                <th scope="col">QUANTITY</th>
                                <th scope="col">AMOUNT</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="product-thumbnail">
                                    <router-link to="/products-details">
                                        <img src="../../assets/images/products/products1.jpg" alt="item">
                                        <h3>Black Men's T-Shirt</h3>
                                    </router-link>
                                </td>
                                <td>$199.00</td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="ph-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="ph-plus"></i></span>
                                    </div>
                                </td>
                                <td>$199.00</td>
                                <td><router-link to="/cart" class="remove"><i class="ph-trash-simple"></i></router-link></td>
                            </tr>
                            <tr>
                                <td class="product-thumbnail">
                                    <router-link to="/products-details">
                                        <img src="../../assets/images/products/products2.jpg" alt="item">
                                        <h3>Floating Paper Bag</h3>
                                    </router-link>
                                </td>
                                <td>$150.00</td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="ph-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="ph-plus"></i></span>
                                    </div>
                                </td>
                                <td>$150.00</td>
                                <td><router-link to="/cart" class="remove"><i class="ph-trash-simple"></i></router-link></td>
                            </tr>
                            <tr>
                                <td class="product-thumbnail">
                                    <router-link to="/products-details">
                                        <img src="../../assets/images/products/products3.jpg" alt="item">
                                        <h3>Sports Cap Black</h3>
                                    </router-link>
                                </td>
                                <td>$200.00</td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="ph-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="ph-plus"></i></span>
                                    </div>
                                </td>
                                <td>$200.00</td>
                                <td><router-link to="/cart" class="remove"><i class="ph-trash-simple"></i></router-link></td>
                            </tr>
                            <tr>
                                <td class="product-thumbnail">
                                    <router-link to="/products-details">
                                        <img src="../../assets/images/products/products4.jpg" alt="item">
                                        <h3>Covid Smart Mask</h3>
                                    </router-link>
                                </td>
                                <td>$289.00</td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="ph-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="ph-plus"></i></span>
                                    </div>
                                </td>
                                <td>$289.00</td>
                                <td><router-link to="/cart" class="remove"><i class="ph-trash-simple"></i></router-link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cart-buttons">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-sm-12 col-md-7">
                            <div class="shopping-coupon-code">
                                <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                                <button type="submit">Apply Coupon</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cart-totals">
                    <ul>
                        <li>Subtotal <span>$800.00</span></li>
                        <li>Shipping <span>$30.00</span></li>
                        <li>Total <span>$830.00</span></li>
                    </ul>
                    <router-link to="/checkout" class="btn-style-one red-light-color">Proceed to Checkout <i class="ph-caret-right"></i></router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Cart'
}
</script>
<template>
  <div class="d-flex justify-content-center mt-3">
    <div class="accordion accordion-flush w-75" id="accordionFlushHomeowner">
      <div
        class="accordion-item"
        v-for="Faq in HomeownerFaqData"
        :key="Faq.number"
      >
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#flush-collapse-' + Faq.number"
            aria-expanded="false"
            :aria-controls="'flush-collapseOne' + Faq.number"
          >
            {{ Faq.question }}
          </button>
        </h2>
        <div
          :id="'flush-collapse-' + Faq.number"
          class="accordion-collapse collapse border border-1 border-dark border-opacity-10 border-bottom-0"
          :aria-labelledby="'flush-heading' + Faq.number"
          data-bs-parent="#accordionFlushHomeowner"
        >
          <div class="accordion-body">
            {{ Faq.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeownerFaq from "./HomeownerFaq.json";
export default {
  name: "HomeownerFaq",
  data() {
    return {
      HomeownerFaqData: HomeownerFaq,
    };
  },
};
</script>

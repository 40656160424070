export const DateFromUTC = (date) => {
  const utcDate = new Date(date);
  const offset = utcDate.getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - offset * 60 * 1000);
  return localDate;
};

export const DateToUTC = (states, stateId, date) => {
  const state = states.find((state) => state.id === stateId);
  if (!state) {
    return date; // If the state is not found, return the original date
  }

  // Assuming state.TimeZone is the offset from UTC (e.g., -5 for EST)
  const timeZoneOffset = state.timeZone;

  // Convert the provided date to UTC
  // Get the local time in milliseconds and then subtract the timezone offset in minutes
  const utcDate = new Date(date.getTime() - timeZoneOffset * 60 * 60 * 1000);

  return utcDate;
};

<template>
  <div>
    <NavbarStyleNine />
    <!-- <PageTitle subTitle="Authentication" heding="Howdy! Welcome Back!" /> -->
    <Login />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
// import PageTitle from '../Common/PageTitle'
import Login from "../Authentication/Login";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "AuthenticationPage",
  components: {
    NavbarStyleNine,
    // PageTitle,
    Login,
    FooterStyleFive,
  },
};
</script>

<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center pageLoaderContainer"
      v-if="loader"
    >
      <div class="spinner-border text-primary pageLoader" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else-if="properties && properties.length > 1">
      <!-- Display list of properties -->
      <PropertyList :properties="properties"></PropertyList>
    </div>
    <div v-else-if="properties && properties.length == 1">
      <!-- Display property Details -->
      <PropertyDetails
        :propertyId="propertyId"
        :showRegisterButton="true"
      ></PropertyDetails>
    </div>
    <div v-else>
      <!-- Display no Properties Found ask them to register one -->
    </div>
  </div>
</template>

<script>
import { usePropertyStore } from "../../stores/properties";
import PropertyList from "../Property/PropertyList.vue";
import PropertyDetails from "../Property/PropertyDetails.vue";

export default {
  components: {
    PropertyList,
    PropertyDetails,
  },
  setup() {
    const propertyStore = usePropertyStore();
    return { propertyStore };
  },
  data() {
    return {
      loader: true,
      listings: false,
      properties: [],
      propertyId: null,
    };
  },
  methods: {},
  async beforeMount() {
    const propertyResponse = await this.propertyStore.getAllUserProperties();
    if (propertyResponse.status == 200) {
      if (propertyResponse.data) {
        this.properties = propertyResponse.data;
        if (this.properties && this.properties.length == 1) {
          this.propertyId = this.properties[0]["id"];
        }
        this.loader = false;
      }
    }
  },
};
</script>

<template>
    <div class="goal-area with-top-border ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="goal-image">
                        <img src="../../assets/images/goal.png" alt="goal-image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="goal-content">
                        <span class="sub-title">Our Goal</span>
                        <h2 class="nunito-font">Protecting families and their futures</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                We invest for long-term results
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                We manage risk in-house, in real time
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                We maintain consistently high ratings
                            </li>
                        </ul>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one dark-green-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurGoal'
}
</script>
import { defineStore } from "pinia";
import axios from "axios";

// const baseUrl = "https://localhost:7139/api";
const baseUrl = process.env.VUE_APP_API_URL;

export const useAuctionStore = defineStore("auctions", {
  state: () => ({
    auction: null,
    auctions: [],
    auctionStatus: [
      "Upcoming",
      "Ongoing",
      "Closed",
      "Canceled"
    ],
  }),
  actions: {
    async getAuctionById(id) {
      try {
        const response = await axios.get(
          `${baseUrl}/auction/` + id,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching auction:", error);
        return error;
      }
    },
    async getAuctionByProperty(id) {
      try {
        const response = await axios.get(
          `${baseUrl}/auction/for-property/` + id,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching auction:", error);
        return error;
      }
    },
    async getWinningAuction(auctionId) {
      try {
        const response = await axios.get(
          `${baseUrl}/auction/winner/${auctionId}`,
          {
            withCredentials: true,
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching auction:", error);
        return error;
      }
    },
  },
});

<template>
  <div>
    <div class="contact-area ptb-100">
      <div class="container">
        <div class="section-title style-two">
          <span class="sub-title">{{ contactData.contactLabel }}</span>
          <h2 v-html="contactData.contactHeader"></h2>
        </div>
        <div class="row d-flex justify-content-center align-content-center">
          <div class="col-lg-6 col-md-12">
            <div class="contact-form">
              <form @submit.prevent>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>{{ contactData.formLabel1 }}</label>
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>{{ contactData.formLabel2 }}</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        id="email"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>{{ contactData.formLabel3 }}</label>
                      <input
                        type="text"
                        name="msg_subject"
                        class="form-control"
                        id="msg_subject"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>{{ contactData.formLabel4 }}</label>
                      <input
                        type="text"
                        name="phone_number"
                        class="form-control"
                        id="phone_number"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label>{{ contactData.formLabel5 }}</label>
                      <textarea
                        name="message"
                        id="message"
                        class="form-control"
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <button
                      type="submit"
                      class="btn btn-style-two fs-5"
                      :disabled="loader"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        Submit
                        <i class="ph-caret-right"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      contactData: {},
    };
  },
  async mounted() {
    const entryId = "4Vp3RH5LoO6SCRly5byI2L";
    await this.fetchContactContent(entryId);
  },
  methods: {
    async fetchContactContent(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        console.log("Contact Page Data:", response);

        if (
          response.fields.locationLink.content[0] &&
          response.fields.locationLink.content[0].content[0]
        ) {
          this.locationLink =
            response.fields.locationLink.content[0].content[0].value;
        }

        this.contactData = response.fields;
      } catch (error) {
        console.error("Error fetching contact data:", error);
      }
    },
  },
};
</script>

<template>
  <div class="pricing-area bg-f1f5fd pb-75">
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-for="(pricing, index) in pricingData"
          :key="index"
          class="col-lg-4 col-md-6"
          data-aos="fade-up"
          :data-aos-duration="'1200'"
          :data-aos-delay="index * 100"
        >
          <div class="single-pricing-box">
            <h3>{{ pricing.fields.tier }}</h3>
            <p>{{ pricing.fields.label }}</p>
            <div class="price">{{ pricing.fields.title }}</div>
            <router-link to="/Register" class="btn-style-one light-green-color">
              {{ pricing.fields.buttonText }} <i class="ph-caret-right"></i>
            </router-link>
            <ul class="features-list">
              <li>
                <i :class="pricing.fields.icon1"></i>
                <div v-html="formatRichText(pricing.fields.description1)" ></div>
              </li>
              <li>
                <i :class="pricing.fields.icon2"></i>
                <div v-html="formatRichText(pricing.fields.description2)" ></div>
              </li>
              <li>
                <i :class="pricing.fields.icon3"></i>
                <div v-html="formatRichText(pricing.fields.description3)" ></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data() {
    return {
      pricingData: [], 
    };
  },
  async mounted() {
    await this.fetchPricingData();
  },
  methods: {
    async fetchPricingData() {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: "pricing", 
        });
        console.log("Pricing Data Response:", response);
        this.pricingData = response.items;
      } catch (error) {
        console.error("Error fetching pricing data:", error);
      }
    },
    formatRichText(content) {
      return this.$documentToHtmlString(content);
    }
  }
};
</script>


<template>
  <div>
    <NavbarStyleNine />
    <InviteExternal />
    <InviteTop20 />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import InviteExternal from "../Invites/InviteExternal.vue";
import InviteTop20 from "../Invites/InviteTop20.vue";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "InviteAgentsPage",
  components: {
    NavbarStyleNine,
    InviteExternal,
    InviteTop20,
    FooterStyleFive,
  },
};
</script>

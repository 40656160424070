<template>
  <div>
    <NavbarStyleNine />
    <PageTitle subTitle="Edit your profile" heding="Howdy! Welcome Back!" />
    <Profile />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import PageTitle from "../Common/PageTitle";
import Profile from "../Profile/Profile";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "AuthenticationPage",
  components: {
    NavbarStyleNine,
    PageTitle,
    Profile,
    FooterStyleFive,
  },
};
</script>

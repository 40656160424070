import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import AOS from "aos";
import BootstrapVue3 from "bootstrap-vue-3";
import Toaster from "@meforma/vue-toaster";
import ContentfulPlugin from './Plugins/contentfulClient'; 

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/custom.scss";


const app = createApp(App);

const pinia = createPinia();


app.use(ContentfulPlugin);

app.use(pinia);
app.use(router);
app.use(Toaster);
app.use(BootstrapVue3);
app.use(AOS.init());

app.mount("#app");

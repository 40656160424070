<template>
    <div>
        <NavbarStyleEight />
        <MainBanner />
        <Features />
        <OurGoal />
        <WeProtect />
        <WhatWeDo />
        <WhatWeOffer />
        <Testimonials />
        <Faq />
        <AppDownload />
        <FooterStyleEight />
    </div>
</template>

<script>
import NavbarStyleEight from '../Layouts/NavbarStyleEight'
import MainBanner from '../ChatBot/MainBanner'
import Features from '../ChatBot/Features'
import OurGoal from '../ChatBot/OurGoal'
import WeProtect from '../ChatBot/WeProtect'
import WhatWeDo from '../ChatBot/WhatWeDo'
import WhatWeOffer from '../ChatBot/WhatWeOffer'
import Testimonials from '../ChatBot/Testimonials'
import Faq from '../Common/Faq'
import AppDownload from '../ChatBot/AppDownload'
import FooterStyleEight from '../Layouts/FooterStyleEight'

export default {
    name: 'ChatBotPage',
    components: {
        NavbarStyleEight,
        MainBanner,
        Features,
        OurGoal,
        WeProtect,
        WhatWeDo,
        WhatWeOffer,
        Testimonials,
        Faq,
        AppDownload,
        FooterStyleEight,
    }
}
</script>
import { createClient } from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  install(app) {
    const client = createClient({
      space: process.env.VUE_APP_CTF_SPACE_ID,
      accessToken: process.env.VUE_APP_CTF_CDA_ACCESS_TOKEN,
    });

    app.config.globalProperties.$contentfulClient = client;
    app.config.globalProperties.$documentToHtmlString = documentToHtmlString;
  },
};
<template>
  <div>
    <NavbarStyleNine />
    <Dahboard />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import Dahboard from "../HomeOwners/DashboardTwo";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "ServicesFourPages",
  components: {
    NavbarStyleNine,
    Dahboard,
    FooterStyleFive,
  },
};
</script>

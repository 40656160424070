import { defineStore } from "pinia";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_URL}/payment`;

export const usePaymentStore = defineStore("Payment", {
  state: () => ({
  }),
  getters: {
  },
  actions: {
    async payInvite(payment) {
      try {
        const response = await axios.post(`${baseUrl}/payInvite`, payment, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error("Error payment:", error);
        return error;
      }
    },
    async payMembership(payment) {
      try {
        const response = await axios.post(`${baseUrl}/payMembership`, payment, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error("Error payment:", error);
        return error;
      }
    },
    async payPropertyListing(payment) {
      try {
        const response = await axios.post(`${baseUrl}/payPropertyListing`, payment, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error("Error payment:", error);
        return error;
      }
    },
    async payWinningBid(payment) {
      try {
        const response = await axios.post(`${baseUrl}/payWinningBid`, payment, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error("Error payment:", error);
        return error;
      }
    },
    async getPropertyListingAmount() {
      try {
        const response = await axios.get(`${baseUrl}/getPropertyListingAmount`, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error("Error getPropertyListingAmount:", error);
        return error;
      }
    },
    
  },
});

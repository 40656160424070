<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog1.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Technology</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    25 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How is technology working with new things?</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog2.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Design</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    24 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Top 10 important tips on IT services & design</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog3.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Startup</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    23 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How our company works in different ways</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog7.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Technology</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    22 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Giving kids and teens a safer experience online</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog8.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Design</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    21 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">9 apps to help people sharpen their coding skills</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog9.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Startup</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    20 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">A new model for inclusive computer science</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Technology</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    19 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">How sellers win when housing inventory is low</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog13.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Design</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    18 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Branding involves developing strategy to create point</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog14.jpg" alt="blog-image">
                            </router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li>
                                    <i class="ph-tag"></i>
                                    <a href="blog-tag.html">Startup</a>
                                </li>
                                <li>
                                    <i class="ph-calendar-check"></i>
                                    17 Nov, 2021
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details">Bootstrap 5 is open source software you can use</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <a href="/blog-grid" class="prev page-numbers">prev</a>
                            <span class="page-numbers current">1</span>
                            <a href="/blog-grid" class="page-numbers">2</a>
                            <a href="/blog-grid" class="page-numbers">3</a>
                            <a href="/blog-grid" class="next page-numbers">next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogGrid'
}
</script>
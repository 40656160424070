import { defineStore } from "pinia";
import axios from "axios";

// const baseUrl = "https://localhost:7139/api";
const baseUrl = process.env.VUE_APP_API_URL;

export const useBidStore = defineStore("bids", {
  state: () => ({
    bid: null,
    bids: [],
  }),
  actions: {
    async addBid(data) {
      try {
        const response = await axios.post(`${baseUrl}/bid`, data, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        console.error("Error adding bid:", error);
        return error;
      }
    },
    async payBid(bidId) {
      try {
        const response = await axios.post(`${baseUrl}/bid/pay/${bidId}`, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        console.error("Error paying bid:", error);
        return error;
      }
    },
    async getAllAuctionBids(auctionId) {
      try {
        const response = await axios.get(
          `${baseUrl}/bid/for-auction/` + auctionId,
          {
            withCredentials: true,
          }
        );
        return response;
      } catch (error) {
        console.error("Error fetching states:", error);
        return error;
      }
    },
  },
});

<template>
  <div>
    <div class="pricing-area bg-f1f5fd pb-75">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-lg-4" v-for="membership in memberships" :key="membership.id">
            <MembershipTile :membership="membership" :buttonDisabled="getMembershipButtonDisabled(membership)"
              :buttonText="getMembershipButtonText(membership)" @clicked="onMembershipClicked" />
          </div>
        </div>
      </div>
    </div>
    <button id="confirmationModalBtn" type="button" class="btn btn-primary d-none" data-bs-toggle="modal"
      data-bs-target="#confirmationModal"></button>
    <button id="confirmationModalCloseBtn" type="button" class="btn btn-primary d-none" data-bs-dismiss="modal"
      data-bs-target="#confirmationModal"></button>
    <div class="modal fade" id="confirmationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="confirmationModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="confirmationModalLabel">
              Membership Confirmation
            </h4>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="modal-body fs-5">
              <div>
                <div ref="cardElementContainer" class="card-element mt-4 mb-4"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary fs-5" data-bs-dismiss="modal" :disabled="loader">
                Cancel
              </button>
              <button type="button" class="btn btn-primary fs-5" :disabled="loader" @click="payMembership">
                {{ getPayButtonText() }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMembershipStore } from "../../stores/memberships";
import { useAgentStore } from "../../stores/agent";
import { usePaymentStore } from "../../stores/payments";
import { useRouter } from "vue-router";
import MembershipTile from "./MembershipTile.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "Pricing",
  components: {
    MembershipTile,
  },
  setup() {
    const membershipStore = useMembershipStore();
    const agentStore = useAgentStore();
    const router = useRouter();
    const paymentStore = usePaymentStore();

    return {
      membershipStore,
      agentStore,
      router,
      paymentStore,
    };
  },
  data() {
    return {
      selectedMembershipTier: null,
      memberships: null,
      agent: null,
      loader: false,
      stripe: null,
      card: null,
    };
  },
  methods: {
    async fetchAgent() {
      const agent = await this.agentStore.fetchAgent();
      if (agent) {
        this.agent = agent;
      }
    },
    async payMembership() {
      // this.loader = true;

      if (this.selectedMembershipTier != null && this.selectedMembershipTier <= 0) {
        // This is bronze, thus just update the tier and proceed
        await this.agentStore
          .updateMembership(this.selectedMembershipTier)
          .then((res) => {
            if (res.status == 200) {
              if (res.data > 0) {
                this.router.push("/AgentDashboard");
              } else {
                this.loader = false;
              }
            }
          });
      }

      // Get the token or payment method from Stripe
      const { error, paymentMethod } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card,
      });


      if (error) {
        console.error(error);
        this.loader = false;
        return;
      }

      console.log("================================================");
      console.log(paymentMethod);

      const data = {
        paymentMethodId: paymentMethod.id,
        membershipTier: this.selectedMembershipTier,
      };
      this.paymentStore.payMembership(data)
        .then(paymentConfirmation => {
          if (paymentConfirmation.requiresAction) {
            // Handle 3D Secure or authentication
            this.stripe.confirmCardPayment(paymentConfirmation.clientSecret).then(result => {
              if (result.error) {
                console.error(result.error.message);
                this.loader = false;
              } else {
                this.agentStore
                  .updateMembership(this.selectedMembershipTier)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data > 0) {
                        this.router.push("/AgentDashboard");
                        document.getElementById("confirmationModalCloseBtn").click();
                        this.loader = false;
                      } else {
                        this.loader = false;
                      }
                    }
                  });
              }
            });
          } else {
            this.agentStore
              .updateMembership(this.selectedMembershipTier)
              .then((res) => {
                if (res.status == 200) {
                  if (res.data > 0) {
                    this.router.push("/AgentDashboard");
                    document.getElementById("confirmationModalCloseBtn").click();
                    this.loader = false;
                  } else {
                    this.loader = false;
                  }
                }
              });
          }
        })
        .catch(error => {
          console.error('Payment failed', error);
          this.loader = false;
        });
    },
    async setupStripe() {
      await this.$nextTick();

      // Initialize Stripe
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount(this.$refs.cardElementContainer);
    },
    async onMembershipClicked(selectedMembership) {
      // Bronze skips payment
      if (selectedMembership.tier <= 0) {
        await this.agentStore
          .updateMembership(selectedMembership.tier)
          .then((res) => {
            if (res.status == 200) {
              if (res.data > 0) {
                //update agent
                // this.fetchAgent();
                this.router.push("/AgentDashboard");
              } else {
                this.loader = false;
              }
            }
          });
        return;
      }

      // Silver and above have to pay
      this.selectedMembershipTier = selectedMembership.tier;
      document.getElementById("confirmationModalBtn").click();
    },
    getMembershipButtonDisabled(membership) {
      if (this.agent == null) return false;
      if (this.agent.membershipId == null) return false;
      if (this.agent.membershipId == membership.membershipId) return true;
      if (this.loader) return true;

      return false;
    },
    getMembershipButtonText(membership) {
      if (this.agent == null) return "Select";
      if (this.agent.membershipId == null) return "Select";
      if (this.agent.membershipId == membership.membershipId)
        return "Current Membership";
      if (this.agent.membership == null) return "Select";

      return this.agent.membership.tier > membership.tier
        ? "Downgrade"
        : "Upgrade";
    },
    getPayButtonText() {
      if (this.loader) return "Processing...";
      if (this.agent == null) return "Pay";
      if (this.agent.membershipId == null) return "Upgrade";

      return this.agent.membership.tier > this.selectedMembershipTier
        ? "Downgrade"
        : "Upgrade";
    },
  },
  async mounted() {
    this.setupStripe();
    const memberships = await this.membershipStore.getAllMemberships();
    if (memberships) {
      this.memberships = memberships;
    }
  },
  async beforeMount() {
    await this.fetchAgent();
  },
};
</script>

<style scoped>
.single-pricing-box {
  border: 1px solid #0d6efd;
}

.features-list li:hover i {
  background-color: black !important;
}
</style>

<template>
  <div class="d-flex w-100 row m-0 justify-content-center mt-5 py-5">
    <div class="w-1200px text-center">
      <h2 class="howItWorksHeading mb-5">Home Seller Benefits?</h2>
      <div class="howto-steps howTo-text">
        <!-- Step 1 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="001"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="
                require('@/assets/agent/images/Transparent-Listing-Agreement-icon.png')
              "
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >1</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">
              Crystal Clear and Transparent Task
            </h3>
            <p class="howTo-text">
              Your agent's task is crystal clear and transparent. They
              understand your desired price and are committed to making it
              happen. Nothing is more frustrating than an agent who promises a
              certain price for your home, only to start pressuring you to lower
              it a few weeks into the job.
            </p>
          </div>
        </div>

        <!-- Step 2 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="002"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="
                require('@/assets/agent/images/Negotiation-Control-icon.png')
              "
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >2</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">No More Awkward Negotiations!</h3>
            <p class="howTo-text">
              At AgentArena.com, you have full control over the terms and
              commissions. Everything is decided by you.
            </p>
          </div>
        </div>

        <!-- Step 3 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="003"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="
                require('@/assets/agent/images/Transparent-Agent-Goals-icon.png')
              "
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >3</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Agents Have Skin in the Game</h3>
            <p class="howTo-text">
              Agents have skin in the game! When they pay you for your listing,
              their motivation to sell your home is unmatched.
            </p>
          </div>
        </div>

        <!-- Step 4 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="004"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="
                require('@/assets/agent/images/Motivated-Home-Sales-icon.png')
              "
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >4</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Access to Top Talent</h3>
            <p class="howTo-text">
              By leveraging local agent rankings, you gain access to
              top-performing agents in your area who have a proven track record
              of success.
            </p>
          </div>
        </div>

        <!-- Step 5 -->
        <div
          class="d-flex align-items-start justify-content-start gap-5 mb-5 flex-lg-row flex-column"
          id="005"
        >
          <div
            class="d-flex align-items-center gap-3 flex-lg-row flex-column w-md-100"
            style="flex-shrink: 0"
          >
            <img
              :src="
                require('@/assets/agent/images/Inclusive-Home-Sales-icon.png')
              "
              alt="Unlock Local Agent Rankings"
              class="img-fluid"
              style="width: 250px"
            />
            <span
              class="step-circle fw-bold d-flex justify-content-center align-items-center rounded-circle text-center border border-4"
              style="width: 100px; height: 100px; border-color: #e4e4e4"
              >5</span
            >
          </div>
          <div class="text-start flex-grow-1">
            <h3 class="fs-4 fw-bold mb-3">Opportunity for All Agents</h3>
            <p class="howTo-text">
              Many homeowners find it challenging to choose just one agent to
              sell their home, especially if they have multiple friends who are
              agents. With Agent Arena, you can provide an opportunity to all
              your friends by inviting them into the arena. Then, let the person
              who is most eager take on your listing! This process ensures total
              transparency and eliminates any potential for hurt feelings.
            </p>
          </div>
        </div>

        <!-- Centered Button -->
        <div class="text-center mt-4 w-100 d-flex justify-content-center">
          <router-link
            to="/register"
            class="btn-style-two fw-bold mt-2 d-flex justify-content-center align-items-center px-5 py-3 text-uppercase"
          >
            Get Started
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeOwnerBenefits",
  components: {},
};
</script>

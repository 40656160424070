<template>
    <div class="request-a-visit-area bg2 ptb-100">
      <div class="container">
        <div class="request-a-visit-form" data-aos="fade-up" data-aos-duration="1200">
          <span class="sub-title">{{ label }}</span>
          <h2>{{ title }}</h2>
          <form @submit.prevent>
            <div class="form-group">
              <input type="text" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <input type="email" placeholder="Enter your email address" />
            </div>
            <div class="form-group">
              <select class="form-select">
                <option selected>Which describes you best?</option>
                <option value="1">Real Estate Agent</option>
                <option value="2">Homeowner</option>
              </select>
            </div>
            <button type="submit" class="btn-style-one red-light-color">
              {{ buttonText }} <i class="ph-caret-right"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        label: '',       
        title: '',        
        buttonText: ''   
      };
    },
    async mounted() {
      await this.fetchRequestVisitContent('3bXTh2J1AwKUYDkcWRRIui');
    },
    methods: {
      async fetchRequestVisitContent(entryId) {
        try {
          const response = await this.$contentfulClient.getEntry(entryId);
          this.label = response.fields.label;
          this.title = response.fields.title;
          this.buttonText = response.fields.buttonText;
        } catch (error) {
          console.error("Error fetching request a visit content:", error);
        }
      }
    }
  };
  </script>
  
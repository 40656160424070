<template>
    <div>
        <NavbarStyleTwo />
        <MainBanner />
        <HelpDesk />
        <About />
        <Services />
        <Faq />
        <Testimonials />
        <Team />
        <CaseStudies />
        <Funfacts />
        <WorkingProcess />
        <GetStarted />
        <Blog />
        <Partner class="ptb-100 bg-fff4f8" />
        <FooterStyleTwo />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
import MainBanner from '../Business/MainBanner'
import HelpDesk from '../Business/HelpDesk'
import About from '../Business/About'
import Services from '../Business/Services'
import Faq from '../Business/Faq'
import Testimonials from '../Common/Testimonials'
import Team from '../Business/Team'
import CaseStudies from '../Business/CaseStudies'
import Funfacts from '../Business/Funfacts'
import WorkingProcess from '../Business/WorkingProcess'
import GetStarted from '../Business/GetStarted'
import Blog from '../Common/Blog'
import Partner from '../Common/Partner'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'BusinessPage',
    components: {
        NavbarStyleTwo,
        MainBanner,
        HelpDesk,
        About,
        Services,
        Faq,
        Testimonials,
        Team,
        CaseStudies,
        Funfacts,
        WorkingProcess,
        GetStarted,
        Blog,
        Partner,
        FooterStyleTwo,
    }
}
</script>
<template>
  <div
    class="p-2 ms-lg-3 me-lg-3 border-bottom border-1 border-dark border-opacity-25"
  >
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2">
        <img
          :src="propertyImageUrl"
          alt="Listing Image"
          class="img-fluid rounded w-100 h-100"
          style="max-width: 150px; max-height: 150px"
        />
      </div>

      <div class="col-12 col-md-7 col-lg-8">
        <p class="mb-0">{{ propertyAddress }}</p>
        <p class="mb-0">
          Bottom Line Price:
          <strong>{{ formatCurrency(bottomLinePrice) }}</strong>
        </p>
        <p class="mb-0 text-muted">
          Open House: {{ displayDate(openHouseDate) }}
        </p>
        <p class="mb-0 text-muted">
          Auction Date: {{ auctionDate ? displayDate(auctionDate) : "" }}
        </p>
      </div>
      <div
        class="col-12 col-md-3 col-lg-2 d-flex align-self-center justify-content-end"
      >
        <button class="btn btn-primary mb-2 w-100" @click="viewListing()">
          View Listing
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import FormatCurrency from "../../utils/Currency";
import { DateFromUTC, DateToUTC } from "../../utils/DateConverter";

export default {
  name: "PropertyListing",
  props: {
    propertyId: {
      type: Number,
      required: true,
    },
    propertyImageUrl: {
      type: String,
      required: true,
    },
    propertyAddress: {
      type: String,
      required: true,
    },
    bottomLinePrice: {
      type: String,
      required: true,
    },
    openHouseDate: {
      type: String,
      required: true,
    },
    auctionDate: {
      type: String,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  methods: {
    viewListing() {
      this.router.push({
        name: "homeListing",
        params: { id: this.propertyId },
      });
    },
    formatCurrency(value) {
      return FormatCurrency(value); // Use the imported utility directly
    },
    displayDate(date) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short",
      };
      
      // Use the imported DateFromUTC utility directly
      return DateFromUTC(date).toLocaleString("en-US", options);
    },
  },
};
</script>

<template>
  <div class="overview-area ptb-100 bg-f9f9f9">
    <div class="container">
      <div class="section-title">
        <span class="sub-title green-color">{{ sectionSubtitle }}</span>
        <h2>{{ sectionTitle }}</h2>
      </div>

      <div class="overview-item-style-two">
        <div class="row align-items-center">
          <div
            class="col-lg-6 col-md-12 overview-image"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <img
              :src="firstOverviewImage"
              alt="overview-image"
              style="max-height: 700px"
            />
          </div>
          <div class="col-lg-6 col-md-12 overview-content">
            <h2 class="nunito-font">{{ firstOverviewTitle }}</h2>
            <div v-html="firstOverviewDescription"></div>
            <ul class="overview-list">
              <li
                v-for="(item, index) in homeOwnerList"
                :key="index"
                :data-aos="'fade-up'"
                :data-aos-duration="'1200'"
                :data-aos-delay="index * 100"
              >
                <i class="flaticon-draw-check-mark"></i>
                <h3 class="nunito-font">{{ item.title }}</h3>
                <div v-html="item.description"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="overview-item-style-two">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12 overview-content">
            <h2 class="nunito-font">{{ benefitsTitle }}</h2>
            <div v-html="benefitsDescription"></div>
            <ul class="overview-list">
              <li
                v-for="(item, index) in benefitsList"
                :key="index"
                :data-aos="'fade-up'"
                :data-aos-duration="'1200'"
                :data-aos-delay="index * 100"
              >
                <i class="flaticon-draw-check-mark"></i>
                <h3 class="nunito-font">{{ item.title }}</h3>
                <div v-html="item.description"></div>
              </li>
            </ul>
          </div>
          <div
            class="col-lg-6 col-md-12 overview-image"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <img
              :src="benefitsImage"
              alt="overview-image"
              style="max-height: 700px"
            />
          </div>
        </div>
      </div>

      <div class="overview-item-style-two">
        <div class="row align-items-center">
          <div
            class="col-lg-6 col-md-12 overview-image"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <img
              :src="agentsImage"
              alt="overview-image"
              style="max-height: 700px"
            />
          </div>
          <div class="col-lg-6 col-md-12 overview-content">
            <h2 class="nunito-font">{{ agentsTitle }}</h2>
            <div v-html="agentsDescription"></div>
            <ul class="overview-list">
              <li
                v-for="(item, index) in agentsList"
                :key="index"
                :data-aos="'fade-up'"
                :data-aos-duration="'1200'"
                :data-aos-delay="index * 100"
              >
                <i class="flaticon-draw-check-mark"></i>
                <h3 class="nunito-font">{{ item.title }}</h3>
                <div v-html="item.description"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionTitle: "",
      sectionSubtitle: "",
      firstOverviewTitle: "",
      firstOverviewDescription: "",
      firstOverviewImage: "",
      homeOwnerList: [],
      benefitsTitle: "",
      benefitsDescription: "",
      benefitsImage: "",
      benefitsList: [],
      agentsTitle: "",
      agentsDescription: "",
      agentsImage: "",
      agentsList: [],
    };
  },
  async mounted() {
    await this.fetchSectionTitle("4C7ApWA2j9tiswSjU3PIqJ");
    await this.fetchOverviewImage("6MHK0PEZQ9ZwNQfIQNfWlz");
    await this.fetchHomeOwnerList("homeOwnerList");
    await this.fetchBenefitsOverview("5TUx1roBLNsV7gAAbY4D6p");
    await this.fetchBenefitsList("benefitsList");
    await this.fetchAgentsOverview("2YCixgbPFDkz2N9Lx20sed");
    await this.fetchAgentsList("agentsList");
  },
  methods: {
    async fetchSectionTitle(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log('Section Title Response:', response);
        this.sectionTitle = response.fields.title;
        this.sectionSubtitle = response.fields.label;
      } catch (error) {
        console.error("Error fetching section title:", error);
      }
    },
    async fetchOverviewImage(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log('First Overview Image Response:', response);
        this.firstOverviewTitle = response.fields.title;
        this.firstOverviewDescription = this.$documentToHtmlString(
          response.fields.description
        );
        this.firstOverviewImage = `https:${response.fields.image.fields.file.url}`;
      } catch (error) {
        console.error("Error fetching overview image:", error);
      }
    },
    async fetchHomeOwnerList(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log('Home Owner List Response:', response);
        this.homeOwnerList = response.items.map((item) => ({
          title: item.fields.title,
          description: this.$documentToHtmlString(item.fields.description),
        }));
      } catch (error) {
        console.error("Error fetching home owner list:", error);
      }
    },
    async fetchBenefitsOverview(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log('Benefits Overview Response:', response);
        this.benefitsTitle = response.fields.title;
        this.benefitsDescription = this.$documentToHtmlString(
          response.fields.description
        );
        this.benefitsImage = `https:${response.fields.image.fields.file.url}`;
      } catch (error) {
        console.error("Error fetching benefits overview:", error);
      }
    },
    async fetchBenefitsList(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log('Benefits List Response:', response);
        this.benefitsList = response.items.map((item) => ({
          title: item.fields.title,
          description: this.$documentToHtmlString(item.fields.description),
        }));
      } catch (error) {
        console.error("Error fetching benefits list:", error);
      }
    },
    async fetchAgentsOverview(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        // console.log('Agents Overview Response:', response);
        this.agentsTitle = response.fields.title;
        this.agentsDescription = this.$documentToHtmlString(
          response.fields.description
        );
        this.agentsImage = `https:${response.fields.image.fields.file.url}`;
      } catch (error) {
        console.error("Error fetching agents overview:", error);
      }
    },
    async fetchAgentsList(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log('Agents List Response:', response);
        this.agentsList = response.items.map((item) => ({
          title: item.fields.title,
          description: this.$documentToHtmlString(item.fields.description),
        }));
      } catch (error) {
        console.error("Error fetching agents list:", error);
      }
    },
  },
};
</script>

<template>
  <div>
    <NavbarStyleNine />
    <div class="d-flex flex-column justify-content-between vh-90">
      <RoleSelection></RoleSelection>
      <FooterStyleFive />
    </div>
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import RoleSelection from "../Roles/RoleSelection.vue";

export default {
  name: "RoleSelectionPage",
  components: {
    NavbarStyleNine,
    RoleSelection,
    FooterStyleFive,
  },
};
</script>

<style scoped>
.vh-90 {
  height: 90vh;
}
</style>

<template>
    <div class="single-pricing-box">
        <h3 :style="{ color: membership.hexColor }">{{ membership.displayName }}</h3>
        <p>{{ membership.description }}</p>
        <div class="price">{{ annualFee }}<span></span></div>
        <button 
            class="btn btn-primary p-3 d-flex align-items-center" 
            @click="handleClick" 
            :disabled="buttonDisabled">
            {{ buttonText }}
            <i class="ph-caret-right"></i>
        </button>
        <ul class="features-list">
            <li v-for="benefit in membership.benefits" :key="benefit.id">
                <i class="flaticon-draw-check-mark bg-primary"></i>
                {{ benefit.description }}
            </li>
        </ul>
    </div>
</template>

<script>
import FormatCurrency from "../../utils/Currency";

export default {
    name: "MembershipTile",
    props: ['membership', 'buttonDisabled', 'buttonText'],
    setup() {
        return {};
    },
    data() {
        return {
            annualFee: "",
        };
    },
    methods: {
        FormatCurrency,
        handleClick() {
            this.$emit('clicked', this.membership);
        },
    },
    mounted() {
        if (this.membership.annualFee == 0) {
            this.annualFee = "Free";
        } else {
            this.annualFee = FormatCurrency(this.membership.annualFee.toString());
        }
    },
    async beforeMount() {},
};
</script>

<style scoped>
.single-pricing-box {
    border: 1px solid #0d6efd;
    height: 100%;
}

.features-list li:hover i {
    background-color: black !important;
}
</style>

<template>
    <div class="services-area bg-fffbfb pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title dark-green-color">What We Offer</span>
                <h2 class="nunito-font">We’re helping teams do their best work</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-services-item">
                        <div class="icon color-ff004d">
                            <i class="flaticon-life"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Term life insurance</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            View More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-services-item">
                        <div class="icon color-d48000">
                            <i class="flaticon-umbrella"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Universal life insurance</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            View More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-services-item">
                        <div class="icon color-2e85a1">
                            <i class="flaticon-life-insurance"></i>
                        </div>
                        <h3 class="nunito-font">
                            <router-link to="/services-details">Guaranteed Issue Whole Life</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                        <router-link to="/services-details" class="link-btn">
                            View More 
                            <i class="ph-caret-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeOffer'
}
</script>
import { defineStore } from "pinia";
import axios from "axios";

// const baseUrl = "https://localhost:7139/api";
const baseUrl = process.env.VUE_APP_API_URL;

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    isAuthenticated: false,
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    getAuth(state) {
      return state.isAuthenticated;
    },
  },
  actions: {
    async register(userData) {
      try {
        const response = await axios.post(`${baseUrl}/User/register`, userData);
        return response;
      } catch (error) {
        console.error("Error registering user:", error);
        return error;
      }
    },
    async login(userData) {
      return await axios
        .post(`${baseUrl}/User/login`, userData, {
          withCredentials: true,
        })
        .then((res) => {
          this.user = res.data;
          this.authenticate();
          return res;
        })
        .catch((error) => {
          this.user = null;
          this.isAuthenticated = false;
          console.error(error);
          return error;
        });
    },
    async logout() {
      return await axios
        .post(
          `${baseUrl}/User/logout`,
          {},
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          this.user = null;
          this.authenticate();
          return res.data;
        })
        .catch((error) => {
          this.user = null;
          this.isAuthenticated = false;
          console.error(error);
        });
    },
    async authenticate() {
      return await axios
        .get(`${baseUrl}/User/isAuthenticated`, {
          withCredentials: true,
        })
        .then((res) => {
          this.isAuthenticated = res.data;
          return res.data;
        })
        .catch((error) => {
          this.user = null;
          this.isAuthenticated = false;
          //console.error(error);
        });
    },
    async getUserInfo() {
      return await axios
        .get(`${baseUrl}/User/getUserInfo`, {
          withCredentials: true,
        })
        .then((res) => {
          this.user = res.data;
          return res.data;
        })
        .catch((error) => {
          this.user = null;
          this.logout();
          this.isAuthenticated = false;
          console.error(error);
        });
    },
    async updateRole(roleData) {
      return await axios
        .post(`${baseUrl}/User/updateRole`, roleData, {
          withCredentials: true,
        })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async registerAndLogin(userData) {
      return await axios
        .post(`${baseUrl}/User/registerAndLogin`, userData, {
          withCredentials: true,
        })
        .then((res) => {
          this.user = res.data;
          this.authenticate();
          return res;
        })
        .catch((error) => {
          this.user = null;
          this.isAuthenticated = false;
          console.error(error);
          return null;
        });
    },
  },
});

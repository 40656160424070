<template>
    <div class="funfacts-area pb-75 bg-fff4f8">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3 col-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <i class="flaticon-employee"></i>
                        </div>
                        <h3>480</h3>
                        <p>CONSULTING SOLUTIONS</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <i class="flaticon-projects"></i>
                        </div>
                        <h3>535</h3>
                        <p>COMPLETED CASES</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <i class="flaticon-rating"></i>
                        </div>
                        <h3>655</h3>
                        <p>HAPPY CUSTOMERS</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <i class="flaticon-expert"></i>
                        </div>
                        <h3>272</h3>
                        <p>EXPERT CONSULTANT</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>
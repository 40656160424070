<template>
  <div>
    <NavbarStyleNine />
    <AllListings />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import AllListings from "../Listing/AllListings.vue";

export default {
  name: "MyListingsPage",
  components: {
    NavbarStyleNine,
    AllListings,
    FooterStyleFive,
  },
};
</script>

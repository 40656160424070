<template>
  <div>
    <div
      v-if="
        !pageLoader &&
        (allowedToViewProperty || (property && property.homeownerId == user.id))
      "
      class="mt-sm-4 mt-md-3 mt-lg-2"
    >
      <div class="container">
        <div class="row">
          <div class="d-flex w-100 justify-content-end">
            <button
              v-if="showRegisterButton"
              class="btn btn-style-two mb-2 mx-1"
              @click="registerProperty"
            >
              Register a Property
            </button>
            <button
              v-if="property && property.homeownerId == user.id"
              class="btn btn-style-two mb-2"
              @click="goToInvites"
            >
              Invite Additional Agents
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 order-lg-1 order-1 mb-4 mb-lg-0">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" v-if="property">
                  {{
                    property.address1 +
                    (property.address2 != null ? ", " + property.address2 : "")
                  }}
                </h5>
                <h6 class="card-subtitle mb-2 text-muted" v-if="property">
                  {{
                    property.city +
                    ", " +
                    property.state +
                    ", " +
                    property.zipCode
                  }}
                </h6>
                <div class="mx-5" v-if="property">
                  <Carousel :items-to-show="1">
                    <Slide
                      v-for="(photoUrl, index) in property.propertyPhotoUrls"
                      :key="index"
                    >
                      <div class="d-flex w-100 justify-content-center">
                        <img :src="photoUrl" class="carouselImage" />
                      </div>
                    </Slide>

                    <template #addons>
                      <Navigation />
                      <Pagination />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 order-lg-2 order-2">
            <div class="card">
              <div class="card-body text-center">
                <h5 class="card-title">Open House For Invited Agents:</h5>
                <Timer
                  v-if="property != null && auction != null"
                  :timerDate="
                    convertUtcToLocalDate(property.openHouseDate).toString()
                  "
                ></Timer>
                <Timer v-else timerDate="0001-01-01 00:00:00"></Timer>
                <h5 class="mt-4">
                  {{
                    auction != null &&
                    new Date(new Date().toISOString()) >
                      convertUtcToLocalDate(auction.startTime)
                      ? "Agent Auction Ends In:"
                      : "Agent Auction Starts In:"
                  }}
                </h5>
                <Timer
                  v-if="property != null && auction != null"
                  :timerDate="
                    new Date(new Date().toISOString()) >
                    convertUtcToLocalDate(auction.startTime)
                      ? convertUtcToLocalDate(auction.endTime).toString()
                      : convertUtcToLocalDate(auction.startTime).toString()
                  "
                ></Timer>
                <Timer v-else timerDate="0001-01-01 00:00:00"></Timer>
              </div>
              <div class="card-body text-center">
                <h5 class="">Seller's Rock Bottom Price:</h5>
                <h3 v-if="property != null">
                  {{ formatCurrency(property.bottomLinePrice) }}
                </h3>
                <p class="mt-2 text-muted">
                  IMPORTANT: This auction is for real estate agents to win the
                  exclusive rights to list this property, not for ownership of
                  this property.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="accordion" id="additionalInfoAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="additionalInfo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Additional Info
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="additionalInfo"
              data-bs-parent="#additionalInfoAccordion"
            >
              <div class="accordion-body text-center">
                <ul class="list-group list-group-flush text-start">
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    Open House For Invited Agents
                    <span>{{
                      property != null
                        ? displayDate(property.openHouseDate)
                        : "Loading..."
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    Agent Auction End
                    <span>{{
                      auction != null
                        ? displayDate(auction.endTime)
                        : "Loading..."
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    Listing Agent Commission Offered
                    <span>{{
                      property != null
                        ? property.commissionPercentage + "%"
                        : "Loading..."
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    Buying Agent Commission Offered
                    <span>{{
                      property != null
                        ? (property.buyerAgentCommissionPercentage != null
                            ? property.buyerAgentCommissionPercentage
                            : "0") + "%"
                        : "Loading..."
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    Opening Bid Amount
                    <span>{{
                      auction != null
                        ? formatCurrency(
                            auction.openingBid != null ? auction.openingBid : 0
                          )
                        : "Loading..."
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Documents
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body"></div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="container mt-4" v-if="property && auction && user">
        <div class="card text-center">
          <div class="card-header">
            <h5 class="card-title">
              Online Auction –
              {{
                auction.status == 0
                  ? "Bidding Upcoming"
                  : auction.status == 1
                  ? "Now Bidding!"
                  : "Bidding Closed"
              }}
            </h5>
          </div>
          <div class="card-body">
            <div class="row mx-1 w-100">
              <button
                v-if="property.homeownerId != user.id && acceptedInvite"
                type="button"
                class="btn btn-style-two btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#bidModal"
                :disabled="
                  auction.status == 0
                    ? true
                    : auction.status == 1
                    ? false
                    : true
                "
              >
                BID
              </button>
              <button
                v-if="property.homeownerId != user.id && !acceptedInvite"
                type="button"
                class="btn btn-style-two btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#enterArenaModal"
                :disabled="auction.status == 3 ? true : false"
              >
                ENTER THE ARENA
              </button>
              <button
                id="confirmationModalCloseBtn"
                type="button"
                class="btn btn-style-two d-none"
                data-bs-dismiss="modal"
                data-bs-target="#enterArenaModal"
              ></button>
              <button
                id="bidModalCloseBtn"
                type="button"
                class="btn btn-style-two d-none"
                data-bs-dismiss="modal"
                data-bs-target="#bidModal"
              ></button>
            </div>
            <!-- Bidding History Section -->
            <div class="row mt-3">
              <div class="col-12 mx-1 w-100">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Agent Name</th>
                      <th scope="col">Bid Amount</th>
                      <th scope="col">Date of Bid</th>
                    </tr>
                  </thead>
                  <tbody v-if="bidHistory.length > 0">
                    <tr v-for="bid in bidHistory" :key="bid.bidId">
                      <td>{{ bid.firstName + " " + bid.lastName }}</td>
                      <td>{{ formatCurrency(bid.bidAmount) }}</td>
                      <td>{{ displayDate(bid.bidTime) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="3" class="text-center">
                        No bids have been placed.
                        {{
                          property.homeownerId == user.id
                            ? ""
                            : auction.status == 0
                            ? ""
                            : auction.status == 1
                            ? "Be the first!"
                            : ""
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="bidModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="bidModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                :disabled="bidLoader"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                Minimum required bid:
                {{
                  formatCurrency(
                    auction != null
                      ? currentHighestBid != null
                        ? currentHighestBid + auction.openingBid * (10 / 100)
                        : auction.openingBid
                      : 0
                  )
                }}
              </div>
              <div class="input-group form-group">
                <input
                  :disabled="bidLoader"
                  class="form-control"
                  placeholder="Bid amount"
                  :value="bidFormatted"
                  @keydown="checkDecimal"
                  @focus="setCursorAfterSymbol"
                  @click="setCursorAfterSymbol"
                  @focusout="updateBidFormatted($event)"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                :disabled="bidLoader"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-style-two"
                @click="placeBid()"
                :disabled="bidLoader"
              >
                {{ bidLoader ? "Placing Bid..." : "Place Bid" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center pageLoaderContainer"
      v-else
    >
      <div class="spinner-border text-primary pageLoader" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      class="modal fade"
      id="enterArenaModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="enterArenaModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="enterArenaModalLabel">
              Enter the arena and battle to win this listing
            </h4>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="modal-body fs-5">
              <div>
                <div
                  ref="cardElementContainer"
                  class="card-element mt-4 mb-4"
                ></div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary fs-5"
                data-bs-dismiss="modal"
                :disabled="loader"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-style-two fs-5"
                :disabled="loader"
                @click="payInvite"
              >
                {{ getPayButtonText() }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { usePropertyStore } from "../../stores/properties";
import { useAuctionStore } from "../../stores/auctions";
import { useAuctionInviteStore } from "../../stores/auctionInvite";
import { useAgentStore } from "../../stores/agent";
import { usePaymentStore } from "../../stores/payments";
import { useBidStore } from "../../stores/bids";
import { useUserStore } from "../../stores/users";
import Timer from "../Timer/Timer.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { loadStripe } from "@stripe/stripe-js";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "PropertyDetails",
  props: {
    propertyId: {
      type: Number,
      required: true,
    },
    showRegisterButton: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Timer,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const auctionStore = useAuctionStore();
    const bidStore = useBidStore();
    const userStore = useUserStore();
    const auctionInviteStore = useAuctionInviteStore();
    const agentStore = useAgentStore();
    const paymentStore = usePaymentStore();
    return {
      route,
      router,
      propertyStore,
      auctionStore,
      bidStore,
      userStore,
      auctionInviteStore,
      agentStore,
      paymentStore,
    };
  },
  data() {
    return {
      currentUser: null,
      property: null,
      auction: null,
      bidHistory: [],
      currentHighestBid: null,
      bidLoader: false,
      bid: 0,
      bidFormatted: "$0",
      refreshInterval: null,
      pageLoader: false,
      allowedToViewProperty: false,
      acceptedInvite: false,
      agent: null,
      invite: null,
      loader: false,
    };
  },
  methods: {
    async getStateById(stateId) {
      const stateResponse = await this.propertyStore.getStateById(stateId);
      if (stateResponse.data) {
        return stateResponse.data.name;
      }
      return "";
    },
    convertUtcToLocalDate(date) {
      const utcDate = new Date(date);
      const offset = utcDate.getTimezoneOffset();
      const localDate = new Date(utcDate.getTime() - offset * 60 * 1000);
      return localDate;
    },
    displayDate(date) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const localDate = this.convertUtcToLocalDate(date);
      const localTimeString = localDate.toLocaleString("en-US", options);
      return localTimeString;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    updateBidFormatted(event) {
      const target = event.target;
      if (target) {
        this.bidFormatted = this.formatValue(target.value);
        this.bid = parseFloat(this.bidFormatted.replace(/[^\d.-]/g, ""));
      }
    },
    checkDecimal(event) {
      const inputElement = event.target;
      let currentValue = inputElement.value;

      if (!currentValue.startsWith("$")) {
        currentValue = "$" + currentValue;
        inputElement.value = currentValue;
        inputElement.setSelectionRange(1, 1);
      }

      const selectionStart =
        inputElement.selectionStart !== null ? inputElement.selectionStart : 0;
      const selectionEnd =
        inputElement.selectionEnd !== null ? inputElement.selectionEnd : 0;

      if (selectionStart < 1) {
        event.preventDefault();
        return;
      }

      if (["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(event.key)) {
        if (event.key === "Backspace" && selectionStart <= 1) {
          event.preventDefault();
        }
        return;
      }

      const valueAfterKeyPress =
        currentValue.substring(1, selectionStart) +
        event.key +
        currentValue.substring(selectionEnd);

      if (!/^\d*\.?\d{0,2}$/.test(valueAfterKeyPress)) {
        event.preventDefault();
      }
    },
    setCursorAfterSymbol(event) {
      const inputElement = event.target;

      window.setTimeout(() => {
        const position = inputElement.selectionStart;
        if (position !== null && position <= 1) {
          inputElement.setSelectionRange(1, 1);
        }
      }, 0);
    },
    formatValue(value) {
      const numericValue = parseFloat(value.replace(/[^\d.-]/g, ""));
      return isNaN(numericValue) ? "$0.00" : `$${numericValue.toFixed(2)}`;
    },
    async placeBid() {
      this.bidLoader = true;
      let minimumBidRequired = 0;
      if (this.currentHighestBid != null) {
        minimumBidRequired =
          this.currentHighestBid + this.auction.openingBid * (10 / 100);
      } else {
        minimumBidRequired = this.auction.openingBid;
      }

      if (this.bid >= minimumBidRequired) {
        //place bid
        const bidResponse = await this.bidStore.addBid({
          auctionId: this.auction.auctionId,
          bidAmount: this.bid,
        });
        if (bidResponse.status == 200) {
          await this.getAuctionDetails();
          this.clearRefreshInterval();
          this.startRefreshInterval();
        }
      } else {
        //user needs to place a bid higher or equal to the current minimum required bid
      }
      const bidHistoryResponse = await this.bidStore.getAllAuctionBids(
        this.auction.auctionId
      );
      if (bidHistoryResponse.status === 200) {
        if (bidHistoryResponse.data.length > 0) {
          this.bidHistory = bidHistoryResponse.data;
          this.currentHighestBid = this.bidHistory[0].bidAmount;
        }
      }
      document.getElementById("bidModalCloseBtn").click();
      this.bidLoader = false;
    },
    goToInvites() {
      this.router.push({
        name: "inviteAgents",
        params: { propertyId: this.propertyId },
      });
    },
    async getAuctionDetails() {
      const auctionResponse = await this.auctionStore.getAuctionByProperty(
        this.propertyId
      );
      if (auctionResponse.status === 200) {
        this.auction = auctionResponse.data[0];
        await this.getAllAuctionBids();
      }
    },
    async getAllAuctionBids() {
      const bidHistoryResponse = await this.bidStore.getAllAuctionBids(
        this.auction.auctionId
      );
      if (
        bidHistoryResponse.status === 200 &&
        bidHistoryResponse.data.length > 0
      ) {
        this.bidHistory = bidHistoryResponse.data;
        this.currentHighestBid = this.bidHistory[0].bidAmount;
        this.bid =
          this.currentHighestBid + this.auction.openingBid * (10 / 100);
        this.bidFormatted = this.formatCurrency(this.bid);
      } else {
        this.bid = this.auction.openingBid;
        this.bidFormatted = this.formatCurrency(this.bid);
      }
    },
    startRefreshInterval() {
      this.refreshInterval = setInterval(async () => {
        await this.getAuctionDetails();
      }, 10000);
    },
    clearRefreshInterval() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    },
    registerProperty() {
      this.router.push("register-property");
    },
    getPayButtonText() {
      if (this.agentStore.getAgent == null) return "Pay";
      if (this.agentStore.getAgent.membership == null) return "Pay";

      return `Pay (${this.formatCurrency(
        this.agentStore.getAgent.membership.entryFee.toString()
      )})`;
    },
    async setupStripe() {
      await this.$nextTick();

      // Initialize Stripe
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount(this.$refs.cardElementContainer);
    },
    fetchAgent() {
      this.agentStore.fetchAgent().then((res) => {
        if (res.status == 200) {
          this.agent = this.agentStore.agent;
        }
      });
    },
    fetchAgentInvite() {
      this.auctionInviteStore
        .getAgentAuctionInvite(this.propertyId)
        .then((res) => {
          if (res.status == 200 && res.data) {
            this.invite = res.data;
          }
        });
    },
    async fetchAgentInviteStatus() {
      await this.auctionInviteStore
        .getInviteStatus(this.propertyId)
        .then((res) => {
          if (res.status == 200) {
            if (res.data && res.data > 0) {
              //set status
              if (res.data == 1) {
                //They have accepted invite
                this.allowedToViewProperty = true;
                this.acceptedInvite = true;
              } else if (res.data == 2) {
                //They need to accept invite
                this.allowedToViewProperty = true;
                this.acceptedInvite = false;
              } else {
                //return them to dashboard
                this.router.push({ name: "AgentDashboard" });
              }
            } else {
              //return them to dashboard
              this.router.push({ name: "AgentDashboard" });
            }
          }
        })
        .catch(() => {
          //return them to dashboard
          this.router.push({ name: "AgentDashboard" });
        });
    },
    async payInvite() {
      if (this.agentStore.getAgent == null) {
        console.error("Agent is null");
        return;
      }

      this.loader = true;

      // Get the token or payment method from Stripe
      const { error, paymentMethod } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card,
      });

      if (error) {
        console.error(error);
        this.loader = false;
        return;
      }
      //paymentMethod.id, membershipTier

      const data = {
        paymentMethodId: paymentMethod.id,
        membershipTier: this.agentStore.getAgent.membership.tier,
      };
      this.paymentStore
        .payInvite(data)
        .then((paymentConfirmation) => {
          if (paymentConfirmation.requiresAction) {
            // Handle 3D Secure or authentication
            this.stripe
              .confirmCardPayment(paymentConfirmation.clientSecret)
              .then((result) => {
                if (result.error) {
                  console.error(result.error.message);
                  this.loader = false;
                } else {
                  this.auctionInviteStore
                    .updateStatus(this.invite, true)
                    .then((res) => {
                      if (res.status == 200) {
                        this.fetchAgentInviteStatus();
                        this.fetchAgent();
                        document
                          .getElementById("confirmationModalCloseBtn")
                          .click();
                        this.loader = false;
                      }
                    });
                }
              });
          } else {
            this.auctionInviteStore
              .updateStatus(this.invite, true)
              .then((res) => {
                if (res.status == 200) {
                  this.fetchAgentInviteStatus();
                  this.fetchAgent();
                  document.getElementById("confirmationModalCloseBtn").click();
                  this.loader = false;
                }
              });
          }
        })
        .catch((error) => {
          console.error("Payment failed", error);
          this.loader = false;
        });
    },
  },
  async beforeMount() {
    this.pageLoader = true;
    this.user = this.userStore.getUser;
    if (this.user.roleId == 2) {
      this.fetchAgent();
      this.fetchAgentInvite();
      await this.fetchAgentInviteStatus();
    }
    const propertyResponse = await this.propertyStore.getPropertyById(
      this.propertyId
    );

    if (propertyResponse.status === 200) {
      let property = propertyResponse.data;
      property.state = await this.getStateById(propertyResponse.data.stateId);
      await this.getAuctionDetails();
      this.startRefreshInterval();
      this.property = property;
      this.pageLoader = false;
    }
  },
  mounted() {
    this.setupStripe();
  },
  beforeUnmount() {
    this.clearRefreshInterval();
  },
};
</script>

<style scoped>
.time-label {
  font-size: 0.7em;
  font-weight: 600;
}

.carouselImage {
  height: 17.25rem;
}
</style>

<style>
.carousel__prev,
.carousel__next,
.carousel__pagination-button {
  background-color: var(--greenColor);
}
</style>

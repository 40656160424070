<template>
    <div>
        <div class="courses-details-area ptb-100 bg-f9f9f9">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="courses-details-header">
                            <div class="rating">
                                <i class="ph-star checked"></i>
                                <i class="ph-star checked"></i>
                                <i class="ph-star checked"></i>
                                <i class="ph-star checked"></i>
                                <i class="ph-star"></i>
                                <div class="rating-count">
                                    <span>4.0 (2 rating)</span>
                                </div>
                            </div>
                            <h2>Make a 2D Platformer with State Machines in Unity</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div class="courses-meta d-flex align-items-center">
                                <ul>
                                    <li>
                                        <i class="ph-folder-open"></i>
                                        <span>Category</span>
                                        <router-link to="/courses">Node.js</router-link>
                                    </li>
                                </ul>
                                <router-link to="/profile-authentication" class="btn-style-one yellow-color">
                                    Enroll Now 
                                    <i class="ph-caret-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="courses-details-image">
                            <img src="../../assets/images/courses/courses1.jpg" alt="image">
                            <div 
                                class="popup-video"
                                v-on:click="isPopupMethod(isPopup)"
                                style="cursor: pointer"
                            >
                                <i class="ph-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="courses-details-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="courses-details-desc">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false">Curriculum</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="overview" role="tabpanel">
                                    <div class="courses-overview">
                                        <h3>Course Description</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <a href="#">incididunt</a> ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                        <h3>Certification</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et <strong>dolore</strong> magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                        <h3>Who this course is for</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                        <h3>What you'll learn in this course:</h3>
                                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                        <ul>
                                            <li>a console</li>
                                            <li>Two Joy-Con controllers that are <a href="#">detachable</a></li>
                                            <li>A grip that enables you to combine them into a single gamepad for play on the</li>
                                            <li>A dock which you can use to <strong>connect</strong> your console to the television for traditional gameplay</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="curriculum" role="tabpanel">
                                    <div class="courses-curriculum">
                                        <h3>Python Introduction</h3>
                                        <ul>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">Python Introduction</span>
                                                <div class="courses-meta">
                                                    <span class="questions">5 questions</span>
                                                    <span class="duration">01 Hour</span>
                                                    <span class="status">Preview</span>
                                                </div>
                                            </router-link></li>
                                        </ul>
                                        <h3>Stepping into the World of Python</h3>
                                        <ul>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">NumPy Introduction</span>
                                                <div class="courses-meta">
                                                    <span class="duration">15 Min</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">NumPy Getting Started</span>
                                                <div class="courses-meta">
                                                    <span class="duration">30 Min</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">NumPy Creating Arrays</span>
                                                <div class="courses-meta">
                                                    <span class="duration">45 Min</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">NumPy Array Indexing</span>
                                                <div class="courses-meta">
                                                    <span class="questions">4 questions</span>
                                                    <span class="duration">1 Hour</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">NumPy Array Slicing</span>
                                                <div class="courses-meta">
                                                    <span class="duration">1.5 Hour</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                        </ul>
                                        <h3>Python MySQL</h3>
                                        <ul>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">Python MySQL</span>
                                                <div class="courses-meta">
                                                    <span class="duration">01 Hour</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">Python MySQL Create Database</span>
                                                <div class="courses-meta">
                                                    <span class="questions">3 questions</span>
                                                    <span class="duration">1.1 Hour</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                            <li><router-link to="/courses-details" class="d-flex justify-content-between align-items-center">
                                                <span class="courses-name">Python MySQL Create Table</span>
                                                <div class="courses-meta">
                                                    <span class="duration">1.5 Hour</span>
                                                    <span class="status locked"><i class='bx bx-lock-alt'></i></span>
                                                </div>
                                            </router-link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="reviews" role="tabpanel">
                                    <div class="courses-reviews">
                                        <h3>Course Rating</h3>
                                        <div class="rating">
                                            <span class="ph-star checked"></span>
                                            <span class="ph-star checked"></span>
                                            <span class="ph-star checked"></span>
                                            <span class="ph-star checked"></span>
                                            <span class="ph-star"></span>
                                        </div>
                                        <div class="rating-count">
                                            <span>4.1 average based on 4 reviews.</span>
                                        </div>
                                        <div class="row">
                                            <div class="side">
                                                <div>5 star</div>
                                            </div>
                                            <div class="middle">
                                                <div class="bar-container">
                                                    <div class="bar-5"></div>
                                                </div>
                                            </div>
                                            <div class="side right">
                                                <div>02</div>
                                            </div>
                                            <div class="side">
                                                <div>4 star</div>
                                            </div>
                                            <div class="middle">
                                                <div class="bar-container">
                                                    <div class="bar-4"></div>
                                                </div>
                                            </div>
                                            <div class="side right">
                                                <div>03</div>
                                            </div>
                                            <div class="side">
                                                <div>3 star</div>
                                            </div>
                                            <div class="middle">
                                                <div class="bar-container">
                                                    <div class="bar-3"></div>
                                                </div>
                                            </div>
                                            <div class="side right">
                                                <div>04</div>
                                            </div>
                                            <div class="side">
                                                <div>2 star</div>
                                            </div>
                                            <div class="middle">
                                                <div class="bar-container">
                                                    <div class="bar-2"></div>
                                                </div>
                                            </div>
                                            <div class="side right">
                                                <div>05</div>
                                            </div>
                                            <div class="side">
                                                <div>1 star</div>
                                            </div>
                                            <div class="middle">
                                                <div class="bar-container">
                                                    <div class="bar-1"></div>
                                                </div>
                                            </div>
                                            <div class="side right">
                                                <div>00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="courses-review-comments">
                                        <h3>3 Reviews</h3>
                                        <div class="user-review">
                                            <img src="../../assets/images/user/user1.jpg" alt="image">
                                            <div class="review-rating">
                                                <div class="review-stars">
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                </div>
                                            </div>
                                            <span class="d-block sub-comment">James Anderson</span>
                                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                        </div>
                                        <div class="user-review">
                                            <img src="../../assets/images/user/user2.jpg" alt="image">
                                            <div class="review-rating">
                                                <div class="review-stars">
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star'></i>
                                                    <i class='ph-star'></i>
                                                </div>
                                            </div>
                                            <span class="d-block sub-comment">Sarah Taylor</span>
                                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                        </div>
                                        <div class="user-review">
                                            <img src="../../assets/images/user/user3.jpg" alt="image">
                                            <div class="review-rating">
                                                <div class="review-stars">
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                </div>
                                            </div>
                                            <span class="d-block sub-comment">David Warner</span>
                                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                        </div>
                                        <div class="user-review">
                                            <img src="../../assets/images/user/user4.jpg" alt="image">
                                            <div class="review-rating">
                                                <div class="review-stars">
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star checked'></i>
                                                    <i class='ph-star'></i>
                                                </div>
                                            </div>
                                            <span class="d-block sub-comment">King Kong</span>
                                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                        </div>
                                    </div>
                                    <div class="review-form-wrapper">
                                        <h3>Add a review</h3>
                                        <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                                        <form @submit.prevent>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="rating">
                                                        <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                                        <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                                        <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                                        <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                                        <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Name *">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" placeholder="Email *">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" id="checkme">
                                                        <label class="form-check-label" for="checkme">
                                                            Save my name, email, and website in this browser for the next time I comment.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="courses-details-info">
                            <ul class="info">
                                <li class="price">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-tag"></i> Price</span>
                                        Free
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-arrow-fat-line-up"></i> Course Level</span>
                                        Intermediate
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-clock"></i> Duration</span>
                                        7 weeks
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-laptop"></i> Lessons</span>
                                        25
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-dots-three"></i> Quizzes</span>
                                        5
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-sun"></i> Certificate</span>
                                        Yes
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-globe"></i> Language</span>
                                        English
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="ph-lock-key"></i> Access</span>
                                        Lifetime
                                    </div>
                                </li>
                            </ul>
                            <div class="btn-box">
                                <button type="submit" class="btn-style-one yellow-color d-block w-100">Add to Cart</button>
                                <router-link to="/profile-authentication" class="btn-style-one crimson-light-color">
                                    Buy Now
                                </router-link>
                            </div>
                            <div class="courses-share">
                                <div class="share-info">
                                    <span>Share This Course <i class="ph-share-network"></i></span>
                                    <ul class="social-link">
                                        <li>
                                            <a href="https://www.https://www.linkedin.com/.com/" class="facebook" target="_blank">
                                                <i class="flaticon-facebook-app-symbol"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/" class="twitter" target="_blank">
                                                <i class="flaticon-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/" class="linkedin" target="_blank">
                                                <i class="flaticon-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/" class="instagram" target="_blank">
                                                <i class="flaticon-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="video-popup show" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoursesDetails',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>
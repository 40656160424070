<template>
  <div
    class="d-flex w-100 row homeMainBanner m-0 justify-content-center align-items-center"
  >
    <div class="homeMainBannerHeading text-center">
      <h1 class="text-white">
        <span>Agent Arena</span>
        Where top real estate agents pay you for the privilege to sell your home
      </h1>
      <div class="d-flex w-100 justify-content-center align-items-center">
        <router-link
          to="/register"
          class="btn-style-two fw-bold mt-2 d-flex justify-content-center align-items-center w-auto px-4 py-3 text-uppercase"
        >
          Register Now
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
  components: {},
};
</script>

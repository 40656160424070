<template>
  <div>
    <NavbarStyleNine />
    <PageTitle
      subTitle="Add your property"
      heding="Let's work on getting your property added."
    />
    <RegisterPropertyForm />
    <FooterStyleFive />
  </div>
</template>

<script>
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import RegisterPropertyForm from "../Forms/RegisterPropertyForm.vue";
import FooterStyleFive from "../Layouts/FooterStyleFive";

export default {
  name: "RegisterPropertyPage",
  components: {
    NavbarStyleNine,
    RegisterPropertyForm,
    FooterStyleFive,
  },
};
</script>

<template>
  <div class="blog-area pb-75 pt-100">
    <div class="container">
      <div class="section-title">
        <span class="sub-title green-color">{{ sectionSubtitle }}</span>
        <h2 class="nunito-font" v-html="sectionTitle"></h2>
      </div>

      <div class="row justify-content-center">
        <div
          v-for="(post, index) in newsGrid"
          :key="index"
          class="col-xl-4 col-lg-6 col-md-6"
          data-aos="fade-up"
          :data-aos-duration="'1200'"
          :data-aos-delay="index * 100"
        >
          <div class="single-blog-post-item d-flex flex-column h-100">
            <div class="image ratio ratio-16x9">
              <router-link :to="post.path" class="d-block w-100 h-100">
                <img
                  :src="post.imageUrl"
                  :alt="post.title"
                  class="img-fluid w-100 h-100"
                  style="object-fit: cover"
                />
              </router-link>
            </div>
            <div class="content mt-3">
              <ul class="meta">
                <li>
                  <i :class="post.icon"></i>
                  {{ post.date }}
                </li>
              </ul>
              <h3 class="nunito-font">
                <router-link :to="post.path">{{ post.title }}</router-link>
              </h3>
              <p v-html="post.description"></p>
              <router-link :to="post.path" class="link-btn">
                {{ post.buttonText }}
                <i class="ph-caret-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionSubtitle: "",
      sectionTitle: "",
      newsGrid: [],
    };
  },
  async mounted() {
    await this.fetchSectionTitle("2oSdxf8ppbbl3bHQiMRoGQ");
    await this.fetchNewsGrid("newsGrid");
  },
  methods: {
    async fetchSectionTitle(entryId) {
      try {
        const response = await this.$contentfulClient.getEntry(entryId);
        this.sectionSubtitle = response.fields.label;
        this.sectionTitle = response.fields.title;
      } catch (error) {
        console.error("Error fetching section title:", error);
      }
    },
    async fetchNewsGrid(contentTypeId) {
      try {
        const response = await this.$contentfulClient.getEntries({
          content_type: contentTypeId,
        });
        // console.log("response",response)

        this.newsGrid = response.items.map((item) => ({
          title: item.fields.title,
          imageUrl: `https:${item.fields.image.fields.file.url}`,
          date: item.fields.date,
          description: this.$documentToHtmlString(item.fields.description),
          buttonText: item.fields.buttonText || "Read More",
          path: item.fields.redirectPath,
          icon: item.fields.dateIcon,
        }));
      } catch (error) {
        console.error("Error fetching news grid:", error);
      }
    },
  },
};
</script>
